<template>
  <div class="row">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th />
            <th class="text-right">
              Past 24 hours
            </th>
            <th class="text-right">
              Past 7 days
            </th>
            <th class="text-right">
              Past 30 days
            </th>
            <th class="text-right">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Applications</th>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
          </tr>
          <tr>
            <th>Enrolments</th>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
          </tr>
          <tr>
            <th>Course completions</th>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
          </tr>
          <tr>
            <th>Kits purchased</th>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
            <td class="text-right">
              0
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import UserMixins from '@/mixins/userProfile'

export default {
  name: 'ResearchTable',
  components: {},
  mixins: [UserMixins],
  props: ['showData', 'search', 'items'],
  data: function () {
    return {
      tableSort: 'name',
      tableCol: {
        name: { sort: 'asc', name: 'Name' },
        practice: { sort: 'asc', name: 'Practice' },
        state: { sort: 'asc', name: 'State' },
        referralCode: { sort: 'asc', name: 'Referral Code' },
        usage: { sort: 'asc', name: 'Usage' }
      }
    }
  },
  computed: {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chart-wrap {
  position: relative;
  height: 200px;
  margin: 50px auto;
}

em {
  font-size: 17px;
  opacity: 0.7;
  max-width: 500px;
  margin: 0px auto;
}
.padded-row {
  padding-bottom: 0;
  @media (min-width: 992px) {
    padding-bottom: 1rem;
  }
}

.mb-4 {
  margin-bottom: 1.25rem !important;
  @media (min-width: 992px) {
    margin-bottom: 1.5rem !important;
  }
}

.table-hover tr {
  cursor: pointer;
}

.dashboard {
  //padding-bottom:2.5rem;
  padding-bottom: 100px;
}

.clickable {
  cursor: pointer;
}
</style>
