<template>
  <div class="page container">
    <transition name="slide-fade">
      <div>
        <header>
          <h1 class="page-header">Lookup by Activation Code</h1>
        </header>
        <transition name="slide-fade">
          <div class="box box-shadow">
            <div class="form-group">
              <label for="activationCode">Activation Code</label>
              <div class="inline-zero form-inline">
                <input
                  id="activationCode"
                  ref="activationCode"
                  v-model="activationCode"
                  v-validate.disable="{
                    required: true,
                    regex: /^[A-Z0-9]{10}$/
                  }"
                  type="text"
                  name="Activation Code"
                  class="form-control"
                  placeholder="Activation Code"
                  :class="{ 'is-invalid': errors.first('Activation Code') }"
                  autofocus
                  @keyup.enter="decodeActivationCode"
                />
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="decodeActivationCode"
                >
                  Find Code
                </button>
              </div>

              <div v-if="errors" class="error">
                {{ errors.first('Activation Code') }}
              </div>
            </div>
          </div>
        </transition>
        <div v-if="sampleIdentifier" class="box box-shadow">
          <b-jumbotron>
            <div class="row">
              <div class="col-6">
                <h3 class="display-4">
                  {{ sampleIdentifier }}
                </h3>
                <input
                  id="sampleIdentifier"
                  type="hidden"
                  :value="sampleIdentifier"
                />
              </div>
              <div class="col-6">
                <button class="btn btn-primary" @click="copySampleIdentifier">
                  <font-awesome-icon :icon="['fa', 'fa-copy']">
                    Click to copy
                  </font-awesome-icon>
                </button>
              </div>
            </div>
            <div class="row">
              <div
                v-if="copyStatus === 'success'"
                class="col alert alert-success"
              >
                Sample identifier copied.
              </div>
              <div
                v-else-if="copyStatus === 'failure'"
                class="col alert alert-danger"
              >
                Failed to copy sample identifier.
              </div>
            </div>
          </b-jumbotron>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import activationCodeService from '@/services/activationCodeService'

export default {
  name: 'ActivationCode',
  data() {
    return {
      activationCode: null,
      sampleIdentifier: null,
      copyStatus: null
    }
  },
  methods: {
    decodeActivationCode() {
      this.$validator
        .validate()
        .then(() => {
          if (!this.errors.any()) {
            this.sampleIdentifier = activationCodeService.decodeActivationCode(
              this.activationCode
            )
          }
        })
        .catch((error) => console.log(error))
    },
    copySampleIdentifier() {
      const sampleIdentifierField = document.getElementById('sampleIdentifier')
      sampleIdentifierField.setAttribute('type', 'text')
      sampleIdentifierField.select()
      try {
        document.execCommand('copy')
        this.copyStatus = 'success'
      } catch (err) {
        this.copyStatus = 'failed'
      }
      sampleIdentifierField.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    }
  }
}
</script>
