import Vue from 'vue'

export const createAsyncTypes = (type) => ({
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
  PENDING: `${type}_PENDING`,
  RESPONSE: `${type}_RESPONSE`
})

export const createAsyncMutations = (type, initialState) => {
  // Set defaults for reactivity
  Vue.set(initialState, type.PENDING, false)
  Vue.set(initialState, type.SUCCESS, false)
  Vue.set(initialState, type.RESPONSE, null)
  Vue.set(initialState, type.FAILURE, null)

  // Set states during requests
  return {
    [type.PENDING] (state) {
      Vue.set(state, type.PENDING, true)
    },
    [type.SUCCESS] (state, data) {
      Vue.set(state, type.PENDING, false)
      Vue.set(state, type.SUCCESS, true)
      Vue.set(state, type.FAILURE, null)
      Vue.set(state, type.RESPONSE, data)
    },
    [type.FAILURE] (state, error) {
      Vue.set(state, type.PENDING, false)
      Vue.set(state, type.SUCCESS, false)
      Vue.set(state, type.FAILURE, error)
    }
  }
}
