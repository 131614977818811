export default {
  methods: {
    stringToSlug: function (text) {
      if (!text) return
      return text
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-')
    },
    sampleToSlug: function (key) {
      return key.replace(/_/g, '-').toLowerCase()
    },
    slugToSample: function (slug) {
      return slug.replace(/-/g, '_').toUpperCase()
    },
    sampleToString: function (key) {
      return this.uFirst(key.replace(/_/g, ' '))
    },
    sampleToDisplay: function (key) {
      const explode = key.split('_')
      if (explode.length === 1) {
        return this.sampleToString(key)
      }
      var rtn = ''
      var i = 0
      while (i < explode.length) {
        if (explode[i] !== '') {
          if (rtn.length >= 1) {
            rtn += ' '
          }
          rtn += this.uFirst(explode[i])
        }
        i++
      }
      return rtn
    },
    uFirst: function (string) {
      const lower = string.toLowerCase()
      return lower.charAt(0).toUpperCase() + lower.slice(1)
    }
  }
}
