<template>
  <transition name="slide-fade">
    <div class="dashboard container">
      <transition name="slide-fade">
        <div v-if="fieldErrors" class="alert alert-danger" role="alert">
          {{ fieldErrors }}
        </div>
        <div v-if="placeOrderError" class="alert alert-danger" role="alert">
          {{ placeOrderError }}
        </div>
        <div v-if="orderSuccess" class="alert alert-success" role="alert">
          {{ orderSuccess }}
        </div>
      </transition>
      <header>
        <h1>{{ header }}</h1>
      </header>
      <div class="box box-shadow">
        <h3>{{ title }}</h3>
        <h6 class="text-danger">
          {{ description }}
        </h6>
        <div v-if="renderInvoicedKit">
          <div class="row">
            <div class="form-group col-md-6 col-sm-12">
              <input
                v-model="order.paymentId"
                v-validate="'required|max:50'"
                type="text"
                class="form-control"
                name="payment reference"
                placeholder="Xero invoice number*"
                :class="{ 'is-invalid': errors.has('payment reference') }"
              />
              <div class="invalid-feedback">
                {{ errors.first('payment reference') }}
              </div>
            </div>
            <div class="form-group col-md-6 col-sm-12">
              <input
                v-model="order.amount"
                v-validate="'required|max:50'"
                type="number"
                class="form-control"
                name="payment amount"
                placeholder="0.00*"
                :class="{ 'is-invalid': errors.has('payment amount') }"
              />
              <div class="invalid-feedback">
                {{ errors.first('payment amount') }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md col-sm-12">
              <input
                v-model="order.purchaserFirstName"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="purchaser first name"
                placeholder="Purchaser first name*"
                :class="{ 'is-invalid': errors.has('purchaser first name') }"
              />
              <div class="invalid-feedback">
                {{ errors.first('purchaser first name') }}
              </div>
            </div>
            <div class="form-group col-md col-sm-12">
              <input
                v-model="order.purchaserLastName"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="purchaser last name"
                placeholder="Purchaser last name*"
                :class="{ 'is-invalid': errors.has('purchaser last name') }"
              />
              <div class="invalid-feedback">
                {{ errors.first('purchaser last name') }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="renderFreeKit">
          <div class="row">
            <div class="form-group col-md-6 col-sm-12">
              <select
                v-model="reasons.reason"
                v-validate="'required'"
                name="reason"
                class="form-control"
                :class="{ 'is-invalid': errors.has('reason') }"
              >
                <option
                  v-for="option in reasons.options"
                  :key="`reasons-options-${option.value}`"
                  :value="option.value"
                  :disabled="option.disabled"
                >
                  {{ option.text }}
                </option>
              </select>
              <div class="invalid-feedback">
                {{ errors.first('reason') }}
              </div>
            </div>
            <div
              v-if="reasons.reason === 'other'"
              class="form-group col-md-6 col-sm-12"
            >
              <input
                v-model="reasons.other"
                v-validate="'required|max:50'"
                type="text"
                class="form-control"
                name="other reason"
                placeholder="Describe the reason*"
                :class="{ 'is-invalid': errors.has('other reason') }"
              />
              <div class="invalid-feedback">
                {{ errors.first('other reason') }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md col-sm-12">
              <input
                v-model="order.purchaserFirstName"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="purchaser first name"
                placeholder="Purchaser first name*"
                :class="{ 'is-invalid': errors.has('purchaser first name') }"
              />
              <div class="invalid-feedback">
                {{ errors.first('purchaser first name') }}
              </div>
            </div>
            <div class="form-group col-md col-sm-12">
              <input
                v-model="order.purchaserLastName"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="purchaser last name"
                placeholder="Purchaser last name*"
                :class="{ 'is-invalid': errors.has('purchaser last name') }"
              />
              <div class="invalid-feedback">
                {{ errors.first('purchaser last name') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md col-sm-12">
            <input
              v-model="order.purchaserEmail"
              v-validate="'required|email'"
              type="text"
              class="form-control"
              name="purchaser email"
              placeholder="Purchaser email address*"
              :class="{ 'is-invalid': errors.has('purchaser email') }"
            />
            <div class="invalid-feedback">
              {{ errors.first('purchaser email') }}
            </div>
          </div>
          <div class="form-group col-md col-sm-12">
            <input
              v-model="order.noOfKits"
              v-validate="'required|numeric|min_value:1'"
              type="number"
              class="form-control"
              name="no of kits"
              min="1"
              placeholder="No Of Kits*"
              :class="{ 'is-invalid': errors.has('no of kits') }"
            />
            <div class="invalid-feedback">
              {{ errors.first('no of kits') }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md col-sm-12" role="group">
            <b-form-input
              v-model="practitionerRefCode"
              :state="practitionerRefCodeState"
              placeholder="Practitioner referral code"
              trim
            />
            <b-form-invalid-feedback id="input-live-feedback">
              Invalid practitioner referral code
            </b-form-invalid-feedback>
          </div>
          <div class="form-group col-md col-sm-12" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <input
              type="button"
              class="btn btn-primary"
              value="Place Order"
              @click="validate"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FreeOrInvoicedKits',
  props: {
    renderFreeKit: {
      type: Boolean,
      default: false
    },
    renderInvoicedKit: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      key: 0,
      order: {
        purchaserFirstName: null,
        purchaserLastName: null,
        purchaserEmail: null,
        noOfKits: null,
        amount: 0,
        orderItems: null,
        paymentId: null,
        description: null
      },
      fieldErrors: null,
      placeOrderError: null,
      orderSuccess: null,
      reasons: {
        reason: null,
        other: null,
        options: [
          { value: null, text: 'Please select a reason*', disabled: true },
          { value: 'staff', text: 'Staff' },
          { value: 'marketing', text: 'Marketing' },
          { value: 'hcp', text: 'HCP' },
          { value: 'promo', text: 'Promo' },
          { value: 'other', text: 'Other' }
        ]
      },
      practitionerRefCode: null,
      practitionerRefCodeState: null
    }
  },
  computed: {
    user: function () {
      return this.$store.state.GET_USER_RESPONSE
    },
    userInfo: function () {
      return this.user
        ? this.user.email || this.user.name || 'No user info'
        : ''
    }
  },
  watch: {
    renderFreeKit() {
      this.clearData()
    }
  },
  mounted() {
    this.$store.dispatch('getUser')
    if (this.order.purchaserFirstName !== null) {
      this.clearOrder()
    }
  },
  methods: {
    clearData: function () {
      this.order = {
        purchaserFirstName: null,
        purchaserLastName: null,
        purchaserEmail: null,
        noOfKits: null,
        amount: 0,
        orderItems: null,
        paymentId: null,
        description: null
      }
      this.fieldErrors = null
      this.placeOrderError = null
      this.orderSuccess = null
    },
    validate() {
      this.$validator.validate().then(() => {
        this.fieldErrors = null
        if (this.errors.any()) {
          this.fieldErrors =
            'Please fill out or correct the highlighted fields.'
          scroll(0, 0)
          return
        }
        // If it is free kit, add the reason to the paymentId field
        if (this.renderFreeKit) {
          this.order.paymentId = `FREE: ${
            (this.reasons.reason === 'other' && this.reasons.other) ||
            this.reasons.reason
          }`
        }
        // If ref code present, validate it, otherwise place order
        if (
          this.practitionerRefCode !== null &&
          this.practitionerRefCode.length > 0
        ) {
          this.$store
            .dispatch('checkReferralCode', this.practitionerRefCode)
            .then((response) => {
              if (response.status === 200) {
                this.practitionerRefCodeState = null
                this.placeOrder()
              } else {
                this.practitionerRefCodeState = false
              }
            })
            .catch(() => {
              this.practitionerRefCodeState = false
            })
        } else {
          this.practitionerRefCodeState = null
          this.placeOrder()
        }
      })
    },
    placeOrder() {
      try {
        this.prepareOrder()
      } catch (error) {
        this.placeOrderError = error.message
        return
      }
      this.$store
        .dispatch('postOrder', this.order)
        .then((ok) => {
          this.orderSuccess = 'Order placed successfully.'
          this.placeOrderError = null
        })
        .catch((error) => {
          this.placeOrderError = error.response.data.message
        })
    },
    prepareOrder() {
      let orderItems = []
      for (var counter = 0; counter < this.order.noOfKits; counter++) {
        let curOrderDto = {
          unitPrice: Number(process.env.VUE_APP_PRICE_SINGLE_KIT)
        }
        if (
          this.practitionerRefCode !== null &&
          this.practitionerRefCode.length > 0
        ) {
          curOrderDto['referralCode'] = this.practitionerRefCode
        }
        orderItems.push(curOrderDto)
      }
      this.order.orderItems = orderItems
      this.order.description = `Ordered By: ${this.userInfo}`
      if (Number.isNaN(Number(this.order.amount))) {
        throw new Error('The order amount is not in number format.')
      } else {
        this.order.amount = Number.parseFloat(this.order.amount).toFixed(2)
      }
    }
  }
}
</script>
