<template>
  <div class="page container">
    <transition name="slide-fade">
      <div>
        <header class="inline-between">
          <h1>Dispatch Station</h1>

          <a
            href="https://microba.atlassian.net/l/c/LFor9sg1"
            target="_blank"
            class="btn btn-sm btn-outline-warning inline-half"
            ><span class="fa fa-question-circle"></span> User guide</a
          >
        </header>

        <dispatch-ui
          :is-dispatch="true"
          :dispatch-type="selectedDispatchType"
          :next-shipping-order="nextShippingOrder"
          :clear-code="clearCode"
          :scan-disabled="scanDisabled"
          @code-scanned="processScannedCode"
        />
        <div class="box box-shadow">
          <h2 v-if="showPendingOrders" class="text-right">
            Pending {{ getDispatchTypeLabel(selectedDispatchType) }} Orders
            <b-badge variant="primary">
              {{ totalDispatchedOrders }} / {{ totalShippingOrders }}
            </b-badge>
          </h2>
          <b-tabs content-class="mt-3">
            <b-tab
              v-for="dispatchType in dispatchTypes"
              :key="dispatchType"
              :title="getDispatchTypeLabel(dispatchType)"
              :active="dispatchType === selectedDispatchType"
              @click="switchDispatchType(dispatchType)"
            />
            <shipping-orders
              v-if="showShippingOrders"
              :shipping-orders="selectedModeShippingOrders"
              :dispatch-type="selectedDispatchType"
              :order-placed="orderPlaced"
              @place-order="placeOrder"
              @manually-print-labels="manuallyPrintLabels"
              @remove-all-shipments="deleteAllShipments"
              @delete-shipping-order="deleteShipmentFromCache"
              @save-shipping-order="saveShippingOrder"
              @delete-dispatchment="deleteDispatchmentFromCache"
              @next-shipping-order="setNextShippingOrder"
              @undispatch-sample="undispatchOrder"
              @clear-fulfillments="clearFulfillments"
            />
            <b-jumbotron
              v-if="isWhiteLabel"
              header="Dispatch Metabiome"
              lead="Scan tubes to dispatch."
            />
            <ClientManagedDispatchUI
              v-if="isResearchClientManagedDispatch"
              @client-managed-project-changed="
                nextShippingOrder = { researchProjectId: $event }
              "
            />
            <b-jumbotron v-if="isOrbitMDispatch" header="Dispatch Orbit M">
              <OrbitMDispatch
                :research-project-id="orbitMProjectId"
                @siteUpdated="orbitMSiteDetails = $event || null"
              />
            </b-jumbotron>
          </b-tabs>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
import DispatchUi from '@/components/dispatch/DispatchUi'
import ShippingOrders from '@/components/dispatch/ShippingOrders'
import OrbitMDispatch from '@/components/dispatch/orbitMDispatch'
import printService from '@/services/printService'
import labelTypes from '@/enums/labelTypes'
import { printers, printGenre } from '@/enums/printers'
import { dispatchTypes, dispatchTypeLabels } from '@/enums/dispatchTypes'
import logisticTypes from '@/enums/logisticTypes'
import KitService from '@/services/kitService'
import metaXploreProductCode from '@/enums/metaXploreProductCodes'
import { Notification, NOTIFICATION_STYLES, Loader } from '@microbadevs/library'
import ClientManagedDispatchUI from '@/components/dispatch/clientManagedDispatchUI'
import activationMethods from '@/enums/activationMethods'

export default {
  name: 'DispatchStation',
  components: {
    DispatchUi,
    ShippingOrders,
    ClientManagedDispatchUI,
    OrbitMDispatch
  },
  data() {
    return {
      dispatchTypes,
      selectedDispatchType: dispatchTypes.REGULAR,
      clearCode: false,
      orderPlaced: null,
      nextShippingOrder: null,
      shippingOrders: [],
      shipments: [],
      orbitMProjectId: null,
      orbitMSiteDetails: null
    }
  },
  computed: {
    regularInsightOrders() {
      return this.shippingOrders
        ? this.shippingOrders.filter((order) => {
            if (!order.researchProjectId && !order.distributorName) {
              order.dispatchType = dispatchTypes.REGULAR
              return true
            }
            return false
          })
        : []
    },
    distributorOrders() {
      return this.shippingOrders
        ? this.shippingOrders.filter((order) => {
            if (!order.researchProjectId && order.distributorName) {
              order.dispatchType = dispatchTypes.DISTRIBUTOR
              return true
            }
            return false
          })
        : []
    },
    whiteLabelOrders() {
      // White label has no orders or dispatches. White label just allocates samples to kits
      return []
    },
    orbitMOrders() {
      // Still in process of figuring out orbit m dispatch.
      // Will have dispatch for a site without any shipping.
      return []
    },
    researchMicrobaManagedOrders() {
      return this.shippingOrders
        ? this.shippingOrders.filter((order) => {
            if (
              order.researchProjectId &&
              order.logisticType === logisticTypes.MICROBA_MANAGED
            ) {
              order.dispatchType = dispatchTypes.RESEARCH_MICROBA_MANAGED
              return true
            }
            return false
          })
        : []
    },
    researchClientManagedOrders() {
      return this.shippingOrders
        ? this.shippingOrders.filter((order) => {
            if (
              order.researchProjectId &&
              order.logisticType === logisticTypes.CLIENT_MANAGED
            ) {
              order.dispatchType = dispatchTypes.RESEARCH_CLIENT_MANAGED
              return true
            }
            return false
          })
        : []
    },
    totalDispatchedOrders() {
      return this.selectedModeShippingOrders.length
        ? this.selectedModeShippingOrders.filter((order) => order?.dispatched)
            .length
        : 0
    },
    selectedModeShippingOrders() {
      if (this.isRegularDispatch) {
        return this.regularInsightOrders
      } else if (this.isDistibutorDispatch) {
        return this.distributorOrders
      } else if (this.isWhiteLabel) {
        return this.whiteLabelOrders
      } else if (this.isResearchMicrobaManagedDispatch) {
        return this.researchMicrobaManagedOrders
      } else if (this.isResearchClientManagedDispatch) {
        return this.researchClientManagedOrders
      } else if (this.isOrbitMDispatch) {
        return this.orbitMOrders
      } else {
        throw new Error(
          `Dispatch type seems wrong - cannot select orders for type: ${this.selectedDispatchType}`
        )
      }
    },
    totalShippingOrders() {
      return this.selectedModeShippingOrders.length
    },
    isRegularDispatch() {
      return this.selectedDispatchType === dispatchTypes.REGULAR
    },
    isDistibutorDispatch() {
      return this.selectedDispatchType === dispatchTypes.DISTRIBUTOR
    },
    isWhiteLabel() {
      return this.selectedDispatchType === dispatchTypes.WHITE_LABEL
    },
    isResearchMicrobaManagedDispatch() {
      return (
        this.selectedDispatchType === dispatchTypes.RESEARCH_MICROBA_MANAGED
      )
    },
    isResearchClientManagedDispatch() {
      return this.selectedDispatchType === dispatchTypes.RESEARCH_CLIENT_MANAGED
    },
    isOrbitMDispatch() {
      return this.selectedDispatchType === dispatchTypes.ORBIT_M
    },
    showShippingOrders() {
      return (
        this.shippingOrders &&
        this.shippingOrders.length &&
        !this.isWhiteLabel &&
        !this.isResearchClientManagedDispatch &&
        !this.isOrbitMDispatch
      )
    },
    showPendingOrders() {
      return (
        !this.isWhiteLabel &&
        !this.isResearchClientManagedDispatch &&
        !this.isOrbitMDispatch
      )
    },
    scanDisabled() {
      const isClientManagedResearchWithoutProjectId =
        this.isResearchClientManagedDispatch &&
        !this.nextShippingOrder?.researchProjectId
      const isOrbitMDispatchWithoutSiteSelected =
        this.isOrbitMDispatch && !this.orbitMSiteDetails
      return (
        isClientManagedResearchWithoutProjectId ||
        isOrbitMDispatchWithoutSiteSelected
      )
    }
  },
  watch: {
    selectedDispatchType: function () {
      if (
        this.isWhiteLabel ||
        this.isResearchClientManagedDispatch ||
        this.isOrbitMDispatch
      ) {
        this.nextShippingOrder = null
      }
    }
  },
  mounted: function () {
    const shippingOrderFromStorage = localStorage.getItem('ShippingOrder')
    const shipmentsFromStorage = localStorage.getItem('Shipments')
    this.shippingOrders =
      shippingOrderFromStorage &&
      JSON.parse(shippingOrderFromStorage).length > 0
        ? JSON.parse(shippingOrderFromStorage)
        : []
    this.shipments =
      shipmentsFromStorage && JSON.parse(shipmentsFromStorage).length > 0
        ? JSON.parse(shipmentsFromStorage)
        : []

    this.updateOrderWithShipments(this.shipments)
    this.getNewShippingOrders(true)
    this.orbitMProjectId = Number(process.env.VUE_APP_ORBIT_M_PROJECT_ID)
  },
  methods: {
    filterAndLogDuplicateShippingOrders() {
      const kitIds = []
      const uniqueOrders = []
      this.shippingOrders.forEach((order) => {
        if (kitIds.includes(order.kitId)) {
          // Disaster! Send a log message so we can investigate how this happened.
          this.$store
            .dispatch('postLogs', {
              title: `${process.env.APP_API_ROOT} Duplicate Shipping Orders Detected`,
              message: `Multiple shipping orders have been generated for Kit ${order.kitId}. Reason: Unknown. Please investigate.`
            })
            .catch((error) => this.showError(error))
        } else {
          kitIds.push(order.kitId)
          uniqueOrders.push(order)
        }
      })

      this.shippingOrders = uniqueOrders
    },
    // Update an order with it's actual shipments (aka dispatchments = things that actually go with AusPost)
    updateOrderWithShipments(shipments) {
      shipments.forEach((shipment) => {
        const ordersWithShipments = this.shippingOrders.filter(
          (order) => order.id === shipment.shippingOrderId
        )
        ordersWithShipments.forEach((order) => {
          if (!order.dispatchments) {
            order.dispatchments = []
          }
          const dispatchExists = order.dispatchments.findIndex(
            (dispatchment) => dispatchment.id === shipment.id
          )
          if (dispatchExists < 0) {
            order.dispatchments.push(shipment)
          }
        })
      })
    },
    getNewShippingOrders(initialSorting = false) {
      this.$store
        .dispatch('getShippingOrders', { pending: true })
        .then((response) => {
          if (!response.data) {
            throw new Error('Missing pending orders')
          }

          let pendingOrdersFromAPI = response.data
          pendingOrdersFromAPI.forEach((element) => {
            if (!this.shippingOrders.find((order) => order.id === element.id)) {
              element.dispatched = false
              element._rowVariant = null
              this.shippingOrders.push(element)
            }
          })

          this.filterAndLogDuplicateShippingOrders()
          if (initialSorting) {
            this.shippingOrders.sort((a, b) => a.id - b.id)
          }
          this.storeToLocalStorage()
        })
        .catch((error) => this.showError(error))
    },
    getDispatchTypeLabel(dispatchType) {
      return dispatchTypeLabels[dispatchType]
    },
    processScannedCode(payload) {
      if (this.isRegularDispatch) {
        this.dispatchRegularSample(payload)
      } else if (this.isDistibutorDispatch) {
        this.dispatchDistributorSample(payload)
      } else if (this.isWhiteLabel) {
        this.dispatchWhiteLabelSample(payload)
      } else if (this.isResearchMicrobaManagedDispatch) {
        this.dispatchResearchMicrobaManagedSample(payload)
      } else if (this.isResearchClientManagedDispatch) {
        this.dispatchResearchClientManagedSample(payload)
      } else if (this.isOrbitMDispatch) {
        this.dispatchOrbitMKit(payload)
      } else {
        throw new Error(
          `Dispatch type seems wrong - cannot dispatch orders for type: ${this.selectedDispatchType}`
        )
      }
    },
    dispatchRegularSample(payload) {
      const scannedSampleIdentifier = payload.code
      if (this.isSampleIdentifierAlreadyScanned(scannedSampleIdentifier)) {
        Notification({
          text: 'Collection device has already been added to an order',
          style: NOTIFICATION_STYLES.ERROR
        })
        return
      }
      // Validate Sample
      const {
        fulfillmentCondition,
        id: shippingOrderId,
        kitHashId,
        kitSpec
      } = this.nextShippingOrder
      const plannedShippedSampleIdentifiers =
        fulfillmentCondition?.fulfillment
          ?.map((sample) => sample.sampleIdentifier)
          ?.filter((sampleId) => Boolean(sampleId)) ?? []
      this.validateRegularOrderWithSamples(shippingOrderId, [
        ...plannedShippedSampleIdentifiers,
        scannedSampleIdentifier
      ]).then((response) => {
        const fulfillmentCondition = response?.data?.fulfillmentCondition
        const { productCode, kitId } = response.data || {}

        if (
          !fulfillmentCondition &&
          fulfillmentCondition.hasUnexpectedSamples
        ) {
          Notification({
            text: 'Attempted to add unexpected collection device',
            style: NOTIFICATION_STYLES.ERROR
          })
          return
        }
        this.nextShippingOrder.fulfillmentCondition = fulfillmentCondition
        if (!fulfillmentCondition.isFulfilled) {
          return
        }
        this.dispatchRegularOrder(
          shippingOrderId,
          fulfillmentCondition.fulfillment.map(
            (sample) => sample.sampleIdentifier
          )
        )
          .then(async (response) => {
            this.updateExistingOrderDetails(shippingOrderId)
            let firstName = response.data.shippingFirstName
            let lastName = response.data.shippingLastName

            if (this.isMetaXploreShippingOrder(productCode)) {
              const patient = await this.fetchPatientDetails(kitId)
              firstName = patient.firstName
              lastName = patient.lastName
            }
            this.printName(
              {
                firstName,
                lastName
              },
              kitHashId
            )
            if (kitSpec?.activationMethod === activationMethods.LABEL) {
              this.printActivationLabel({ activationCode: kitHashId })
            }
            const shipments = _.orderBy(
              response.data.shipments,
              ['type'],
              ['desc']
            )
            if (shipments) {
              for (const shipment of shipments) {
                shipment.dispatchType = this.selectedDispatchType
                this.generateLabels(shipment)
                  .then(() => {
                    this.printShipmentsAddress(shipment)
                  })
                  .catch((error) => {
                    this.showError(error)
                  })
                  .finally(() => {
                    Loader.hide()
                  })
              }
              this.shipments.push(...shipments)
              if (response.data.promotionalCode) {
                this.promoCodeAcknowledgementDialogue(
                  response.data.promotionalCode
                )
              }
              localStorage.setItem('Shipments', JSON.stringify(this.shipments))
              this.updateOrderWithShipments(shipments)
            }
          })
          .catch((error) => {
            this.moveShippingOrderToBottomOfList()
            this.showError(error)
          })
          .finally(() => {
            this.resetDataForNextScan()
          })
      })
    },
    dispatchDistributorSample(payload) {
      const sampleIdentifier = payload.code
      if (this.nextShippingOrder) {
        payload.shippingOrderId = this.nextShippingOrder.id
      }
      this.dispatchSample(payload)
        .then((response) => {
          this.updateExistingOrderDetails(
            response.data.shippingOrderId,
            sampleIdentifier,
            response.data.activationCode
          )
          this.markShippingOrderAsDispatched(response.data)
          this.printActivationCode({
            activationCode: response.data.activationCode,
            sampleIdentifier: response.data.sampleIdentifier
          })
          const shipments = _.orderBy(
            response.data.shipments,
            ['type'],
            ['desc']
          )
          if (shipments) {
            for (const shipment of shipments) {
              shipment.sampleIdentifier = sampleIdentifier
              shipment.dispatchType = this.selectedDispatchType
              this.generateLabels(shipment)
                .then(() => {
                  this.printShipmentsAddress(shipment, sampleIdentifier)
                })
                .catch((error) => {
                  this.showError(error)
                })
                .finally(() => {
                  Loader.hide()
                })
            }
            this.shipments.push(...shipments)
          }
          if (response.data.promotionalCode) {
            this.promoCodeAcknowledgementDialogue(response.data.promotionalCode)
          }
          localStorage.setItem('Shipments', JSON.stringify(this.shipments))
          this.updateOrderWithShipments(shipments)
        })
        .catch((error) => {
          this.moveShippingOrderToBottomOfList()
          this.showError(error)
        })
        .finally(() => {
          this.resetDataForNextScan()
        })
    },
    dispatchWhiteLabelSample(payload) {
      const sampleIdentifier = payload.code
      this.dispatchSample(payload)
        .then((response) => {
          this.printActivationCode(response.data)
        })
        .catch((error) => {
          // Feeling dirty doing this but raised with @yao to have error codes so some flow can be applied in FE.
          if (
            error.response &&
            error.response.data &&
            error.response.data.message ===
              `Sample ${sampleIdentifier} is in Allocated status and can not be dispatched.`
          ) {
            // Second scan so confirm if we want to un-dispatch the kit ?.
            this.undispatchOrder({
              item: { id: this.nextShippingOrder.id }
            })
          } else {
            this.showError(error)
          }
        })
    },
    dispatchResearchMicrobaManagedSample(payload) {
      const sampleIdentifier = payload.code
      this.dispatchSample(payload)
        .then((response) => {
          this.updateExistingOrderDetails(
            response.data.shippingOrderId,
            sampleIdentifier
          )
          this.markShippingOrderAsDispatched(response.data)
          this.printName(
            {
              firstName: response.data.shippingFirstName,
              lastName: response.data.shippingLastName
            },
            response.data.voucherCode
          )
          const shipments = _.orderBy(
            response.data.shipments,
            ['type'],
            ['desc']
          )
          if (shipments) {
            for (const shipment of shipments) {
              shipment.sampleIdentifier = sampleIdentifier
              shipment.dispatchType = this.selectedDispatchType
              this.generateLabels(shipment)
                .then(() => {
                  this.printShipmentsAddress(shipment, sampleIdentifier)
                })
                .catch((error) => {
                  this.showError(error)
                })
                .finally(() => {
                  Loader.hide()
                })
            }
            this.shipments.push(...shipments)
          }
          localStorage.setItem('Shipments', JSON.stringify(this.shipments))
          this.updateOrderWithShipments(shipments)
        })
        .catch((error) => {
          this.moveShippingOrderToBottomOfList()
          this.showError(error)
        })
        .finally(() => {
          this.resetDataForNextScan()
        })
    },
    dispatchResearchClientManagedSample(payload) {
      this.dispatchSample(payload)
        .then((response) => {
          this.fetchAndPrintVoucherCode(response)
        })
        .catch((error) => {
          this.showError(error)
        })
        .finally(() => {
          this.resetDataForNextScan()
        })
    },
    async getKitByHashId(hashId) {
      try {
        const kit = await this.$store.dispatch('getKitByHashId', { hashId })
        return kit
      } catch (error) {
        const errorMessage = error?.response?.data?.message || error?.message
        Notification({
          text: `Failed to fetch kit by hash id. ${errorMessage}`,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    async dispatchOrbitMKit(payload) {
      try {
        const replaceRequisitionUrl = `${process.env.VUE_APP_ORBIT_M_KIT_REPLACEMENT_URL}/`.toLowerCase()
        const replaceReplacementUrl = `${process.env.VUE_APP_ORBIT_M_KIT_REQUISITION_URL}/`.toLowerCase()
        const scannedCode = payload.code
        const kitHashId = scannedCode
          .toLowerCase()
          .replace(replaceRequisitionUrl, '')
          .replace(replaceReplacementUrl, '')
        const kitId = KitService.getKitIdByHashId(kitHashId)
        await this.$store.dispatch('postOrbitMShippingOrder', {
          id: this.orbitMSiteDetails?.id,
          kitId,
          addressId: this.orbitMSiteDetails?.shippingAddress?.addressId,
          firstName: this.orbitMSiteDetails?.shippingContact?.firstName,
          lastName: this.orbitMSiteDetails?.shippingContact?.lastName
        })
        Notification({
          text: `Kit ${kitHashId.toUpperCase()} dispatched successfully.`,
          style: NOTIFICATION_STYLES.SUCCESS
        })
      } catch (error) {
        const errorMessage = error?.response?.data?.message || error?.message
        Notification({
          text: `Failed to post shipping order. ${errorMessage}`,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    getLabel(shipment, sampleIdentifier) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          this.$store
            .dispatch('getLabel', shipment)
            .then((response) => {
              resolve({
                shipment: shipment,
                labelResponse: response,
                sampleIdentifier: sampleIdentifier
              })
            })
            .catch((error) => {
              reject(error)
            })
        }, 6000)
      })
    },
    generateLabels(shipment) {
      Loader.hide({
        text: `Generating ${shipment.type.toLowerCase()} label.`
      })
      return this.$store.dispatch('postRequestLabel', shipment)
    },
    dispatchSample(payload) {
      payload.sampleIdentifier = payload.code
      return this.$store.dispatch('postSampleDispatch', payload)
    },
    validateRegularOrderWithSamples(shippingOrderId, sampleIdentifiers) {
      return this.$store.dispatch('postValidateShippingOrderWithDispatch', {
        shippingOrderId,
        sampleIdentifiers
      })
    },
    dispatchRegularOrder(shippingOrderId, sampleIdentifiers) {
      return this.$store.dispatch('postShippingOrderDispatch', {
        shippingOrderId,
        sampleIdentifiers
      })
    },
    markShippingOrderAsDispatched(payload) {
      this.shippingOrders.filter((order) => {
        if (order.shippingOrderId === payload.shippingOrderId) {
          order.dispatched = true
          order._rowVariant = 'primary'
        }
      })
      localStorage.setItem('ShippingOrder', JSON.stringify(this.shippingOrders))
      this.movePendingOrderToTopOfList()
    },
    printLabel(labelData, type, printGenre) {
      this.clearCode = true
      if (type === printers.A4) {
        const linkSource = `data:application/pdf;base64,${labelData.manifestData}`
        const downloadLink = document.createElement('a')
        const fileName = 'manifest.pdf'

        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
      return printService.printLabels(labelData, type, printGenre)
    },
    placeOrder(payload) {
      const data = this.shipments
        .filter((shipment) => shipment.dispatchType === payload)
        .map((shipment) => {
          return { id: shipment.id }
        })
      this.$store
        .dispatch('postShipmentOrder', data)
        .then((response) => this.getOrderSummary(response.data.id))
        .then((orderSummary) => {
          this.orderPlaced = true
          // Create a list of the dispatched shipments
          const shippingOrderIdsDispatched = this.shipments
            .filter((shipment) => shipment.dispatchType === payload)
            .map((shipment) => {
              return shipment.shippingOrderId
            })
          // Remove the dispatched items from the orders
          this.shippingOrders = this.shippingOrders.filter(
            (order) => shippingOrderIdsDispatched.indexOf(order.id) === -1
          )
          // Remove the dispatched items from the shipments
          this.shipments = this.shipments.filter(
            (shipment) => shipment.dispatchType !== this.selectedDispatchType
          )
          this.storeToLocalStorage()
          this.printLabel(
            { manifestData: orderSummary.data },
            printers.A4,
            printGenre.AUSPOST_MANIFEST
          )
          Notification({
            title:
              'Order placed sucessfully, please sign and place order manifest with shipping orders.',
            style: NOTIFICATION_STYLES.SUCCESS
          })
        })
        .catch((error) => this.showError(error))
    },
    getOrderSummary(shipmentOrderId) {
      return this.$store.dispatch('getShipmentOrderSummary', shipmentOrderId)
    },
    isMetaXploreShippingOrder(productCode) {
      return Object.values(metaXploreProductCode).includes(productCode)
    },
    async manuallyPrintLabels(payload) {
      let code =
        this.isResearchMicrobaManagedDispatch ||
        this.isResearchClientManagedDispatch
          ? payload.item.voucherCode
          : payload.item.sampleIdentifier

      switch (payload.labelType) {
        case labelTypes.NAME:
          const { productCode, kitId } = payload?.item || {}
          let { firstName, lastName } = payload?.item || {}
          if (this.isMetaXploreShippingOrder(productCode)) {
            const patient = await this.fetchPatientDetails(kitId)
            firstName = patient.firstName
            lastName = patient.lastName
            code = payload.item.kitHashId
          }

          this.printName(
            {
              firstName,
              lastName
            },
            code
          )
          break
        case labelTypes.ACTIVATION_CODE:
          this.printActivationCode({
            activationCode: payload.item.activationCode,
            sampleIdentifier: code
          })
          break
        case labelTypes.ACTIVATION_LABEL:
          this.printActivationLabel({ activationCode: payload.item.kitHashId })
          break
        default:
          const shipments = this.shipments.filter(
            (order) => order.shippingOrderId === payload.item.id
          )
          const shipment = shipments.find(
            (order) => order.type === payload.labelType
          )
          this.printShipmentsAddress(shipment, code)
      }
    },
    async deleteAllShipments(payload) {
      const result = await Notification({
        title: 'Remove all shipments from Shipping Order?',
        text: `Just checking you wanted to remove all shipments from Shipping Order #${
          payload.item.shippingOrderId
        }${payload.extraText || ''}?`,
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, keep it'
      })
      try {
        if (result.value) {
          await this.$store.dispatch(
            'deleteShipmentsByShippingOrder',
            payload.item.shippingOrderId
          )

          // Remove orders with no shipments from cache
          this.shippingOrders = this.shippingOrders.filter(
            (order) => order.shippingOrderId !== payload.item.shippingOrderId
          )
          this.shipments = this.shipments.filter(
            (order) => order.shippingOrderId !== payload.item.shippingOrderId
          )
          this.storeToLocalStorage()

          Notification({
            text: 'Successfully deleted all shipments',
            style: NOTIFICATION_STYLES.SUCCESS
          })
        }
      } catch (error) {
        Notification({
          text: error?.response?.data?.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    deleteShipmentFromCache(payload) {
      Notification({
        title: 'Remove Shipping Order',
        text: `Just checking you wanted to remove this shipment (#${payload.item.id}) from shipping orders?`,
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.deleteShipmentFromCacheUnconfirmed(payload)
        }
      })
    },
    async deleteShipmentFromCacheUnconfirmed(payload) {
      const shippingId = payload.item.id
      try {
        this.shippingOrders = this.shippingOrders.filter(
          (order) => order.id !== shippingId
        )
        this.shipments = this.shipments.filter(
          (order) => order.id !== shippingId
        )
        this.storeToLocalStorage()
        Notification({
          text: 'Successfully deleted shipping order',
          style: NOTIFICATION_STYLES.SUCCESS
        })
      } catch (error) {
        Notification({
          text: error?.response?.data?.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    async deleteDispatchmentFromCache(payload) {
      const result = await Notification({
        title: `Remove ${payload.item.type} Shipment`,
        text: `Just checking you wanted to remove this ${payload.item.type} shipment with tracking id ${payload.item.trackingIdentifier}?`,
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, keep it'
      })
      try {
        if (result.value) {
          await this.$store.dispatch(
            'deleteShipmentByShipmentId',
            payload.item.id
          )

          // Remove this dispatchment from shipments and shipping orders
          this.shipments = this.shipments.filter(
            (order) => order.id !== payload.item.id
          )
          const relevantOrder = this.shippingOrders.find(
            (order) => order.id === payload.item.shippingOrderId
          )
          const updatedDispatchments = relevantOrder.dispatchments.filter(
            (d) => d.id !== payload.item.id
          )
          relevantOrder.dispatchments = updatedDispatchments

          // If shipping order now has no shipments, remove shipping order from cache too.
          if (updatedDispatchments.length <= 0) {
            this.shippingOrders = this.shippingOrders.filter(
              (order) => order.shippingOrderId !== payload.item.shippingOrderId
            )
          }

          this.storeToLocalStorage()

          Notification({
            text: 'Successfully deleted shipment ',
            style: NOTIFICATION_STYLES.SUCCESS
          })
        }
      } catch (error) {
        console.error(error)
        Notification({
          text: error?.response?.data?.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    saveShippingOrder(payload) {
      this.$store
        .dispatch('putShippingOrder', payload)
        .then((response) => {
          Notification({
            title: 'Shipping order address updated successfully.',
            style: NOTIFICATION_STYLES.SUCCESS
          })
          this.updateExistingOrderAddress(response && response.data)
        })
        .catch((error) => {
          this.showError(error)
        })
    },
    showError(error) {
      console.log(error)
      Notification({
        text: error.response?.data.message || '',
        style: NOTIFICATION_STYLES.ERROR
      })
    },
    printActivationCode(data) {
      const labelData = {
        firstLine: data.activationCode,
        lastLine: data.sampleIdentifier
      }
      this.printLabel(labelData, printers.LABEL, printGenre.ACTIVATION_CODE)
    },
    printActivationLabel({ activationCode }) {
      this.printLabel(
        { activationCode },
        printers.LABEL,
        printGenre.COBIOME_ACTIVATION_QR
      )
    },
    printName(data, sampleIdentifierOrVoucherCode) {
      const labelData = {
        firstLine: data.firstName,
        secondLine: data.lastName,
        lastLine: sampleIdentifierOrVoucherCode
      }
      this.printLabel(labelData, printers.LABEL, printGenre.KIT_OWNER)
    },
    printShipmentsAddress(shipment, sampleIdentifier) {
      if (shipment) {
        this.getLabel(shipment, sampleIdentifier)
          .then((response) => {
            const labelData = {
              addressLabel: response.labelResponse.data,
              sampleIdentifier: sampleIdentifier
            }
            this.printLabel(
              labelData,
              printers.ADDRESS,
              printGenre.AUSPOST_ADDRESS_LABELS
            )
          })
          .catch((error) => {
            this.showError(error)
          })
      }
    },
    setNextShippingOrder(payload) {
      this.nextShippingOrder = payload
        ? this.shippingOrders.find((order) => order.id === payload.id)
        : this.nextShippingOrder
    },
    resetDataForNextScan() {
      let nextShippingOrder = this.nextShippingOrder
      if (this.isRegularDispatch) {
        [nextShippingOrder] = this.regularInsightOrders
      } else if (this.isDistibutorDispatch) {
        [nextShippingOrder] = this.distributorOrders
      } else if (this.isResearchMicrobaManagedDispatch) {
        [nextShippingOrder] = this.researchMicrobaManagedOrders
      }
      this.nextShippingOrder = nextShippingOrder
    },
    updateExistingOrderDetails(
      shippingOrderId,
      sampleIdentifier,
      activationCode
    ) {
      this.shippingOrders.find((order) => {
        if (order.id === shippingOrderId) {
          order.dispatched = !order.dispatched
          order._rowVariant = order.dispatched ? 'success' : null
          order.sampleIdentifier = order.dispatched ? sampleIdentifier : null
          order.activationCode = activationCode
        }

        if (!order.dispatched) {
          order.dispatchments = []
        }
      })
      this.storeToLocalStorage()
    },
    updateExistingOrderAddress(updatedAddress) {
      const indexToUpdate = this.shippingOrders.findIndex(
        (order) => order.id === updatedAddress.id
      )
      this.shippingOrders[indexToUpdate].addressLine1 =
        updatedAddress.addressLine1
      this.shippingOrders[indexToUpdate].addressLine2 =
        updatedAddress.addressLine2
      this.shippingOrders[indexToUpdate].suburb = updatedAddress.suburb
      this.shippingOrders[indexToUpdate].state = updatedAddress.state
      this.shippingOrders[indexToUpdate].postcode = updatedAddress.postcode
      this.shippingOrders[indexToUpdate].country = updatedAddress.country
      this.storeToLocalStorage()
    },
    switchDispatchType(dispatchType) {
      this.selectedDispatchType = dispatchType
    },
    storeToLocalStorage() {
      localStorage.setItem('ShippingOrder', JSON.stringify(this.shippingOrders))
      localStorage.setItem('Shipments', JSON.stringify(this.shipments))
    },
    undispatchOrder(payload) {
      Notification({
        title: 'Undispatch Order',
        text: `Just checking you wanted to undispatch this order (#${payload.item.id})?`,
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes, undispatch it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('postUnDispatch', payload.item)
            .then(() => {
              this.shipments = this.shipments.filter(
                (shipment) => shipment.shippingOrderId !== payload.item.id
              )
              this.updateExistingOrderDetails(
                payload.item.id,
                payload.item.sampleIdentifier
              )
              this.unassociateSamplesFromFulfillments(
                payload.item.fulfillmentCondition.fulfillment
              )
              this.storeToLocalStorage()
            })
            .catch((error) => {
              this.showError(error)
            })
        }
      })
    },
    clearFulfillments(payload) {
      Notification({
        title: 'Undispatch Order',
        text: `Just checking you wanted to undispatch this order (#${payload.item.id})?`,
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes, undispatch it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.unassociateSamplesFromFulfillments(
            payload.item.fulfillmentCondition.fulfillment
          )
        }
      })
    },
    unassociateSamplesFromFulfillments(fulfillment) {
      fulfillment.forEach((fulfillment) => {
        fulfillment.sampleIdentifier = null
      })
      this.storeToLocalStorage()
    },
    movePendingOrderToTopOfList() {
      const nextPendingShippingOrder = this.selectedModeShippingOrders.find(
        (shippingOrder) => !shippingOrder.dispatched
      )
      if (nextPendingShippingOrder) {
        this.shippingOrders = [
          nextPendingShippingOrder,
          ...this.shippingOrders.filter(
            (shippingOrder) => shippingOrder.id !== nextPendingShippingOrder.id
          )
        ]
        this.storeToLocalStorage()
      }
    },
    moveShippingOrderToBottomOfList() {
      if (this.nextShippingOrder) {
        this.shippingOrders = this.shippingOrders.filter(
          (order) => order.id !== this.nextShippingOrder.id
        )
        this.shippingOrders.push(this.nextShippingOrder)
        this.storeToLocalStorage()
      }
    },
    promoCodeAcknowledgementDialogue(promoCode) {
      Notification({
        title: 'Promotional Code found',
        text: `Just checking you have followed the guidelines associated with promotional code <b>${promoCode}</b>`,
        style: NOTIFICATION_STYLES.WARNING,
        showCancelButton: false,
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (!result.value) {
          this.promoCodeAcknowledgementDialogue(promoCode)
        }
      })
    },
    fetchAndPrintVoucherCode(payload) {
      const kitId = payload?.data?.kitId
      if (kitId) {
        this.$store
          .dispatch('getVoucherCodeByKitId', kitId)
          .then((response) => {
            const voucherCode = response?.data?.voucherCode
            const labelData = {
              firstLine: voucherCode
            }
            this.printLabel(labelData, printers.LABEL, printGenre.VOUCHER_CODE)
          })
          .catch((error) => this.showError(error))
      }
    },
    isSampleIdentifierAlreadyScanned(sampleIdentifier) {
      const fulfilledSampleIdentifiers = this.shippingOrders
        .map(
          (shippingOrder) => shippingOrder?.fulfillmentCondition?.fulfillment
        )
        .filter((fulfillment) => Boolean(fulfillment))
        .flat()
        .map((fulfillment) => fulfillment.sampleIdentifier)
        .filter((sampleIdentifier) => Boolean(sampleIdentifier))
      return fulfilledSampleIdentifiers.includes(sampleIdentifier)
    },
    async fetchPatientDetails(kitId) {
      try {
        Loader.hide()
        const { data } = await this.$store.dispatch('getReferralByKitId', {
          kitId
        })
        const { patient } = data
        Loader.hide()
        return patient
      } catch (error) {
        Notification({
          text: error.response.data.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  padding: 1em;
}
</style>
