<template>
  <li class="list-group-item kit-item" :class="className">
    <div>
      <b-card-group deck>
        <b-card title="" header-tag="header" footer-tag="footer">
          <h6 slot="header" class="mb-0">
            <span>{{
              getSampleOrKitStatusLabel(kit.kitStatus, kit.sampleStatus)
            }}</span>
            <span class="pull-right">
              {{ kit.name || dateString }} (Kit ID: {{ kit.id }})
              <SampleToolbar
                v-if="kit.sampleIdentifier"
                :sample-identifier="kit.sampleIdentifier"
                :sample-status="kit.sampleStatus"
                :product-type="kit.productType"
                :product-code="kit.productCode"
                :can-aliquot="!kit.aliquotOfSample"
                class="ml-2"
                style="width: 2rem"
              />
            </span>
          </h6>
          <em slot="footer">
            <div class="pull-right">
              <b-button
                v-if="canRegenerateSurvey"
                variant="primary"
                @click="regenerateSurvey"
                >Survey Regeneration
              </b-button>
              <b-button
                v-if="kit.sampleStatus == 'RESULTS_AVAILABLE'"
                variant="primary"
                @click="onClickCompleteCoach"
                >Coach Completed
              </b-button>
              <b-button variant="primary" @click="onClickAdminActions"
                >Admin</b-button
              >
              <b-button
                :aria-controls="`collapse${kit.id}`"
                :aria-expanded="timelineVisible ? 'true' : 'false'"
                variant="outline-primary"
                @click="timelineVisible = !timelineVisible"
              >
                <i
                  v-if="timelineVisible"
                  class="fa fa-minus-square"
                  aria-hidden="true"
                />
                <i v-else class="fa fa-plus-square" aria-hidden="true" />
                Timeline
              </b-button>
              <b-button
                v-if="canCopyQuestionnaire"
                variant="primary"
                @click="enableQuestionnaireTransfer = true"
                >Copy Questionnaires
              </b-button>
              <b-button variant="primary" @click="enableTransferKit = true"
                >Transfer</b-button
              >
            </div>
            <div>
              <b-collapse
                :id="`collapse${kit.id}`"
                v-model="timelineVisible"
                class="mt-2"
              >
                <kit-timeline v-if="timelineVisible" :kit-id="kit.id" />
              </b-collapse>
            </div>
          </em>
          <div class="card-text row">
            <div class="col-md-6">
              <div v-if="enableSurveys" class="progress-container">
                <div class="d-none d-lg-block col-12">
                  <div
                    v-for="stat in kit.surveysStats"
                    :key="stat.surveyType"
                    class="progress-wrap"
                  >
                    <progressBar
                      :total="stat.totalQuestions"
                      :enable-questionnaire-transfer="
                        enableQuestionnaireTransfer
                      "
                      :kits="kits"
                      :kit-id="kit.id"
                      :count="stat.completedQuestions"
                      :tip="stat.surveyType"
                      @questionnaire-copied="$emit('questionnaire-copied')"
                    />
                  </div>
                </div>
                <div class="d-sm-block d-lg-none mobile-progress-wrapper">
                  <KitProgressBar
                    v-for="stat in kit.surveysStats"
                    :key="stat.surveyType"
                    :total="stat.totalQuestions"
                    :enable-questionnaire-transfer="enableQuestionnaireTransfer"
                    :kits="kits"
                    :count="stat.completedQuestions"
                    :title="stat.surveyType"
                    :kit-id="kit.id"
                    :survey-type="stat.surveyType"
                    @questionnaire-copied="$emit('questionnaire-copied')"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <KitCard :kit="kit" />
            </div>
            <transferKit
              v-if="enableTransferKit"
              class="col-md"
              :kit="kit"
              :owner="owner"
              @kit-transferred="passMessageUpstream"
            />
          </div>
        </b-card>
      </b-card-group>
    </div>
  </li>
</template>

<script>
import authService from '@/services/authService'
import websocketMessageTypes from '@/enums/websocketMessageTypes'
import moment from 'moment'
import { Loader, Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import dateMixin from '@/mixins/date'
import {
  kitStatusLabels,
  sampleStatus as SAMPLE_STATUS
} from '@/enums/kitItemStatus'
import progressBar from '@/components/elements/progressBar'
import KitProgressBar from '@/components/elements/KitProgressBar'
import apiStringMappings from '@/mixins/apiStringMapping'
import KitTimeline from '@/components/elements/KitTimeline'
import transferKit from '@/components/elements/transferKit'
import NotificationQueueService from '@/services/notificationQueueService'
import queues from '@/enums/queues'
import SampleToolbar from '@/components/elements/SampleToolbar'
import KitCard from '@/components/elements/KitCard'

export default {
  name: 'KitItem',
  components: {
    progressBar,
    KitProgressBar,
    KitTimeline,
    transferKit,
    SampleToolbar,
    KitCard
  },
  mixins: [apiStringMappings, dateMixin],
  props: ['kit', 'kits', 'canCopyQuestionnaire', 'owner'],
  data: function () {
    return {
      timelineVisible: false,
      enableQuestionnaireTransfer: null,
      enableTransferKit: null,
      originalReferralCode: null,
      referralCodeInfo: null
    }
  },

  computed: {
    canRegenerateSurvey: function () {
      return (
        this.kit.sampleStatus === SAMPLE_STATUS.RESULTS_AVAILABLE ||
        this.kit.sampleStatus ===
          SAMPLE_STATUS.MICROBIOME_COACH_SESSION_COMPLETED
      )
    },
    enableSurveys: function () {
      return process.env.VUE_APP_ENABLE_SURVEYS === 'true' || false // .env by default are strings
    },
    status: function () {
      return this.kit.sampleStatus ? this.kit.sampleStatus : this.kit.kitStatus
    },
    dateString: function () {
      var dateKey = null
      if (this.kit.activatedDate) {
        dateKey = this.kit.activatedDate
      } else if (this.kit.redeemedDate) {
        dateKey = this.kit.redeemedDate
      } else {
        // If all date keys are empty - fall back to todays as a default.
        dateKey = new Date()
      }
      const theDate = moment(dateKey)
      const month = theDate.format('MMMM')
      const year = theDate.format('Y')
      return month + ' ' + year
    },
    reportLink: function () {
      return '/report/' + this.kit.id
    },
    className: function () {
      const kitStatus = this.kit.kitStatus
        ? this.kit.kitStatus.toLowerCase().replace(/_/g, '-')
        : ''
      const sampleStatus = this.kit.sampleStatus
        ? this.kit.sampleStatus.toLowerCase().replace(/_/g, '-')
        : ''
      return kitStatus + ' ' + sampleStatus
    },
    unlocked: function () {
      // Check all answerd / total questions. If > 100 return true
      if (this.kit.surveysStats == null) return false
      const total = this.kit.surveysStats.reduce((acc, el) => {
        const val = Number(el.totalQuestions)
        return acc + val
      }, 0)
      const completed = this.kit.surveysStats.reduce((acc, el) => {
        const val = Number(el.completedQuestions)
        return acc + val
      }, 0)
      return (completed / total) * 100 >= 100
    },
    notActivated: function () {
      return (
        this.kit.kitStatus === 'UNASSIGNED' || this.kit.kitStatus === 'ASSIGNED'
      )
    }
  },
  methods: {
    onClickProgress: function (type) {
      if (this.enableSurveys === false) return
      const slug = this.sampleToSlug(type)
      const route = `/surveys/${slug}/${this.kit.id}`
      this.$router.push(route)
    },
    onClickAdminActions: function () {
      this.$emit('onClickAdminActions')
    },
    onClickRedeem: function () {
      this.$emit('onClickRedeem')
    },
    onClickSend: function () {
      this.$emit('onClickSend')
    },
    onClickPurchase: function () {
      this.$emit('onClickPurchase')
    },
    onClickReplacement: function () {
      this.$emit('onClickReplacement')
    },
    async onClickCompleteCoach() {
      const requestReview = await Notification({
        title: 'Customer review',
        text: 'Do you want to request a customer review?',
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      const coachCompleted = await Notification({
        title: 'Complete coaching',
        text: `A customer review will ${
          requestReview.value ? '' : 'NOT'
        } be requested. <br/>Has the coach session for this kit has been completed?`,
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (coachCompleted.value) {
        // Send the review email
        let reviewRequested
        let reviewError
        if (requestReview.value) {
          try {
            reviewRequested = await this.$store.dispatch(
              'postEmailCustomerReview',
              {
                to: this.owner.email
              }
            )
          } catch (error) {
            reviewError = `The email to request a review failed: ${error.message}`
            Notification({
              text: reviewError,
              style: NOTIFICATION_STYLES.ERROR
            })
          }
        }

        let reviewSuffix = ''
        if (requestReview.value) {
          if (reviewError) {
            reviewSuffix =
              '.<br/><br/>But an ERROR occurred while sending the email.'
          } else {
            if (reviewRequested) {
              // Happy path
              reviewSuffix = ' and customer review requested.'
            } else {
              reviewSuffix =
                '.<br/><br/>But the customer review email was NOT sent.'
            }
          }
        } else {
          reviewSuffix = ' but customer review not requested.'
        }

        // Complete the coaching
        this.$store
          .dispatch('putSample', {
            id: this.kit.sampleId,
            sampleStatus: 'MICROBIOME_COACH_SESSION_COMPLETED'
          })
          .then((ok) => {
            Notification({
              text: `Microbiome coach session completed${reviewSuffix}`,
              style: NOTIFICATION_STYLES.SUCCESS
            })
          })
          .catch((error) => {
            if (requestReview.value && reviewRequested && !reviewError) {
              reviewSuffix = '. <br/><br/>BUT customer review WAS requested.'
            }
            Notification({
              text: `Unable to complete session${reviewSuffix}`,
              style: NOTIFICATION_STYLES.ERROR
            })
            console.log(error)
          })
      }
    },
    hideTimeline() {
      this.timelineVisible = false
    },
    getSampleOrKitStatusLabel: function (kitStatus, sampleStatus) {
      const status = sampleStatus || kitStatus
      return kitStatusLabels[status] ? kitStatusLabels[status] : 'Unknown'
    },
    passMessageUpstream: function () {
      this.enableTransferKit = null
      this.$emit('kit-transferred')
    },
    regenerateSurvey: function () {
      Notification({
        title: 'Survey regeneration',
        text: 'Do you want to regenerate survey?',
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.value) {
          Loader.show()
          // process mental health survey
          const username = authService.getUsername()
          const payload = {
            sampleIdentifier: this.kit.sampleIdentifier
          }
          const mentalHealthNotificationQueue = new NotificationQueueService(
            queues.MENTAL_HEALTH_SURVEY_PROCESSING
          )
          const mentalSurveyRegenerationPromise = mentalHealthNotificationQueue.sendMessage(
            payload,
            true,
            websocketMessageTypes.PRIVATE,
            username
          )
          // process FFQ survey
          const ffqNotificationQueue = new NotificationQueueService(
            queues.FFQ_PROCESSING
          )
          const FFQSurveyRegenerationPromise = ffqNotificationQueue.sendMessage(
            payload,
            true,
            websocketMessageTypes.PRIVATE,
            username
          )
          // process PAQ survey
          const paqNotificationQueue = new NotificationQueueService(
            queues.PAQ_PROCESSING
          )
          const PAQSurveyRegenerationPromise = paqNotificationQueue.sendMessage(
            payload,
            true,
            websocketMessageTypes.PRIVATE,
            username
          )

          Promise.all([
            mentalSurveyRegenerationPromise,
            FFQSurveyRegenerationPromise,
            PAQSurveyRegenerationPromise
          ])
            .then(() => {
              Notification({
                text: 'Survey regeneration message sent.',
                style: NOTIFICATION_STYLES.SUCCESS
              })
            })
            .catch((error) => {
              console.log(error)
              Notification({
                text:
                  'Failed to trigger survey regeneration, please try again.',
                style: NOTIFICATION_STYLES.ERROR
              })
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.progress-wrap {
  margin-bottom: 0.2rem;
}

.label_bold {
  font-weight: bold;
}

.card-body {
  paddding-top: 0.25em;
}

.template-page main header {
  margin-bottom: 15px;
}
</style>
