const kitStatuses = Object.freeze({
  ALLOCATED: 'ALLOCATED',
  ASSIGNED: 'ASSIGNED',
  DISPATCHED_BY_MICROBA: 'DISPATCHED_BY_MICROBA',
  FAILED_OVERLOADED: 'FAILED_OVERLOADED',
  FAILED_PROCESSING: 'FAILED_PROCESSING',
  FAILED_SWAB_OTHER: 'FAILED_SWAB_OTHER',
  FAILED_UNDERLOADED: 'FAILED_UNDERLOADED',
  INVALIDATED: 'INVALIDATED',
  PROCESSING: 'PROCESSING',
  RECEIVED_BY_CUSTOMER: 'RECEIVED_BY_CUSTOMER',
  RECEIVED_BY_MICROBA: 'RECEIVED_BY_MICROBA',
  REDEEMED: 'REDEEMED',
  REPLACED: 'REPLACED',
  REQUESTED: 'REQUESTED',
  RESULTS_AVAILABLE: 'RESULTS_AVAILABLE',
  UNASSIGNED: 'UNASSIGNED'
})

const sampleReceivedConditions = Object.freeze({
  GOOD: { label: 'Good condition', color: '#009731' },
  POTENTIALLY_UNDERLOADED: {
    label: 'Potentially underloaded',
    color: '#DA2525'
  },
  POTENTIALLY_OVERLOADED: { label: 'Potentially overloaded', color: '#DA8025' },
  UNCAPPED: { label: 'Uncapped', color: '#DA2580' },
  WITHOUT_LABEL_OR_ID: { label: 'Without label or ID', color: '#00A6FF' },
  NOT_IN_ZIP_LOCK_ENVELOPES: {
    label: 'Not in zip lock envelope',
    color: '#0027FF'
  },
  DAMAGED_ENVELOPES: { label: 'Damaged envelope', color: '#00FFD8' },
  REPACKAGED_AND_POSTED: { label: 'Repackaged and posted', color: '#A9B219' },
  IDENTIFIED_SAMPLE: { label: 'Identified sample', color: '#9419E6' },
  UNSIGNED: { label: 'Unsigned', color: '#06FD02' },
  MISSING_2ND_SAMPLE_ID: {
    label: 'Missing 2nd sample ID - MetaPanel/Clinical',
    color: '#00FFD8'
  },
  FAILED_DELIVERABLE_TIMEFRAME: {
    label: 'Failed deliverable timeframe - MetaPanel/Clinical',
    color: '#06FD02'
  }
})

const kitStatus = Object.freeze({
  ALLOCATED: 'ALLOCATED',
  ASSIGNED: 'ASSIGNED',
  DISPATCHED_BY_MICROBA: 'DISPATCHED_BY_MICROBA',
  INVALIDATED: 'INVALIDATED',
  PROCESSING: 'PROCESSING',
  REFUNDED: 'REFUNDED',
  REQUESTED: 'REQUESTED',
  UNASSIGNED: 'UNASSIGNED'
})

const sampleStatus = Object.freeze({
  ACCESSIONING: 'ACCESSIONING',
  ACCESSIONING_ON_HOLD: 'ACCESSIONING_ON_HOLD',
  ALLOCATED: 'ALLOCATED',
  FAILED_AT_RECEIPTING: 'FAILED_AT_RECEIPTING',
  FAILED_OVERLOADED: 'POTENTIALLY_OVERLOADED',
  FAILED_PROCESSING: 'FAILED_PROCESSING',
  FAILED_SWAB_OTHER: 'FAILED_SWAB_OTHER',
  FAILED_UNDERLOADED: 'POTENTIALLY_UNDERLOADED',
  INVALIDATED: 'INVALIDATED',
  MICROBIOME_COACH_SESSION_COMPLETED: 'MICROBIOME_COACH_SESSION_COMPLETED',
  READY_FOR_ACCESSIONING: 'READY_FOR_ACCESSIONING',
  RECEIVED_BY_MICROBA: 'RECEIVED_BY_MICROBA',
  RESULTS_AVAILABLE: 'RESULTS_AVAILABLE',
  RESULTS_ON_HOLD: 'RESULTS_ON_HOLD',
  RESULTS_PROCESSED: 'RESULTS_PROCESSED',
  RESULTS_PROCESSING_FAILED: 'RESULTS_PROCESSING_FAILED',
  STOWED: 'STOWED',
  UNALLOCATED: 'UNALLOCATED',
  UNLABELLED: 'UNLABELLED'
})

function cannotReprocessSample(status) {
  return [
    sampleStatus.RESULTS_AVAILABLE,
    sampleStatus.MICROBIOME_COACH_SESSION_COMPLETED
  ].includes(status)
}

const kitStatusLabels = Object.freeze({
  ACCESSIONING: 'Accessioning',
  ASSIGNED: 'Kit Available',
  PROCESSING: 'Processing',
  UNASSIGNED: 'Kit Available',
  INVALIDATED: 'Invalidated',
  REQUESTED: 'Packing',
  ALLOCATED: 'Sent',
  DISPATCHED_BY_MICROBA: 'Sent',
  RECEIVED_BY_CUSTOMER: 'Received',
  RESULTS_AVAILABLE: 'Results Available',
  REPLACED: 'Replaced',
  REFUNDED: 'Refunded',
  FAILED_OVERLOADED: 'Error',
  FAILED_UNDERLOADED: 'Error',
  FAILED_SWAB_OTHER: 'Error',
  FAILED_PROCESSING: 'Error',
  FAILED_AT_RECEIPTING: 'Error',
  REDEEMED: 'Kit Redeemed',
  MICROBIOME_COACH_SESSION_COMPLETED: 'Microbiome Coach Session Completed',
  RECEIVED_BY_MICROBA: 'Received By Lab'
})

export {
  kitStatuses,
  kitStatusLabels,
  kitStatus,
  sampleStatus,
  cannotReprocessSample,
  sampleReceivedConditions
}
