<script>
import { sampleStatus } from '@/enums/kitItemStatus'
import { QCStatus, Report } from '@/Models/qcReport'
import { Loader, Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import QCFilter from './qcFilter'
export default {
  name: 'QCReport',
  components: { QCFilter },
  props: {
    qcReport: {
      type: Array,
      default: () => []
    },
    sampleStatus: {
      type: String,
      default: undefined
    },
    canPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      QCStatus,
      sampleStatuses: sampleStatus,
      reportExpanded: {},
      showWarnings: true,
      showErrors: true,
      showSuccess: true,
      fields: [
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'pageNo',
          label: 'Page No.'
        },
        {
          key: 'rms',
          label: 'Score'
        },
        {
          key: 'statusMessage',
          label: 'Status Message'
        },
        {
          key: 'QCDiffImage',
          label: 'View Image'
        }
      ]
    }
  },
  computed: {
    allExpanded() {
      return Object.values(this.reportExpanded).every(
        (isExpanded) => isExpanded
      )
    },
    successCount() {
      return (
        this?.qcReport?.filter(
          (report) => report.reportStatus === QCStatus.SUCCESS
        )?.length || 0
      )
    },
    warningCount() {
      return (
        this?.qcReport?.filter(
          (report) => report.reportStatus === QCStatus.WARNING
        )?.length || 0
      )
    },
    errorCount() {
      return (
        this?.qcReport?.filter(
          (report) => report.reportStatus === QCStatus.FAILURE
        )?.length || 0
      )
    },
    filteredReports() {
      return this?.qcReport?.filter((report) => {
        return (
          (report.reportStatus === QCStatus.FAILURE && this.showErrors) ||
          (report.reportStatus === QCStatus.WARNING && this.showWarnings) ||
          (report.reportStatus === QCStatus.SUCCESS && this.showSuccess)
        )
      })
    }
  },
  watch: {
    qcReport: {
      handler(qcReport) {
        const newExpanded = {}
        qcReport.forEach((report) => {
          const key = this.getReportKey(report)
          newExpanded[key] = !this.allExpanded
        })
        this.reportExpanded = newExpanded
      },
      immediate: true
    }
  },
  methods: {
    isWarning(report, pageNumber) {
      return report?.warningReasons?.find(
        (reason) => reason.cause === pageNumber
      )
    },
    isFailure(report, pageNumber) {
      return report?.failureReasons?.find(
        (reason) => reason.cause === pageNumber
      )
    },
    noOfPagesCheckFailed(report) {
      return report?.failureReasons?.find(
        (reason) => reason.cause === 'pageNumber'
      )
    },
    fileSizeCheckFailed(report) {
      return report?.failureReasons?.find(
        (reason) => reason.cause === 'fileSize'
      )
    },
    fileSizeCheckWarning(report) {
      return report?.warningReasons?.find(
        (reason) => reason.cause === 'fileSize'
      )
    },
    getReportKey(report) {
      return `${report?.sampleIdentifier}-${report?.sample?.distributor}-${report?.sample?.variant}-${report?.sample?.theme}`
    },
    getReportStatusClass(report) {
      switch (report?.reportStatus) {
        case QCStatus.FAILURE:
          return 'table-danger'
        case QCStatus.WARNING:
          return 'table-primary'
        case QCStatus.SUCCESS:
          return 'table-success'
      }
    },
    toggleCollapse(report) {
      const key = this.getReportKey(report)
      this.reportExpanded = {
        ...this.reportExpanded,
        [key]: !this.reportExpanded[key]
      }
    },
    toggleAllReports() {
      const newExpanded = {}
      Object.keys(this.reportExpanded).forEach((key) => {
        newExpanded[key] = !this.allExpanded
      })
      this.reportExpanded = newExpanded
    },
    async displayImageInNewTab(report, item) {
      let imageInBase64
      try {
        Loader.show()
        const params = {
          sampleIdentifier: report?.sampleIdentifier,
          imageSource: report?.imageSource,
          imageName: item.diffImageName
        }
        imageInBase64 = await Report.getComparisonImage(params)
        Loader.hide()
      } catch (error) {
        Notification({
          title: 'Failed',
          text: `Unable to open this image.`,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
      const src = `data:image/png;base64, ${imageInBase64}`
      const newTab = window.open()
      newTab.document.body.innerHTML = `<img width="600" height="800" src="${src}">`
    },
    previewReport(report) {
      this.$emit('preview-report', report)
    }
  }
}
</script>
<template>
  <div>
    <div class="d-inline-block col-md-6 col-sm-12">
      <!-- Sample Status Starts -->
      <div v-if="sampleStatus === sampleStatuses.RESULTS_PROCESSING_FAILED">
        <font-awesome-icon
          :icon="['fas', 'fa-times-circle']"
          class="text-danger mr-1 mb-3"
        />
        Sample process failed
      </div>
      <div v-else>
        <font-awesome-icon
          :icon="['fas', 'fa-check-circle']"
          class="text-success mr-1 mb-3"
        />
        Sample process succeeded
      </div>
      <!-- Sample Status Ends -->
    </div>
    <q-c-filter
      v-if="qcReport && qcReport.length > 0"
      class="p-0 d-inline-flex col-md-6 col-sm-12"
      button-size="sm"
      :success-visible="showSuccess"
      :warning-visible="showWarnings"
      :error-visible="showErrors"
      :success-count="successCount"
      :warning-count="warningCount"
      :error-count="errorCount"
      @success-toggle="showSuccess = $event"
      @warning-toggle="showWarnings = $event"
      @error-toggle="showErrors = $event"
    />
    <!-- Missing QC Report Starts -->
    <div v-if="!qcReport || qcReport.length === 0">
      <font-awesome-icon
        :icon="['fas', 'fa-times-circle']"
        class="text-danger mr-1"
      />
      This sample is missing QC report
    </div>
    <!-- Missing QC Report Ends -->
    <div v-for="report in filteredReports" v-else :key="getReportKey(report)">
      <h4
        class="report-header p-2 rounded"
        :class="getReportStatusClass(report)"
        @click="toggleCollapse(report)"
      >
        {{ report.sampleIdentifier }} {{ report.sample.distributor }}
        {{ report.sample.variant }}
      </h4>
      <b-collapse :visible="reportExpanded[getReportKey(report)]">
        <!-- Preview Button starts here -->
        <b-button class="pull-right" size="sm" @click="previewReport(report)">
          Preview
        </b-button>
        <!-- Preview Button ends -->

        <!-- No of Pages Starts -->
        <div v-if="noOfPagesCheckFailed(report)">
          <font-awesome-icon
            :icon="['fas', 'fa-times-circle']"
            class="text-danger mr-1"
          />
          {{
            `Number of pages: ${report.noOfPages} &lt; threshold: ${qcReport.noOfPagesThreshold}`
          }}
        </div>
        <div v-else>
          <font-awesome-icon
            :icon="['fas', 'fa-check-circle']"
            class="text-success mr-1"
          />
          {{ `Number of pages: ${report.noOfPages}` }}
        </div>
        <!-- No of Pages Ends -->

        <!-- File size threshold warning starts -->

        <div v-if="fileSizeCheckFailed(report)">
          <font-awesome-icon
            :icon="['fas', 'fa-times-circle']"
            class="text-danger mr-1"
          />
          {{
            `File size: ${report.fileSizeInMb} &#8924; failure threshold: ${report.fileSizeFailureThresholdInMB}`
          }}
        </div>

        <div v-else-if="fileSizeCheckWarning(report)">
          <font-awesome-icon
            :icon="['fas', 'fa-exclamation-triangle']"
            class="text-yellow mr-1"
          />
          {{
            `Failure threshold: ${report.fileSizeFailureThresholdInMB} &lt; file size: ${report.fileSizeInMb} &#8924; warning threshold: ${report.fileSizeWarningThresholdInMB}`
          }}
        </div>

        <div v-else>
          <font-awesome-icon
            :icon="['fas', 'fa-check-circle']"
            class="text-success mr-1"
          />
          {{ `File size: ${report.fileSizeInMb}` }}
        </div>

        <!-- File size threshold warning ends -->

        <!-- Page Warnings/Errors starts here -->
        <b-table
          :key="report.sampleIdentifier"
          :items="report.pages"
          :fields="fields"
        >
          <template v-slot:cell(status)="subRow">
            <i
              v-if="isFailure(report, subRow.item.pageNo)"
              class="fas fa-times-circle text-danger"
            />
            <i
              v-else-if="isWarning(report, subRow.item.pageNo)"
              class="fa fa-exclamation-triangle text-yellow"
            />
            <i v-else class="fas fa-check-circle text-success" />
          </template>
          <template v-slot:cell(rms)="subRow">
            {{ `${subRow.item.rms.toFixed(2)}` }}
          </template>
          <template v-slot:cell(statusMessage)="subRow">
            <template v-if="isFailure(report, subRow.item.pageNo)">
              {{
                `score: ${subRow.item.rms.toFixed(
                  2
                )} &#8925; failing threshold: ${subRow.item.failingThreshold}`
              }}
            </template>
            <template v-else-if="isWarning(report, subRow.item.pageNo)">
              {{
                `warning threshold: ${
                  subRow.item.warningThreshold
                } &#8924; score: ${subRow.item.rms.toFixed(
                  2
                )} &lt; failing threshold: ${subRow.item.failingThreshold}`
              }}
            </template>
            <template v-else> success </template>
          </template>
          <template v-slot:cell(QCDiffImage)="subRow">
            <div class="subRow">
              <a
                href="#"
                @click.prevent="displayImageInNewTab(report, subRow.item)"
              >
                <font-awesome-icon :icon="['fas', 'fa-external-link-alt']" />
              </a>
            </div>
          </template>
        </b-table>
        <!-- Page Warning/Errors ends here -->
      </b-collapse>
    </div>
    <b-button class="pull-left" size="sm" @click="toggleAllReports">
      {{ allExpanded ? 'Collapse' : 'Expand' }} All Reports
    </b-button>
  </div>
</template>

<style scoped lang="scss">
.report-header {
  cursor: pointer;
}
</style>
