import httpClientService from '@/services/httpClientService'
import axios from 'axios'

export const doAsync = (
  store,
  {
    url,
    mutationType,
    method = 'get',
    data = {},
    postSuccess = null,
    responseType
  }
) => {
  if (mutationType) {
    store.commit(mutationType.PENDING)
  }

  return new Promise((resolve, reject) => {
    httpClientService
      .getAPIHttpClient()({ method, url, data, responseType })
      .then((response) => {
        if (mutationType) {
          store.commit(mutationType.PENDING, false)
          store.commit(mutationType.SUCCESS, response.data)
        }
        resolve(response)
      })
      .catch((error) => {
        if (mutationType) {
          store.commit(mutationType.PENDING, false)
          // Typically we use the error_description key but for password reset the api can only return an "message" key
          if (error.response) {
            store.commit(
              mutationType.FAILURE,
              error.response.data.error_description ||
                error.response.data.message
            )
          }
        }
        reject(error)
      })
  })
}

export const authAsync = (
  store,
  { url, mutationType, method = 'get', data = {}, postSuccess = null }
) => {
  if (mutationType) {
    store.commit(mutationType.PENDING)
  }

  const AUTHHTTP = axios.create({
    baseURL: process.env.VUE_APP_API_ROOT,
    auth: {
      username: process.env.VUE_APP_API_USER,
      password: process.env.VUE_APP_API_PASS
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })

  AUTHHTTP.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 503) {
        store.dispatch('maintenance/show', error.response?.data)
      }
      return Promise.reject(error)
    },
  )

  return new Promise((resolve, reject) => {
    AUTHHTTP({ method, url, data })
      .then((response) => {
        if (mutationType) {
          store.commit(mutationType.PENDING, false)
          store.commit(mutationType.SUCCESS, response.data)
        }
        resolve(response)
      })
      .catch((error) => {
        if (mutationType) {
          store.commit(mutationType.PENDING, false)
          // Typically we use the error_description key but for password reset the api can only return an "message" key
          if (error.response) {
            store.commit(
              mutationType.FAILURE,
              error.response.data.error_description ||
                error.response.data.message ||
                error.response.data.error
            )
          }
        }
        reject(error)
      })
  })
}
