<template>
  <Modal :show="show" class-name="p-5 pb-5" @close="close()">
    <template #header>
      <a
        v-if="action != null"
        class="backButton closeBlack mr-2"
        @click="action = null"
      >
        <font-awesome-icon :icon="['fa', 'fa-arrow-left']" aria-hidden="true" />
      </a>
      <a class="closeBlack closeButton" @click="close">
        <font-awesome-icon :icon="['fa', 'fa-times']" aria-hidden="true" />
      </a>
    </template>
    <div v-if="action == null" class="modal-main ml-4 mr-4">
      <h2 class="text-center">Take an action with this kit</h2>
      <div class="row button-container">
        <button
          class="btn btn-primary"
          @click="action = 'ReverseShippingRequest'"
        >
          Reverse shipping request
        </button>
        <button
          class="btn btn-primary"
          @click="action = 'RequestReplacementFree'"
          v-if="!hideRequestReplacementFree"
        >
          Request replacement (free)
        </button>
        <button
          class="btn btn-primary"
          @click="action = 'RequestReplacementCharge'"
        >
          Request replacement ($35)
        </button>
        <button class="btn btn-primary" @click="action = 'RequestRefund'">
          Refund
        </button>
        <button
          v-if="isLocal && kit.sampleId"
          class="btn btn-primary"
          @click="action = 'SetResultsAvailableCharge'"
        >
          Set "Results available"
        </button>
        <button class="btn btn-primary" @click="action = 'SetStatus'">
          Set Status
        </button>
        <button class="btn btn-primary" @click="action = 'MarkAsAPriority'">
          Mark as Priority
        </button>
        <button class="btn btn-primary" @click="action = 'SendAKit'">
          Send a kit
        </button>
      </div>
    </div>
    <div v-if="action == 'MarkAsAPriority'" class="modal-main">
      <h2 class="text-center">Make this sample a priority for the lab?</h2>
      <span v-if="!kit.sampleIdentifier"
        >The kit must be dispatched before it can be prioritised.</span
      >
      <button v-else class="btn btn-primary" @click="onClickMarkPriority">
        Make a Priority
      </button>
    </div>
    <div v-if="action == 'ReverseShippingRequest'" class="modal-main">
      <h2 class="text-center">
        You are cancelling a shipping order. Are you sure?
      </h2>
      <button class="btn btn-primary" @click="onClickCancelShipping">
        Cancel shipping
      </button>
    </div>
    <div v-if="action == 'RequestReplacementFree'" class="modal-main">
      <h2 class="text-center">
        You are offer free replacement for this kit. Are you sure?
      </h2>
      <vSelect
        v-model="sampleStatus"
        v-validate="'required'"
        :options="[
          'FAILED_OVERLOADED',
          'FAILED_UNDERLOADED',
          'FAILED_SWAB_OTHER',
          'FAILED_PROCESSING'
        ]"
        label="label"
        name="state"
        placeholder="Sample Status (required)"
      />
      <button class="btn btn-primary" @click="onClickRequestReplacementFree">
        Yes
      </button>
    </div>
    <div v-if="action == 'RequestReplacementCharge'" class="modal-main">
      <h2 class="text-center">
        You are offering replacement($35) for this kit. Are you sure?
      </h2>
      <vSelect
        v-model="sampleStatus"
        v-validate="'required'"
        :options="[
          'FAILED_OVERLOADED',
          'FAILED_UNDERLOADED',
          'FAILED_SWAB_OTHER'
        ]"
        label="label"
        name="state"
        placeholder="Sample Status (required)"
      />
      <button class="btn btn-primary" @click="onClickRequestReplacementCharge">
        Yes
      </button>
    </div>
    <div v-if="action == 'RequestRefund'" class="modal-main">
      <h2 class="text-center">You are requesting a refund</h2>
      <refundKit :kit="kit" />
    </div>
    <div v-if="action == 'SetResultsAvailableCharge'" class="modal-main">
      <h2 class="text-center">Assign a sample and set results available?</h2>
      <button class="btn btn-primary" @click="onClickResultsAvailableCharge">
        Yes
      </button>
    </div>
    <div v-if="action == 'SetStatus'" class="modal-main">
      <h2 class="text-center">Set the status of this kit?</h2>
      <select
        class="mb-4 w-100 form-control"
        id="select-status"
        v-model="setkitStatus"
      >
        <option
          v-for="item in getKitStatuses"
          :key="`kit-statuses-${item}`"
          :value="item"
        >
          {{ item }}
        </option>
      </select>
      <button class="btn btn-primary" @click="onClickSetStatus">Set</button>
    </div>
    <div v-if="action == 'SendAKit'" class="modal-main">
      <h2 class="text-center">Send Kit: {{ kit.id }}</h2>
      <label for="send-kit-email">Customer Email</label>
      <div class="inline-zero form-inline">
        <input
          id="sendKitInput"
          ref="sendKitInput"
          v-model="sendKitInput"
          v-validate="'required|email'"
          type="text"
          name="Kit Email"
          class="form-control"
          placeholder="example@gmail.com"
          :class="{ 'is-invalid': errors.first('Kit Email') }"
          @keyup.enter="validate"
        />
        <button class="btn btn-primary" @click="validate">Send</button>
      </div>
      <div v-if="errors" class="invalid-feedback">
        {{ errors.first('Kit Email') }}
      </div>
    </div>
  </Modal>
</template>

<script>
import vSelect from 'vue-select'
import Modal from '@/components/models/Modal'
import refundKit from '@/components/elements/refundKit'
import { kitStatuses, kitStatus } from '@/enums/kitItemStatus'

export default {
  name: 'ModalAdminActions',
  components: {
    Modal,
    vSelect,
    refundKit
  },
  extends: Modal,
  props: {
    kit: {
      type: Object,
      required: true
    },
    hideRequestReplacementFree: {
      type: Boolean
    }
  },
  data: () => {
    return {
      action: null,
      sampleStatus: '',
      sampleIdentifier: '',
      setkitStatus: null,
      sendKitInput: ''
    }
  },
  computed: {
    getKitStatuses() {
      return kitStatuses
    },
    getBaseStatuses() {
      return kitStatus
    },
    isLocal() {
      let parts = window.location.host

      if (
        parts.includes('localhost') ||
        parts.includes('uat') ||
        parts.includes('demo')
      ) {
        return true
      }
      return false
    }
  },
  methods: {
    onShow() {
      this.action = null
    },
    onClickMarkPriority() {
      this.$emit('onClickMarkPriority')
      this.action = null
      this.close()
    },
    onClickCancelShipping() {
      this.$emit('onClickCancelShipping')
      this.action = null
      this.close()
    },
    onClickRequestReplacementFree() {
      this.$emit('onClickRequestReplacementFree', this.sampleStatus)
      this.action = null
      this.close()
    },
    onClickRequestReplacementCharge() {
      this.$emit('onClickRequestReplacementCharge', this.sampleStatus)
      this.action = null
      this.close()
    },
    onClickResultsAvailableCharge() {
      this.$emit('onClickResultsAvailableCharge')
      this.action = null
      this.close()
    },
    onClickSetStatus() {
      if (!this.setkitStatus) {
        return
      }

      let type = 'putSample'

      alert(this.setkitStatus)
      alert(this.getBaseStatuses[this.setkitStatus])
      if (this.getBaseStatuses[this.setkitStatus]) {
        type = 'putKit'
      }
      this.$emit('onClickSetStatus', this.setkitStatus, type)
      this.action = null
      this.close()
    },
    onClickSendKit() {
      this.$emit('onClickSendKit', this.sendKitInput)
      this.action = null
      this.close()
    },
    validate() {
      this.$validator
        .validate()
        .then(() => {
          if (!this.errors.any()) {
            this.onClickSendKit()
          }
        })
        .catch((error) => {
          throw new Error(error)
        })
    }
  }
}
</script>

<style>
.modal-container {
  max-width: calc(100vw - 40px) !important;
}
.button-container {
  gap: 0.25rem;
}
#select-status {
  height: 32px;
  padding: 4px 16px;
}
</style>
