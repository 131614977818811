<template>
  <transition name="slide-fade">
    <div class="box box-shadow">
      <form @submit.prevent="submit" class="stack">
        <div>
          <label for="patientFname">Patient First Name</label>
          <input
            id="patientFname"
            ref="patientFname"
            v-model="patientFname"
            type="text"
            name="Patient First Name"
            class="form-control"
            @keyup.enter="submit"
          />
        </div>
        <div>
          <label for="patientLname">Patient Last Name</label>
          <input
            id="patientLname"
            ref="patientLname"
            v-model="patientLname"
            type="text"
            name="Patient Last Name"
            class="form-control"
            @keyup.enter="submit"
          />
        </div>
        <div>
          <label for="keyword">Keyword (eg. phone, or email)</label>
          <input
            id="keyword"
            ref="keyword"
            v-model="keyword"
            type="text"
            name="Keyword Search"
            class="form-control"
            @keyup.enter="submit"
          />
        </div>
        <div>
          <button type="submit" class="btn btn-primary">Search</button>
        </div>
      </form>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ReferralSearchUi',
  components: {},
  props: {},
  data() {
    return {
      patientFname: null,
      patientLname: null,
      keyword: null
    }
  },
  mounted() {
    // Load search query from URL
    if (this.$route.query) {
      this.patientFname = this.$route.query.patientFname
      this.patientLname = this.$route.query.patientLname
      this.keyword = this.$route.query.keyword
      this.$emit('search-clicked', this.$route.query)
    }
  },
  methods: {
    submit() {
      console.log('sub')
      const payload = {
        patientFname: this.patientFname,
        patientLname: this.patientLname,
        keyword: this.keyword
      }
      this.$emit('search-clicked', payload)
    }
  }
}
</script>
