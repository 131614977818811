<template>
  <section class="search-user">
    <vue-simple-suggest
      ref="searchUser"
      v-model="text"
      placeholder="Search customers"
      display-attribute="firstName"
      :list="suggestionList"
      :debounce="1500"
      :max-suggestions="10"
      :filter-by-query="false"
      @input="fetchSuggestions"
      @suggestion-click="selectSearch"
    >
      <b-list-group slot="suggestion-item" slot-scope="{ suggestion }">
        <b-list-group-item>
          <i class="fa fa-user" aria-hidden="true" />
          {{ suggestion.firstName }} {{ suggestion.lastName }}<br />
          <i class="fa fa-envelope" aria-hidden="true" /> {{ suggestion.email
          }}<br />
          <i class="fa fa-phone" aria-hidden="true" />
          {{ suggestion.phoneNumber }}
          <hr />
        </b-list-group-item>
      </b-list-group>
    </vue-simple-suggest>
  </section>
</template>

<script lang="js">
import _ from 'lodash'
import VueSimpleSuggest from 'vue-simple-suggest/dist/cjs'

export default {
  name: 'SearchUser',
  components: {
    VueSimpleSuggest
  },
  props: {},
  data () {
    return {
      text: null,
      suggestionList: []
    }
  },
  mounted () {},
  methods: {
    fetchSuggestions: _.debounce(function (payload) {
      if (!payload || payload.length <= 2) {
        this.suggestionList = []
        return
      }
      this.$store.dispatch('search', encodeURIComponent(payload))
        .then((response) => {
          this.suggestionList = response.data
        })
        .catch(error => {
          throw new Error(error)
        })
    }, 1000),
    selectSearch (payload) {
      this.$emit('customer-selected', payload)
    }
  }
}
</script>
<style>
.list-group-item {
  padding: 5px;
  min-height: auto;
}
.vue-simple-suggest.designed .suggestions .suggest-item {
  padding: 0px !important;
}

.vue-simple-suggest.designed .suggestions .suggest-item.hover,
.vue-simple-suggest.designed .suggestions .suggest-item.selected {
  color: #000 !important;
  background-color: #eee;
}

.vue-simple-suggest.designed
  .suggestions
  .suggest-item.hover
  .list-group
  .list-group-item {
  background-color: #eee;
}

.vue-simple-suggest > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.vue-simple-suggest.designed {
  position: relative;
}

.vue-simple-suggest.designed,
.vue-simple-suggest.designed * {
  box-sizing: border-box;
}

.vue-simple-suggest.designed .input-wrapper input {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #cde;
  border-radius: 3px;
  color: black;
  background: white;
  outline: none;
  transition: all 0.1s;
  transition-delay: 0.05s;
}

.vue-simple-suggest.designed.focus .input-wrapper input {
  border: 1px solid #aaa;
}

.vue-simple-suggest.designed .suggestions {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  top: calc(100% + 5px);
  border-radius: 3px;
  border: 1px solid #aaa;
  background-color: #fff;
  opacity: 1;
  z-index: 1000;
}

.vue-simple-suggest.designed .suggestions .suggest-item {
  cursor: pointer;
  user-select: none;
}

.vue-simple-suggest.designed .suggestions .suggest-item.selected {
  background-color: #2832d5;
  color: #fff;
}
</style>
<style lang="scss">
.vue-simple-suggest {
  .suggestions {
    .suggest-item {
      .list-group-item {
        margin-bottom: 0;
        padding: 5px 5px 0px 5px;
        hr {
          margin: 0px;
        }
      }
    }
  }
}
</style>
