<template>
  <Modal :show="show" @close="close">
    <div class="modal-main">
      <div class="modal-header">
        <h2 class="modal-title">Order Replacement Kit</h2>
      </div>
      <div class="modal-body">
        <b-form-group disabled label="Sample ID:" label-for="sampleIdDisabled">
          <b-form-input
            id="sampleIdDisabled"
            :value="sampleIdentifier"
            disabled
          />
        </b-form-group>
        <div class="d-flex">
          <b-form-group
            label="First Name"
            label-for="firstName"
            class="col-6 pl-0"
          >
            <b-form-input
              id="firstName"
              v-model="shippingContact.firstName"
              v-validate="'required'"
              name="firstName"
              :class="{ 'is-invalid': errors.has('firstName') }"
            />
            <div
              v-if="errors.has('firstName')"
              class="invalid-feedback"
              :class="{ 'd-block': errors.has('firstName') }"
            >
              First name is required
            </div>
          </b-form-group>
          <b-form-group
            label="Last Name"
            label-for="lastName"
            class="col-6 pr-0"
          >
            <b-form-input
              id="lastName"
              v-model="shippingContact.lastName"
              v-validate="'required'"
              name="lastName"
              :class="{ 'is-invalid': errors.has('lastName') }"
            />
            <div
              v-if="errors.has('lastName')"
              class="invalid-feedback"
              :class="{ 'd-block': errors.has('lastName') }"
            >
              Last name is required
            </div>
          </b-form-group>
        </div>
        <b-form-group
          :label="'Email' + canEditEmailLabelText"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="shippingContact.emailAddress"
            v-validate="'email'"
            name="email"
            :class="{ 'is-invalid': errors.has('email') }"
            type="email"
            :disabled="!canEditEmail"
          />
          <div
            v-if="errors.has('email')"
            class="invalid-feedback"
            :class="{ 'd-block': errors.has('email') }"
          >
            Please enter a valid email address
          </div>
        </b-form-group>
        <b-form-group
          :label="'Phone Number' + canEditEmailLabelText"
          label-for="phoneNumber"
        >
          <vue-tel-input
            v-model="shippingContact.phoneNumber"
            v-validate="`isValidPhoneNumber:${isPhoneValid}`"
            class="input-phone"
            :class="{ 'is-invalid': errors.has('phoneNumber') }"
            name="phoneNumber"
            v-bind="phoneProps"
            placeholder="Phone Number"
            size="sm"
            @validate="checkPhoneNumberValidity"
            :disabled="!canEditEmail"
          />
          <div class="invalid-feedback">
            {{ errors.first('phoneNumber') }}
          </div>
        </b-form-group>
        <div class="box box-shadow px-0 mb-2">
          <h3>Shipping Address</h3>

          <div class="row">
            <div class="form-group col-12 col-md-12">
              <label for="address-one">Address line 1</label>
              <b-form-input
                v-model="shippingAddress.addressLine1"
                type="text"
                autocomplete="address-line1"
                id="address-one"
                name="address1"
                class="fs-hide"
              />
            </div>

            <div class="form-group col-12 col-md-12">
              <label for="address-two">Address line 2</label>
              <b-form-input
                v-model="shippingAddress.addressLine2"
                type="text"
                class="fs-hide"
                id="address-two"
                autocomplete="address-line2"
              />
            </div>

            <div class="form-group col-12 col-md-4">
              <label for="suburb">Suburb</label>
              <b-form-input
                v-model="shippingAddress.suburb"
                type="text"
                class="fs-hide"
                id="suburb"
                name="suburb"
                autocomplete="locality"
              />
            </div>

            <div class="form-group col-12 col-md-4">
              <label for="state">State</label>
              <select
                v-model="shippingAddress.state"
                id="state"
                name="state"
                class="form-control"
                :class="{ 'is-invalid': errors.has('state') }"
              >
                <option value="ACT">ACT</option>
                <option value="NSW">NSW</option>
                <option value="NT">NT</option>
                <option value="QLD">QLD</option>
                <option value="SA">SA</option>
                <option value="TAS">TAS</option>
                <option value="VIC">VIC</option>
                <option value="WA">WA</option>
              </select>
            </div>

            <div class="form-group col col-md-4">
              <label for="postcode">Post code</label>
              <b-form-input
                v-model="shippingAddress.postcode"
                type="text"
                id="postcode"
                name="postcode"
                autocomplete="shipping postal-code"
              />
            </div>

            <div class="form-group col-12 col-md-12">
              <label for="replacementReason">Replacement Reason</label>
              <b-form-input
                v-model="kit.notes"
                type="text"
                class="fs-hide"
                id="replacementReason"
                autocomplete="replacementReason"
              />
            </div>
          </div>
        </div>
        <Button
          class="full-width"
          text="Order Replacement Kit"
          :saving="loading"
          :success="buttonSuccess"
          @onClick="onClickSave"
        />
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/components/models/Modal'
import Button from '@/components/elements/DynamicButton'
import { Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import { HTTP } from '@/http.js'
import authService from '@/services/authService'

export default {
  name: 'ModalReplaceSample',
  components: { Modal, Button },
  extends: Modal,
  props: {
    sampleIdentifier: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      buttonSuccess: false,
      isPhoneValid: null,
      error: null,
      isSubmit: false,
      shippingContact: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: ''
      },
      shippingAddress: {
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        state: '',
        postcode: ''
      },
      phoneProps: {
        defaultCountry: 'AU',
        disabledFetchingCountry: false,
        placeholder: 'Enter phone number',
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ['AU'],
        onlyCountries: ['AU', 'NZ'],
        ignoredCountries: [],
        autocomplete: 'tel',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: 'form-control',
        inputClasses: 'phoneField'
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.REPLACE_SAMPLE_PENDING
    },
    success: function () {
      return this.$store.state.REPLACE_SAMPLE_SUCCESS
    },
    canEditEmail: function () {
      const roles = authService.getRoles()
      return roles.includes('ROLE_ADMIN')
    },
    canEditEmailLabelText: function () {
      return !this.canEditEmail ? ' (requires admin access to update) ' : ''
    }
  },
  methods: {
    fetchPatientInfo: async function () {
      this.isSubmit = false
      this.kit = {
        notes: ''
      }
      this.shippingContact = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: ''
      }
      this.shippingAddress = {
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        state: '',
        postcode: ''
      }
      try {
        const sample = await this.$store.dispatch('getSampleByIdentifier', {
          sampleIdentifier: this.sampleIdentifier
        })

        if (sample.data.kitId) {
          const kit = await HTTP.get(
            `${process.env.VUE_APP_API_ROOT}/kit/${sample.data.kitId}`
          )
          if (kit.data.shippingOrder) {
            this.shippingContact.firstName = kit.data.shippingOrder.firstName
            this.shippingContact.lastName = kit.data.shippingOrder.lastName
            this.shippingAddress.addressLine1 =
              kit.data.shippingOrder.addressLine1
            this.shippingAddress.addressLine2 =
              kit.data.shippingOrder.addressLine2
            this.shippingAddress.country = kit.data.shippingOrder.country
            this.shippingAddress.postcode = kit.data.shippingOrder.postcode
            this.shippingAddress.suburb = kit.data.shippingOrder.suburb
            this.shippingAddress.state = kit.data.shippingOrder.state
          }
          if (this.canEditEmail && kit.data.patientReference) {
            const patientInfo = await this.$store.dispatch(
              'getPatientById',
              kit.data.patientReference
            )
            this.shippingContact.emailAddress = patientInfo.data.emailAddress
            this.shippingContact.phoneNumber = patientInfo.data.phoneNumber
          }
        }
      } catch (error) {
        Notification({
          text: error.response?.data?.message,
          style: NOTIFICATION_STYLES.ERROR
        })
        console.log(error)
      }
    },
    onClickSave: function () {
      this.localError = null
      this.parentError = null
      this.isSubmit = true
      this.$validator.validate().then(() => {
        if (this.errors.any()) {
          this.localError = 'Please fill out or correct the highlighted fields'
          scroll(0, 0)
          return
        }
        this.doSave()
      })
    },
    doSave: async function () {
      const filteredShippingAddress = this.checkIsFieldFilled(
        this.shippingAddress
      )
      let data = {
        kit: this.kit,
        shippingContact: this.checkIsFieldFilled(this.shippingContact)
      }
      if (Object.keys(filteredShippingAddress).length) {
        data = { ...data, shippingAddress: filteredShippingAddress }
      }
      await this.$store
        .dispatch('replaceSample', {
          sampleIdentifier: this.sampleIdentifier,
          data
        })
        .then(() => {
          this.$emit('close')
          Notification({
            text: `${this.shippingContact.firstName} ${this.shippingContact.lastName} has been sent a replacement kit.`,
            style: NOTIFICATION_STYLES.SUCCESS
          })
        })
        .catch((error) => {
          Notification({
            text: error.response?.data?.message,
            style: NOTIFICATION_STYLES.ERROR
          })
          console.log(error)
        })
    },
    checkPhoneNumberValidity(payload) {
      this.isPhoneValid = payload?.isValid
      this.shippingContact.phoneNumber = this.isPhoneValid
        ? payload?.number.e164
        : payload?.number.input
    },
    checkIsFieldFilled(data) {
      let filteredObj = {}
      const obj = Object.entries(data).forEach(([key, val]) => {
        if (val) {
          filteredObj[key] = val
        }
      })
      return filteredObj
    }
  },
  watch: {
    show() {
      if (this.show) {
        this.fetchPatientInfo()
      }
    }
  }
}
</script>
<style scoped>
.input-phone.disabled {
  background: #eee;
}
</style>
