import Vue from 'vue'

import Router from 'vue-router'

import ActivationCode from '@/page/ActivationCode'
import Customers from '@/page/Customers'
import ReferralTools from '@/page/ReferralTools'
import Dashboard from '@/page/Dashboard'
import DispatchStation from '@/page/DispatchStation'
import DueSubscriptions from '@/page/DueSubscriptions'
import FreeOrInvoicedKits from '@/page/FreeOrInvoicedKits'
import HCPProfessional from '@/page/HCPProfessional'
import Login from '@/page/Login'
import MIPProfessional from '@/page/MIPProfessional'
import OrbitMAssembly from '@/page/orbitMAssembly'
// Templates
import Page from '@/page/Page'
import Profile from '@/page/Profile'
import PromoCode from '@/page/PromoCode'
import ResetPassword from '@/page/ResetPassword'
import resultsRelease from '@/page/ResultsRelease'
import resultsProcess from '@/page/ResultsProcess'
import StaffList from '@/page/StaffList'
import Unauthorised from '@/page/Unauthorised'
import authService, { responseErrorHandler } from '@/services/authService'
import { USER_TYPE, AuthService, TokenService } from '@microbadevs/library'

Vue.use(Router)

let rolePermissions = {}
const ifNotAuthenticated = (to, from, next) => {
  const isLoggedIn = authService?.isLoggedIn() || false
  if (!isLoggedIn) {
    next()
    return
  }
  next('/dashboard')
}

const authServiceWithTokens = () => {
  const { accessToken, refreshToken } = TokenService.getTokenDetails()
  return new AuthService({ accessToken, refreshToken, responseErrorHandler })
}

const ifAuthenticated = async (to, from, next) => {
  const isLoggedIn = authService?.isLoggedIn() || false
  if (!isLoggedIn) {
    next('/login')
    return
  }
  const is2FAEnabled = await authServiceWithTokens().is2FAEnabled(
    USER_TYPE.USER
  )
  if (
    process.env.VUE_APP_IS_MFA_ENABLED === 'true' &&
    to.path !== '/2fa-setup' &&
    !is2FAEnabled
  ) {
    next('/2fa-setup')
    return
  }

  import('../config/rolePermissions.json').then((data) => {
    rolePermissions = data

    // Ensure we have roles and permissions
    const userRoles = authService.getRoles() || []
    if (!rolePermissions || !userRoles) {
      throw new Error(
        `Cannot find the permissions associated with roles and/or the roles for this user`
      )
    }
    if (userRoles.length === 0) {
      next('/unauthorised')
      return
    }

    // Check if we're allowed, first on actual path
    let allowedRoles = rolePermissions[to.path]
    if (!allowedRoles) {
      // otherwise check on path name
      allowedRoles = rolePermissions[to.name]
    }
    let allowed = false
    for (const role of userRoles) {
      if (
        allowedRoles === '*' ||
        role === allowedRoles ||
        (Array.isArray(allowedRoles) && allowedRoles.indexOf('*') >= 0) ||
        (Array.isArray(allowedRoles) && allowedRoles.indexOf(role) >= 0)
      ) {
        allowed = true
        break
      }
    }

    if (allowed) {
      localStorage.setItem('last_visited', to.fullPath)
      next()
    } else {
      next('/unauthorised')
    }
  })
}

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: Page,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          meta: { title: 'Dashboard' },
          component: Dashboard,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/2FA-Setup',
          name: 'two-factor-authentication-set-up',
          meta: { title: 'Two Factor Authentication' },
          component: () =>
            import(
              /* webpackChunkName: "2fa" */ '@/components/2fa/TwoFactorAuthenticationSetUp'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/profile',
          name: 'Profile',
          meta: { title: 'Profile | Users' },
          component: Profile,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/customers',
          name: 'Customers',
          meta: { title: 'Customers | Users' },
          component: Customers,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/referrals',
          name: 'Referrals',
          component: ReferralTools,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/research/:reset?/:type?/:term?',
          name: 'research',
          meta: { title: 'Research | Users' },
          component: () =>
            import(
              /* webpackChunkName: "research" */ '@/components/research/researchUI'
            ),
          props: (route) => {
            // JSON.parse("true") = true JSON.parse("false") = false
            // route.params.reset === undefined instead of !route.params.reset
            // because when route.params.reset = false it would not work
            const reset =
              route.params.reset === undefined
                ? true
                : JSON.parse(route.params.reset)
            return { ...route.params, reset }
          },
          beforeEnter: ifAuthenticated
        },
        {
          path: '/project/:organisationId/:projectId?',
          name: 'research-project',
          meta: { title: 'Research Project | Users' },
          component: () =>
            import(
              /* webpackChunkName: "research" */ '@/components/research/project'
            ),
          props: (route) => {
            const organisationId = Number.parseInt(
              route.params.organisationId,
              10
            )
            const projectId = Number.parseInt(route.params.projectId, 10)

            if (Number.isNaN(organisationId)) {
              return null
            }
            return { organisationId, projectId }
          },
          beforeEnter: ifAuthenticated
        },
        {
          path: '/add-research-organisation',
          name: 'add-research-organisation',
          meta: { title: 'Add Research Organisation | Users' },
          component: () =>
            import(
              /* webpackChunkName: "research" */ '@/components/research/organisations'
            ),
          props: { propsShowAddOrganisation: true },
          beforeEnter: ifAuthenticated
        },
        {
          path: '/research-organisations/:organisationId?',
          name: 'research-organisations',
          meta: { title: 'Research Organisations | Users' },
          component: () =>
            import(
              /* webpackChunkName: "research" */ '@/components/research/organisations'
            ),
          props: (route) => {
            const organisationId = Number.parseInt(
              route.params.organisationId,
              10
            )
            return { organisationId }
          },
          beforeEnter: ifAuthenticated
        },
        {
          path: '/mip-professional',
          name: 'MIP professional',
          meta: { title: 'MIP Professional | Users' },
          component: MIPProfessional,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/hcp-professional',
          name: 'HCP professional',
          meta: { title: 'HCP Professional | Users' },
          component: HCPProfessional,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/receipt/:receiptId?',
          name: 'receipt',
          meta: { title: 'Receipting | Lab' },
          component: () =>
            import(/* webpackChunkName: "lab" */ '@/page/Receipt'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/receipt/prep',
          name: 'receiptPrep',
          meta: { title: 'Receipt Prep | Lab' },
          component: () =>
            import(/* webpackChunkName: "lab" */ '@/page/ReceiptPrep'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/runs',
          name: 'listRuns',
          meta: { title: 'Runs | Lab' },
          component: () =>
            import(
              /* webpackChunkName: "lab" */ '@/components/Lab/RunsListing'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/plates',
          name: 'listPlates',
          meta: { title: 'Plates | Lab' },
          component: () =>
            import(
              /* webpackChunkName: "lab" */ '@/components/Lab/PlatesListing'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/aliquot/:sampleIdentifier?',
          name: 'aliquot',
          meta: { title: 'Aliquot | Lab' },
          props: true,
          component: () =>
            import(/* webpackChunkName: "lab" */ '@/page/aliquot'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/lims-upload',
          name: 'limsUpload',
          meta: { title: 'LIMS Upload | Lab' },
          props: true,
          component: () =>
            import(/* webpackChunkName: "lab" */ '@/page/LimsUpload.vue'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/run/:runId?',
          name: 'createEditRun',
          meta: { title: 'Edit Run | Lab' },
          props: (route) => {
            const runId = Number.parseInt(route.params.runId, 10)

            if (Number.isNaN(runId)) {
              return null
            }
            return { runId }
          },
          component: () =>
            import(
              /* webpackChunkName: "lab" */ '@/components/Lab/createEditRun'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/plate/:plateId/:experimentId?',
          name: 'editPlate',
          meta: { title: 'Edit Plate | Lab' },
          props: (route) => {
            const plateId = Number.parseInt(route.params.plateId, 10)
            const experimentId = Number.parseInt(route.params.experimentId, 10)
            if (Number.isNaN(plateId)) {
              return null
            }
            return { plateId, experimentId }
          },
          component: () =>
            import(/* webpackChunkName: "lab" */ '@/components/Lab/editPlate'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/free-kit',
          name: 'FreeKit',
          meta: { title: 'Free Kit | Marketing' },
          props: {
            renderFreeKit: true,
            header: 'Free Kit',
            title: 'Free Kit Details',
            description:
              'Please ensure that appropriate permission from the company has been received to issue a free kit. This is for specific circumstances and special exemptions.'
          },
          component: FreeOrInvoicedKits,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/invoiced-kits',
          name: 'InvoicedKits',
          meta: { title: 'Invoiced Kits | Finance' },
          props: {
            renderInvoicedKit: true,
            header: 'Invoiced Kits',
            title: 'Invoiced Kits Details',
            description:
              'This form will order the kits to the person specified. Please make sure the invoice is generated in Xero beforehand.'
          },
          component: FreeOrInvoicedKits,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/dispatch-station',
          name: 'Dispatch',
          meta: { title: 'Dispatch | Logistics' },
          component: DispatchStation,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/orbit-m',
          name: 'OrbitMAssembly',
          meta: { title: 'Orbit M | Logistics' },
          component: OrbitMAssembly,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/promo-code',
          name: 'PromoCode',
          meta: { title: 'Promo Codes | Marketing' },
          component: PromoCode,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/inventory',
          name: 'Inventory',
          meta: { title: 'Stock | Inventory' },
          component: () =>
            import(/* webpackChunkName: "inventory" */ '@/page/Inventory'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/tube-supplier-batch',
          name: 'Tube Supplier Batch',
          meta: { title: 'Batch | Inventory' },
          component: () =>
            import(
              /* webpackChunkName: "inventory" */ '@/page/TubeSupplierBatch'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/batch-label',
          name: 'Batch Label',
          meta: { title: 'Batch Label | Inventory' },
          component: () =>
            import(/* webpackChunkName: "inventory" */ '@/page/BatchLabel'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/kits',
          name: 'Kit Inventory',
          meta: { title: 'Kit Inventory | Inventory' },
          component: () =>
            import(/* webpackChunkName: "inventory" */ '@/page/ListKits'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/create-kit',
          name: 'Create Kit',
          meta: { title: 'Create Kit | Inventory' },
          component: () =>
            import(/* webpackChunkName: "inventory" */ '@/page/CreateKit'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/due-subscriptions',
          name: 'Due Subscriptions',
          meta: { title: 'Due Subscriptions | Users' },
          component: DueSubscriptions,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/staff-list',
          name: 'Staff List',
          meta: { title: 'Staff List | Users' },
          component: StaffList,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/activation-code',
          name: 'Activation Code',
          meta: { title: 'Activation Code | Support' },
          component: ActivationCode,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/sample-lookup/:sampleId?',
          props: true,
          name: 'sampleLookup',
          meta: { title: 'Sample Lookup | Support' },
          component: () =>
            import(/* webpackChunkName: "support" */ '@/page/SampleLookup'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/kit-lookup/:kitId?',
          props: true,
          name: 'kitLookup',
          meta: { title: 'Kit Lookup | Support' },
          component: () =>
            import(/* webpackChunkName: "support" */ '@/page/KitLookup'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/results-process',
          name: 'Results Process',
          meta: { title: 'Results Process | Support' },
          component: resultsProcess,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/results-release',
          name: 'Results Release',
          meta: { title: 'Results Release | Support' },
          component: resultsRelease,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/distributor-languages',
          name: 'Distributors',
          meta: { title: 'Languages | Distributors' },
          component: () =>
            import(
              /* webpackChunkName: "distributors-listings" */ '@/page/DistributorsListings'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/add-language',
          name: 'Add Language',
          meta: { title: 'Add Language | Distributors' },
          component: () =>
            import(/* webpackChunkName: "add-language" */ '@/page/addLanguage'),
          beforeEnter: ifAuthenticated
        },
        {
          path: '/edit-language/:slug/:mgdbVersion/:code/:snapshotLoaded?',
          name: 'Edit Language',
          meta: { title: 'Edit Language | Distributors' },
          component: () =>
            import(
              /* webpackChunkName: "edit-language" */ '@/page/EditLanguage'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/list-language-versions/:slug/:mgdbVersion/:code',
          name: 'List Language Versions',
          meta: { title: 'List Languages | Distributors' },
          component: () =>
            import(
              /* webpackChunkName: "language-versions" */ '@/page/ListLanguageVersions'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path:
            '/edit-configuration/:slug?/:mgdbVersion?/:language?/:snapshotLoaded?',
          name: 'Edit Configuration',
          meta: { title: 'Edit Language Configuration | Distributors' },
          component: () =>
            import(
              /* webpackChunkName: "edit-configuration" */ '@/page/EditConfiguration'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/list-configuration-versions',
          name: 'List Configuration Versions',
          meta: { title: 'Configuration File Snapshots | Distributors' },
          component: () =>
            import(
              /* webpackChunkName: "configuration-versions" */ '@/page/ListConfigurationVersions'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/configuration-migrations',
          name: 'Configuration Migrations',
          meta: { title: 'Configuration Migrations | Distributors' },
          component: () =>
            import(
              /* webpackChunkName: "configuration-migrations" */ '@/page/configurationMigrations'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'add-language-key',
          name: 'Add Language Key',
          meta: { title: 'Add Language Key | Distributors' },
          component: () =>
            import(
              /* webpackChunkName: "configuration-migrations" */ '@/page/addLanguageKey'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'remove-language-key',
          name: 'Remove Language Key',
          meta: { title: 'Remove Language Key | Distributors' },
          component: () =>
            import(
              /* webpackChunkName: "configuration-migrations" */ '@/page/removeLanguageKey'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'admin',
          name: 'Admin Dashboard',
          meta: { title: 'Admin' },
          component: () =>
            import(
              /* webpackChunkName: "configuration-migrations" */ '@/page/adminDashboard'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'kitHashId',
          name: 'Kit Hash ID',
          meta: { title: 'Kit Hash ID' },
          component: () =>
            import(
              /* webpackChunkName: "configuration-migrations" */ '@/page/kitHashId'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'addDistributor',
          name: 'Add Distributor',
          meta: { title: 'Add Distributor | Distributors' },
          component: () =>
            import(
              /* webpackChunkName: "add-distributor" */ '@/page/addDistributor'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'bulkPdfRegeneration',
          name: 'Bulk PDF Regeneration',
          meta: { title: 'Bulk PDF Regeneration | Support' },
          component: () =>
            import(
              /* webpackChunkName: "configuration-migrations" */ '@/page/bulkPdfRegeneration'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'cobiome-result-release',
          name: 'Cobiome Result Release',
          meta: { title: 'Co-Biome Result Release | Support' },
          component: () =>
            import(
              /* webpackChunkName: "cobiome-report-release" */ '@/page/cobiomeResultRelease'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: 'cobiome-report-lookup',
          name: 'Cobiome Report Lookup',
          meta: { title: 'Co-Biome Report Lookup | Support' },
          component: () =>
            import(
              /* webpackChunkName: "cobiome-report-lookup" */ '@/page/cobiomeReportLookup'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: 'cobiome-report-preview/:reportId',
          name: 'Cobiome Report Preview',
          meta: { title: 'Co-Biome Report Preview | Support' },
          component: () =>
            import(
              /* webpackChunkName: "cobiome-report-preview" */ '@/page/CobiomeReportPreview'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'metapanel-orders/:query?',
          name: 'MetaPanel Orders',
          meta: { title: 'MetaPanel Orders | Diagnostics' },
          component: () =>
            import(
              /* webpackChunkName: "metapanel-orders" */ '@/page/metapanel/Orders'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: 'metapanel-order/:orderId',
          name: 'MetaPanel Order',
          meta: { title: 'MetaPanel Order | Diagnostics' },
          component: () =>
            import(
              /* webpackChunkName: "metapanel-orders" */ '@/page/metapanel/Order'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'metapanel-approvals/:query?',
          name: 'MetaPanel Approvals',
          meta: { title: 'MetaPanel Approvals | Diagnostics' },
          component: () =>
            import(
              /* webpackChunkName: "metapanel-orders" */ '@/page/metapanel/Approvals'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: 'metapanel-result-release/:query?',
          name: 'MetaPanel Result Release',
          meta: { title: 'MetaPanel Result Release | Diagnostics' },
          component: () =>
            import(
              /* webpackChunkName: "metapanel-orders" */ '@/page/metapanel/Release'
            ),
          beforeEnter: ifAuthenticated
        },
        {
          path: 'metapanel-preview/:reportId',
          name: 'MetaPanel Preview',
          meta: { title: 'MetaPanel Preview | Diagnostics' },
          component: () =>
            import(
              /* webpackChunkName: "metapanel-orders" */ '@/page/metapanel/Preview'
            ),
          props: true,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'metapanel-messages',
          name: 'MetaPanel Messages',
          meta: { title: 'MetaPanel Messages | Diagnostics' },
          component: () =>
            import(
              /* webpackChunkName: "metapanel-messages" */ '@/page/metapanel/Orders'
            ),
          beforeEnter: ifAuthenticated
        }
      ]
    },
    {
      path: '/TwoFactorAuthentication/:mfaToken',
      name: 'two-factor-authentication',
      meta: { title: 'Two Factor Authentication' },
      component: () =>
        import(
          /* webpackChunkName: "2fa" */ '@/components/2fa/TwoFactorAuthentication'
        ),
      props: (route) => {
        const mfaToken = route.params.mfaToken
        return { mfaToken }
      },
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/login',
      name: 'Login',
      meta: { title: 'Login' },
      component: Login,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/reset-password',
      name: 'Reset password',
      meta: { title: 'Reset Password' },
      component: ResetPassword,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: '/unauthorised',
      name: 'Unauthorised',
      meta: { title: 'Unauthorised' },
      component: Unauthorised
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  // Update the tab title on route change
  const titleArr = []
  if (to.params.reportId) {
    titleArr.push(to.params.reportId)
  }

  titleArr.push(to.meta?.title ?? to.name)
  titleArr.push('Microba Staff Portal')

  document.title = titleArr.join(' | ')
  next()
})

export default router
