import store from '@/stores'

export default class SampleService {
  static async getSamplesByStatus (status) {
    if (!status) throw new Error('Missing status')

    return store.dispatch('getSamples', { status })
  }

  static async getPlateSampleCandidates ({ page, pageSize, providedSampleIdentifier }) {
    const payload = {
      page,
      pageSize,
      sampleIdentifier: providedSampleIdentifier
    }

    const resp = await store.dispatch('getPlateSampleCandidates', payload)

    if (!resp || !resp.data) {
      return []
    }

    return {
      samples: resp?.data?.content || [],
      totalPages: resp?.data?.totalPages || 0
    }
  }

  static async getSampleByIdentifier (sampleIdentifier) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    const payload = {sampleIdentifier}
    return store.dispatch('getSampleByIdentifier', payload)
  }

  static async getSampleFile (sampleIdentifier, fileId) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    if (!fileId) throw new Error('Missing file ID')
    return store.dispatch('getSampleFile', { sampleIdentifier, fileId })
  }

  static async getSampleFileList (sampleIdentifier) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    return store.dispatch('getSampleFiles', sampleIdentifier)
  }

  static async postSampleFile (sampleIdentifier, formData) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    if (!formData) throw new Error('Missing form data')
    return store.dispatch('postSampleFile', { sampleIdentifier, formData })
  }

  static async deleteSampleFile (sampleIdentifier, fileId) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    if (!fileId) throw new Error('Missing file ID')
    return store.dispatch('deleteSampleFile', { sampleIdentifier, fileId })
  }

  static async getSampleAliquots (sampleIdentifier) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    return store.dispatch('getSampleAliquots', sampleIdentifier)
  }

  static async postGenerateSampleAliquots (sampleIdentifier, params) {
    if (!sampleIdentifier) throw new Error('Missing sample identifier')
    return store.dispatch('postGenerateSampleAliquots', { sampleIdentifier, params })
  }
}
