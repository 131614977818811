<template>
  <div class="shippingAddress">
    <addressComponent
      :existing-address="address"
      :address-errors="addressErrors"
      @update:existingAddress="updateExistingAddress"
    />
    <button
      class="btn btn-primary pull-right"
      :disabled="!isValidAddress"
      type="button"
      @click="saveAddress"
    >
      Save
    </button>
  </div>
</template>

<script>
import addressComponent from '@/components/address'
import Address from '@/Models/address'
export default {
  name: 'ShippingAddress',
  components: { addressComponent },
  props: {
    existingAddress: {
      required: true,
      type: Object,
      default: () => new Address({})
    }
  },
  data() {
    return { address: new Address(this.existingAddress), addressErrors: {} }
  },
  computed: {
    isValidAddress() {
      return Object.keys(this.addressErrors).length === 0
    }
  },
  methods: {
    updateExistingAddress(payload) {
      this.address = new Address(payload)
      this.addressErrors = {}

      if (!this.address.addressLine1) {
        this.addressErrors.addressLine1 = 'Address line1 is required. '
      }
      if (!this.address.suburb) {
        this.addressErrors.suburb = 'Suburb is required.'
      }
      if (!this.address.state) {
        this.addressErrors.state = 'State is required.'
      }
      if (!this.address.postcode) {
        this.addressErrors.postcode = 'Postcode is required.'
      }
    },
    saveAddress() {
      this.$emit('updateAddress', this.address)
    }
  }
}
</script>
