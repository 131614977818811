import QCReportService from '@/services/qcReportService'

const QCStatus = Object.freeze({
  SUCCESS: 'success',
  WARNING: 'warning',
  FAILURE: 'failure',
  DANGER: 'danger',
  MISSING_REPORT: 'missingReport'
})

const QCCause = Object.freeze({
  FILE_SIZE: 'fileSize',
  PAGE_NUMBER: 'pageNumber'
})

class Report {
  constructor (jsonObj = {}) {
    this.sample = jsonObj?.sample
    this.sampleIdentifier = jsonObj?.sample?.sampleIdentifier || null
    const report = jsonObj?.report || null
    if (report) {
      this.noOfPages = report.noOfPages || null
      this.noOfPagesThreshold = report.noOfPagesThreshold || null
      this.fileSizeInMb = fileSizeInMB(report.fileSize) || null
      this.fileSizeWarningThresholdInMB = fileSizeInMB(report.fileSizeWarningThreshold) || null
      this.fileSizeFailureThresholdInMB = fileSizeInMB(report.fileSizeFailingThreshold) || null
      this.pages = report.pages || []
      this.imageSource = report.imageSource
      this.warningReasons = getWarningReasons(report) || []
      this.failureReasons = getFailureReasons(report) || []
      this.reportStatus = getReportStatus(this.warningReasons, this.failureReasons) || null
    }
  }

  static async getReportBySampleBatch (sampleIdentifiersArray) {
    let { data: sampleReports } = await QCReportService.getReportBySampleBatch(sampleIdentifiersArray)
    sampleReports = sampleReports?.map(reportCollection => {
      if (!Array.isArray(reportCollection)) {
        reportCollection = [reportCollection]
      }
      return reportCollection.map((report) => new Report(report))
    })
    return sampleReports
  }

  static async getComparisonImage (params) {
    let { data: imageBase64 } = await QCReportService.getComparisonImage(params)
    return imageBase64
  }
}

function fileSizeInMB (fileSize) {
  if (!fileSize) throw new Error('No file size is given')
  return `${(Number(fileSize) / 1000000).toFixed(
    2
  )} MB`
}

function getWarningReasons (report) {
  let warnings = []
  const fileSizeWarnings = getFileSizeWarningReasons(report)
  if (fileSizeWarnings) {
    warnings.push(fileSizeWarnings)
  }

  const pagesWarnings = getPageWarningReasons(report)
  if (pagesWarnings.length) {
    warnings.push(...pagesWarnings)
  }
  return warnings
}

function getFailureReasons (report) {
  let failures = []
  const pageNumberFailure = getPageNumberFailureReasons(report)
  if (pageNumberFailure) {
    failures.push(pageNumberFailure)
  }

  const fileSizeFailure = getFileSizeFailureReasons(report)
  if (fileSizeFailure) {
    failures.push(fileSizeFailure)
  }

  const pagesFailure = getPagesFailureReasons(report)
  if (pagesFailure.length) {
    failures.push(...pagesFailure)
  }
  return failures
}

function getPageNumberFailureReasons (report) {
  const pageNumberStatus = getPageNumberStatus(report)
  const isPageNumberFailure = pageNumberStatus === QCStatus.FAILURE
  const failureMessage = {cause: QCCause.PAGE_NUMBER}
  const pageNumberFailureReason = isPageNumberFailure ? failureMessage : null
  return pageNumberFailureReason
}

function getFileSizeFailureReasons (report) {
  const fileSizeStatus = getFileSizeStatus(report)
  const isFileSizeFailure = fileSizeStatus === QCStatus.FAILURE
  const failureMessage = {cause: QCCause.FILE_SIZE}
  const fileSizeFailureReason = isFileSizeFailure ? failureMessage : null
  return fileSizeFailureReason
}

function getFileSizeWarningReasons (report) {
  const fileSizeStatus = getFileSizeStatus(report)
  const isFileSizeWarning = fileSizeStatus === QCStatus.WARNING
  const warningMessage = {cause: QCCause.FILE_SIZE}
  const fileSizeWarningReason = isFileSizeWarning ? warningMessage : null
  return fileSizeWarningReason
}

function getPagesFailureReasons (report) {
  const pageStatus = getPageStatus(report)
  const pageWithFailure = pageStatus.filter(
    page => page.status === QCStatus.FAILURE
  )
  const pageFailureMessage = pageWithFailure.map(page => {
    return {cause: page.pageNo}
  })
  return pageFailureMessage
}

function getPageWarningReasons (report) {
  const pageStatus = getPageStatus(report)
  const pageWithWarning = pageStatus.filter(
    page => page.status === QCStatus.WARNING
  )
  const pageWarningMessage = pageWithWarning.map(page => {
    return {cause: page.pageNo}
  })
  return pageWarningMessage
}

function getPageNumberStatus ({ noOfPages, noOfPagesThreshold }) {
  if (noOfPages < noOfPagesThreshold) return QCStatus.FAILURE
  return QCStatus.SUCCESS
}

function getFileSizeStatus ({
  fileSize,
  fileSizeFailingThreshold,
  fileSizeWarningThreshold
}) {
  if (fileSize <= fileSizeFailingThreshold) {
    return QCStatus.FAILURE
  } else if (
    fileSize <= fileSizeWarningThreshold &&
    fileSize > fileSizeFailingThreshold
  ) {
    return QCStatus.WARNING
  }
  return QCStatus.SUCCESS
}

function getPageStatus ({ pages }) {
  return pages.map(page => {
    if (page.rms < page.warningThreshold) {
      page.status = QCStatus.SUCCESS
    } else if (
      page.rms >= page.warningThreshold &&
      page.rms < page.failingThreshold
    ) {
      page.status = QCStatus.WARNING
    } else {
      page.status = QCStatus.FAILURE
    }
    return page
  })
}

function getReportStatus (warningReasons, failureReasons) {
  let reportStatus
  const doesReportHaveWarning = warningReasons?.length > 0
  const doesReportHaveFailure = failureReasons?.length > 0
  reportStatus = doesReportHaveWarning ? QCStatus.WARNING : QCStatus.SUCCESS
  reportStatus = doesReportHaveFailure ? QCStatus.FAILURE : reportStatus
  return reportStatus
}

export { Report, QCStatus, QCCause }
