<template>
  <Modal
    :show="show"
    @close="close"
  >
    <!-- Modal container -->
    <div class="modal-main">
      <!-- Header -->
      <div class="modal-header">
        <h2 class="modal-title">
          Invalidate sample?
        </h2>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <!-- Modal body form / sample identifier -->
        <b-form-group
          disabled
          label="Sample ID:"
          label-align="right"
          label-cols="2"
          label-for="sampleIdDisabled"
        >
          <b-form-input
            id="sampleIdDisabled"
            :value="sampleIdentifier"
            disabled
          />
        </b-form-group>
        <!-- Modal body form / sample status -->
        <b-form-group
          disabled
          label="Status:"
          label-align="right"
          label-cols="2"
          label-for="sampleStatusDisabled"
        >
          <b-form-input
            id="sampleStatusDisabled"
            :value="sampleStatus"
            disabled
          />
        </b-form-group>
        <!-- Modal body form / notes -->
        <b-form-group
          label="Notes:"
          label-align="right"
          label-cols="2"
          label-for="sampleNotes"
        >
          <b-form-textarea
            id="sampleNotes"
            v-model="notes"
            placeholder="Reason for invalidating sample... (optional)"
            rows="3"
          />
        </b-form-group>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <div class="row">
          <!-- Modal footer / left column -->
          <div class="col-6">
            <b-button
              variant="primary"
              @click="invalidateSample"
            >
              <div class="d-flex">
                Invalidate
              </div>
            </b-button>
          </div>
          <!-- Modal footer / right column -->
          <div class="col-6">
            <b-button
              variant="outline-primary"
              @click="$emit('close')"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/models/Modal'
import { sampleStatus } from '@/enums/kitItemStatus'
import { Notification, NOTIFICATION_STYLES } from '@microbadevs/library'

export default {
  name: 'ModalInvalidateSample',
  components: {
    Modal
  },
  extends: Modal,
  props: {
    sampleIdentifier: {
      type: String,
      required: true
    },
    sampleStatus: {
      type: String,
      required: true
    },
    sampleNotes: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      notes: ''
    }
  },
  methods: {
    async invalidateSample () {
      try {
        this.$emit('close')
        const notes = this.sampleNotes ? `${this.sampleNotes} ${this.notes}` : this.notes
        await this.$store.dispatch('putSampleByIdentifier', {
          sampleIdentifier: this.sampleIdentifier,
          sampleStatus: sampleStatus.INVALIDATED,
          notes
        })
        await Notification({
          title: 'Sample invalidated',
          message: 'Sample has been invalidated',
          style: NOTIFICATION_STYLES.SUCCESS
        })
        this.$emit('invalidation:success')
      } catch (error) {
        await Notification({
          title: 'Error',
          message: error.message || 'Unable to invalidate sample. Please try again.',
          style: NOTIFICATION_STYLES.ERROR
        })
        this.$emit('invalidation:failed')
      }
    }
  }
}
</script>

<style scoped></style>
