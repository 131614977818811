/**
 * Event Bus
 *
 * A global event bus plugin
 *
 * USAGE:
 *
 * this.$bus.$emit('doSomething')
 * this.$bus.$on('doSomething', function(){})
 * this.$bus.$off('doSomething')
 */
export default {
  install: (Vue) => {
    Vue.prototype.$bus = new Vue()
  }
}
