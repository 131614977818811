<template>
  <Modal
    :show="show"
    class-name="p-5 pb-5"
    @close="close()"
  >
    <div
      v-if="action == null"
      class="modal-main ml-4 mr-4 w-100"
    >
      <h2 class="text-center">
        View Application
      </h2>

      <div class="row form">
        <div class="col-12">
          <div class="form-block pl-5 pr-5">
            <div class="row">
              <div class="col">
                <h4>Practitioner</h4>
              </div>
            </div>
            <div class="row">
              <div class="form-group col col-md-6">
                <input
                  v-model="professional.firstName"
                  type="text"
                  class="form-control"
                  required
                  placeholder="First name"
                >
              </div>
              <div class="form-group col col-md-6">
                <input
                  v-model="professional.lastName"
                  type="text"
                  class="form-control"
                  required
                  placeholder="Last name"
                >
              </div>
            </div>
            <div class="row">
              <div class="form-group col col-md-12">
                <input
                  v-model="professional.practise"
                  type="text"
                  class="form-control"
                  required
                  placeholder="Practise"
                >
              </div>
            </div>
            <div class="row">
              <div class="form-group col col-md-6">
                <input
                  v-model="professional.email"
                  type="text"
                  class="form-control"
                  required
                  placeholder="Email"
                >
              </div>
              <div class="form-group col col-md-6">
                <input
                  v-model="professional.phone"
                  type="text"
                  class="form-control"
                  required
                  placeholder="Phone"
                >
              </div>
            </div>
          </div>
          <div class="form-block pl-5 pr-5">
            <div class="row">
              <div class="col">
                <h4>Registering body</h4>
              </div>
            </div>
            <div class="row">
              <div class="form-group col col-md-6">
                <input
                  v-model="professional.regbody.name"
                  type="text"
                  class="form-control"
                  required
                  placeholder="Name"
                >
              </div>
              <div class="form-group col col-md-6">
                <input
                  v-model="professional.regbody.number"
                  type="text"
                  class="form-control"
                  required
                  placeholder="Number"
                >
              </div>
            </div>
          </div>

          <div class="col-12 text-center mt-5">
            <a
              href="#"
              class="btn btn-success mr-3"
              @click.prevent="approveApplication"
            >
              <span v-if="loadingData">Loading</span>
              <span v-else>
                <span>Approve</span>
              </span>
            </a>
            <a
              href="#"
              class="btn btn-danger ml-3"
              @click.prevent="rejectApplication"
            >
              <span v-if="loadingData">Loading</span>
              <span v-else>
                <span>Reject</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/models/Modal'
export default {
  name: 'ModalCreateInformedProfessional',
  components: {
    Modal
  },
  extends: Modal,
  props: ['obj'],
  data: () => {
    return {
      loadingData: false,
      action: null,
      sampleStatus: '',
      professional: {
        email: '',
        firstName: '',
        lastName: '',
        userType: 'MICROBA_INFORMED_PROFESSIONAL',
        password: 'password'
      }
    }
  },
  methods: {
    onShow () {
      this.action = null
      if (this.obj) {
        this.professional = this.obj
      } else {
        this.professional = {
          email: '',
          firstName: '',
          lastName: '',
          userType: 'MICROBA_INFORMED_PROFESSIONAL',
          password: 'password'
        }
      }
    },
    approveApplication () {
      this.close()
    },
    rejectApplication () {
      this.close()
    },
    createProfessional () {
      let payload = this.professional
      let self = this
      this.loadingData = true

      // Randomise password
      if (!this.obj) {
        payload.password = Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(0, 5)
      }

      let action = 'postInformedProfessional'
      if (this.obj) {
        action = 'putInformedProfessional'
      }

      this.$store
        .dispatch(action, payload)
        .then((response) => {
          self.loadingData = false
          self.close()
        })
        .catch((error) => {
          self.loadingData = false
          self.close()
          console.log(error)
        })
    }
  }
}
</script>

<style></style>
