<template>
  <div>
    <b-card bg-variant="default">
      <slot name="header" />
      <p><span class="font-weight-bold">Used By: </span>{{ kit.ownedBy }}</p>
      <p>
        <span class="font-weight-bold">Activated Date: </span
        >{{ kit.activatedDate | formatDate('DD/MM/YYYY hh:mm a') }}
      </p>
      <p>
        <span class="font-weight-bold">Kit Status: </span
        >{{ getKitStatusLabel(kit.kitStatus) }}
      </p>
      <p>
        <span class="font-weight-bold">Voucher Code: </span
        >{{ kit.voucherCode }}
      </p>
      <p>
        <span class="font-weight-bold">Sample Identifier: </span
        >{{ kit.sampleIdentifier }}
      </p>
      <div class="d-flex">
        <p>
          <span class="font-weight-bold">Referral Code: </span
          >{{ kit.referralCode }}
        </p>
        <a href="#" @click.prevent="onClickEditReferralCode">
          <font-awesome-icon :icon="['fa', 'fa-pencil']" />
          {{ `${kit.referralCode ? 'Edit' : 'Add'}` }}
        </a>
      </div>
      <a
        v-if="kit.referralCode"
        href="#"
        class="ml-2"
        @click.prevent="removeReferralCode"
      >
        <font-awesome-icon :icon="['fa', 'fa-trash']" />
        {{ `Remove` }}
      </a>
      <div v-if="editingReferralCode" class="mb-4">
        <div>
          <b-form-input
            v-model="kit.referralCode"
            name="Referral Code"
            placeholder="Enter the referal code"
          />
        </div>
        <div>
          <div class="text-left error-text">
            {{ referralCodeInfo }}
          </div>
          <div class="text-right mt-2">
            <b-button
              variant="primary"
              class="w-25"
              @click.prevent="addOrEditReferralCode(kit.referralCode)"
            >
              Enter
            </b-button>
          </div>
        </div>
      </div>
      <p>
        <span class="font-weight-bold">Sample Status: </span
        >{{ kit.sampleStatus }}
      </p>
      <p>
        <span class="font-weight-bold">Shipped to: </span
        >{{ formattedShippingAddress(kit, 1) }}
      </p>
      <p />
      <template v-if="kit.shippingOrder">
        <span v-if="kit.shippingOrder" class="font-weight-bold invisible"
          >Shipped to: </span
        >{{ formattedShippingAddress(kit, 2) }}<br />
        <span v-if="kit.shippingOrder" class="font-weight-bold invisible"
          >Shipped to: </span
        >{{ formattedShippingAddress(kit, 3) }}<br />
        <span v-if="kit.shippingOrder" class="font-weight-bold invisible"
          >Shipped to: </span
        >{{ formattedShippingAddress(kit, 4) }}<br />
      </template>
      <span v-show="kit.priority" class="font-weight-bold text-danger"
        ><br />PRIORITY SAMPLE</span
      ><br />
      <slot name="footer" />
    </b-card>
  </div>
</template>
<script>
import dateMixin from '@/mixins/date'
import { kitStatusLabels } from '@/enums/kitItemStatus'

export default {
  name: 'KitCard',
  props: {
    kit: {
      type: Object,
      required: true
    }
  },
  mixins: [dateMixin],
  data: function () {
    return {
      editingReferralCode: false
    }
  },
  methods: {
    formattedShippingAddress: function (kit, lineNr = 2) {
      const details = kit.shippingOrder
      if (!details) {
        return ''
      }
      switch (lineNr) {
        case 1:
          return `${details.firstName || ''} ${details.lastName || ''}`
        case 2:
          return `${details.addressLine1 || ''} ${details.addressLine2 || ''}`
        case 3:
          return `${details.suburb || ''} ${
            details.postcode ? '- ' + details.postcode : ''
          }`
        case 4:
          return `${details.state || ''}`
      }
    },
    onClickEditReferralCode() {
      this.editingReferralCode = !this.editingReferralCode
      this.originalReferralCode = this.kit.referralCode
      this.referralCodeInfo = null
    },
    addOrEditReferralCode(referralCode) {
      const payload = {
        id: this.kit.id,
        referralCode
      }
      this.$store
        .dispatch('checkReferralCode', referralCode)
        .then(() => {
          this.$store.dispatch('putKit', payload).then(() => {
            this.referralCodeInfo = this.originalReferralCode
              ? 'Referral code has been successfully updated.'
              : 'Referral code has been successfully added.'
          })
        })
        .catch((error) => {
          this.referralCodeInfo = error.response.data.message
          this.kit.referralCode = this.originalReferralCode
        })
    },
    removeReferralCode() {
      const payload = {
        id: this.kit.id,
        referralCode: null
      }
      this.$store
        .dispatch('putKit', payload)
        .then((response) => {
          this.referralCodeInfo = 'Referral code has been successfully removed!'
          this.kit.referralCode = null
          this.originalReferralCode = null
        })
        .catch((error) => {
          this.referralCodeInfo = error.response.data.message
          this.kit.referralCode = this.originalReferralCode
        })
    },
    getKitStatusLabel: function (status) {
      return kitStatusLabels[status] ? kitStatusLabels[status] : 'Unknown'
    },
    getSampleOrKitStatusLabel: function (kitStatus, sampleStatus) {
      const status = sampleStatus || kitStatus
      return kitStatusLabels[status] ? kitStatusLabels[status] : 'Unknown'
    }
  }
}
</script>
