import moment from 'moment'

const DDMMYYYYSlashSeparated = (time) => {
  return !time ? 'N/A' : moment(time).format('DD/MM/YYYY')
}

const DDMMYYYYTimeSpaceSeparated = (time) => {
  return !time ? 'N/A' : moment(time).format('DD MMM YYYY h:mma')
}

const LocalizedFormat = (dateTime, parseFormat) => {
  if (!dateTime) return 'N/A'

  dateTime = parseFormat ? moment(dateTime, parseFormat) : moment(dateTime)
  return dateTime.format('ll')
}

export { DDMMYYYYSlashSeparated, DDMMYYYYTimeSpaceSeparated, LocalizedFormat }
