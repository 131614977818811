<script>
  import { BFormGroup, BFormInput } from 'bootstrap-vue'
  import { Address, addressService } from './lib/address.js'
  import VueSimpleSuggest from 'vue-simple-suggest'

  export default {
    name: 'AddressComponent',
    components: {
      'b-form-group': BFormGroup,
      'b-form-input': BFormInput,
      VueSimpleSuggest
    },
    props: {
      countriesEnabled: {
        type: Array,
        default: () => []
      },
      existingAddress: {
        type: Object,
        default: () => new Address({})
      },
      addressErrors: {
        type: Object,
        default: () => new Address({})
      },
      apiBaseUrl: {
        type: String,
        default: 'https://apiuat.microba.com'
      }
    },
    data () {
      return {
        fetchingSuggestions: false,
        address: new Address({}),
        selectedCountry: null,
        providerSessionId: null,
        provider: null,
        hideAutoSuggestionItems: false,
        addressReadOnly: false
      }
    },
    computed: {
      autoCompleteStyle () {
        const invalidStyle =
          this.addressErrors && this.addressErrors.addressLine1
            ? 'is-invalid'
            : ''
        return {
          vueSimpleSuggest: 'position-relative',
          inputWrapper: '',
          defaultInput: `form-control ${invalidStyle}`,
          suggestions: 'position-absolute list-group addressSuggestions',
          suggestItem: 'list-group-item'
        }
      },
      countries () {
        let countries = addressService.getCountries()
        countries = this.getfilteredCountries(countries)
        return countries
      },
      states () {
        if (!this.selectedCountry) return []
        return addressService.getStates(this.selectedCountry)
      },
      addressFieldsReadOnly () {
        return !this.selectedCountry || this.addressReadOnly
      },
      canEditAddress () {
        return this.selectedCountry && this.addressReadOnly
      }
    },
    watch: {
      selectedCountry: function (newCountry, oldCountry) {
        if (oldCountry !== null && newCountry !== oldCountry) {
          this.clearAddress()
          this.allowAddressEditing()
        }
      },
      address: {
        handler: function (newAddress, oldAddress) {
          if (
            JSON.stringify(newAddress) !== JSON.stringify(this.existingAddress)
          ) {
            newAddress.externalIdentifier = null
            newAddress.provider = null
            this.$emit('update:existingAddress', newAddress)
          }
        },
        deep: true
      },
      existingAddress: {
        handler: function () {
          if (
            JSON.stringify(this.address) !==
            JSON.stringify(this.existingAddress)
          ) {
            this.address = new Address(this.existingAddress)
          }
        },
        deep: true
      }
    },
    mounted () {
      this.bootstrapComponentData()
    },
    methods: {
      bootstrapComponentData () {
        this.selectedCountry =
          this.existingAddress && this.existingAddress.country
            ? this.countries.find(
              (country) => country.code === this.existingAddress.country
            )
            : null
        this.address = new Address(this.existingAddress)
        if (this.address.country) {
          this.addressReadOnly = true
        }
      },
      getfilteredCountries (countries) {
        if (this.countriesEnabled.length) {
          countries = countries.filter(
            (country) => this.countriesEnabled.indexOf(country.code) !== -1
          )
        }
        return countries
      },
      async getSuggestionList () {
        if (!this.selectedCountry) return []
        return addressService
          .getSuggestionList(
            this.apiBaseUrl,
            this.selectedCountry,
            this.address.addressLine1,
            this.providerSessionId
          )
          .then((response) => {
            this.providerSessionId = response.sessionId
            return response.suggestionList
          })
          .catch(() => [])
      },
      async getSelectedSuggestionInfo (payload) {
        this.hideAutoSuggestionItems = true
        try {
          const selectedSuggestionMetaInfo = await addressService.getSelectedSuggestionInfo(
            this.apiBaseUrl,
            this.selectedCountry,
            this.providerSessionId,
            payload.provider,
            payload.value
          )
          if (selectedSuggestionMetaInfo) {
            const address = new Address(selectedSuggestionMetaInfo)
            this.$emit('update:existingAddress', address)
            this.addressReadOnly = true
            this.hideAutoSuggestionItems = false
          }
        } catch (error) {
          return null
        }
      },
      allowAddressEditing () {
        this.addressReadOnly = false
        this.externalIdentifier = null
        this.provider = null
      },
      clearAddress () {
        this.address = new Address({})
      }
    }
  }
</script>
<template>
  <div class="address-component">
    <b-form-group
      id="fieldset-horizontal"
      label-cols-sm="2"
      label-cols-lg="2"
      description="Choose country"
      label="Country"
      label-for="countrySelector"
    >
      <select
        id="countrySelector"
        v-model="selectedCountry"
        label="name"
        class="form-control"
        :class="{ 'is-invalid': addressErrors.country }"
      >
        <option value="null">
          Select country*
        </option>
        <option
          v-for="country in countries"
          :key="`country-${country.code}`"
          :value="country"
        >
          {{ country.name }}
        </option>
      </select>
      <div class="invalid-feedback">
        {{ addressErrors.country }}
      </div>
    </b-form-group>
    <div
      v-if="canEditAddress"
      class="editAddressWrapper text-right"
    >
      <a @click="allowAddressEditing">
        Edit Address
      </a>
    </div>
    <b-form-group
      id="fieldset-horizontal"
      label-cols-sm="2"
      label-cols-lg="2"
      description="Enter address line1"
      label="Address line1"
      label-for="addressLine1Selector"
    >
      <vue-simple-suggest
        id="addressLine1Selector"
        v-model="address.addressLine1"
        :list="getSuggestionList"
        :remove-list="hideAutoSuggestionItems"
        :max-suggestions="10"
        :min-length="3"
        :debounce="200"
        display-attribute="name"
        value-attribute="value"
        :styles="autoCompleteStyle"
        :disabled="addressFieldsReadOnly"
        @suggestion-click="getSelectedSuggestionInfo"
      />
      <div
        v-if="addressErrors.addressLine1"
        class="invalid-feedback d-block"
      >
        {{ addressErrors.addressLine1 }}
      </div>
    </b-form-group>

    <b-form-group
      id="fieldset-horizontal"
      label-cols-sm="2"
      label-cols-lg="2"
      description="Enter suburb"
      label="Suburb"
      label-for="suburbSelector"
    >
      <b-form-input
        id="suburbSelector"
        v-model="address.suburb"
        :readonly="addressFieldsReadOnly"
        :class="{ 'is-invalid': addressErrors.suburb }"
      />
      <div class="invalid-feedback">
        {{ addressErrors.suburb }}
      </div>
    </b-form-group>

    <b-form-group
      id="fieldset-horizontal"
      label-cols-sm="2"
      label-cols-lg="2"
      description="Enter state"
      label="State"
      label-for="stateSelector"
    >
      <select
        id="stateSelector"
        v-model="address.state"
        label="name"
        :disabled="addressFieldsReadOnly"
        class="form-control"
        :class="{ 'is-invalid': addressErrors.state }"
      >
        <option
          value="null"
          hidden
        >
          Select state*
        </option>
        <option
          v-for="state in states"
          :key="`state-${state.code}`"
          :value="state.code"
        >
          {{ state.name }}
        </option>
      </select>

      <div class="invalid-feedback">
        {{ addressErrors.state }}
      </div>
    </b-form-group>

    <b-form-group
      id="fieldset-horizontal"
      label-cols-sm="2"
      label-cols-lg="2"
      description="Enter postcode"
      label="Postcode"
      label-for="postcodeSelector"
    >
      <b-form-input
        id="postcodeSelector"
        v-model="address.postcode"
        :readonly="addressFieldsReadOnly"
        :class="{ 'is-invalid': addressErrors.postcode }"
      />
      <div class="invalid-feedback">
        {{ addressErrors.postcode }}
      </div>
    </b-form-group>
  </div>
</template>
<style lang="scss">
  .addressSuggestions {
    z-index: 1;
  }
</style>
