import axios from 'axios'
import router from '@/router'
import authService from '@/services/authService'
import qs from 'qs'
import store from '@/stores/'
import TokenService from '@microbadevs/library'

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  crossDomain: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

function axiosConfig (config) {
  const token = authService.getAccessToken()
  if (token) {
    config.headers.authorization = 'Bearer ' + token
  }
  return config
}

// Add local token to request headers
HTTP.interceptors.request.use(
  (config) => {
    const token = authService.getAccessToken()
    if (token) {
      config.headers.authorization = 'Bearer ' + token
    }
    return axiosConfig(config)
  },
  (error) => {
    Promise.reject(error)
  }
)

// If responce is 401 - get a refresh token
HTTP.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const originalRequest = error.config

    if (error.response?.status === 503) {
      store.dispatch('maintenance/show', error.response?.data)
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      const refreshToken = authService.getRefreshToken()

      // If there's no token set, boot to login screen
      if (!refreshToken) {
        store.dispatch('logout').then(() => {
          router.push('/login')
          return Promise.reject(error)
        })
      }

      // Try to get new access token
      return axios
        .post(
          originalRequest.baseURL + '/oauth/token',
          qs.stringify({
            grant_type: 'refresh_token',
            refresh_token: refreshToken
          }),
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            auth: originalRequest.auth
          }
        )
        .then(({data}) => {
          TokenService.updateTokenDetails(data?.access_token, data?.refresh_token)
          return axios.request(axiosConfig(originalRequest))
        })
        .catch((error) => {
          // Error while using refresh token so boot to login screen
          store.dispatch('logout').then(() => {
            router.push('/login')
            return Promise.reject(error)
          })
        })
    }
    return Promise.reject(error)
  }
)

export { HTTP }
