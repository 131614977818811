import Hashids from 'hashids'

const KIT_HASH_ID_SALT = '2BJTIUAYUSMR7QG6'
const KIT_HASH_ID_MIN_LENGTH = 8
const KIT_HASH_ID_CHAR_POOL = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'
const HASHIDS = new Hashids(KIT_HASH_ID_SALT, KIT_HASH_ID_MIN_LENGTH, KIT_HASH_ID_CHAR_POOL)

export default class KitService {
  static getKitIdByHashId (hashId) {
    if (!hashId) throw new Error('Missing kit hash id.')
    const [kitId] = HASHIDS.decode(hashId.toUpperCase())
    return kitId
  }

  static getHashIdByKitId (kitId) {
    if (!kitId) throw new Error('Missing kit id.')
    return HASHIDS.encode(kitId)
  }
}
