<template>
  <div class="template-page">
    <Sidebar />

    <div class="app-body">
      <main class="main">
        <router-view />
        <div class="versionInfo">
          {{ versionInfo.tag }}-{{ versionInfo.hash }}
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import nav from '../nav'
import Sidebar from '@/components/parts/Sidebar'
import userProfile from '@/mixins/userProfile'

export default {
  name: 'Page',
  components: {
    Sidebar
  },
  mixins: [userProfile],
  data() {
    return {
      nav: nav.items,
      loadingData: false
    }
  },
  computed: {
    versionInfo() {
      return {
        tag: process.env.VUE_APP_TAG,
        hash: process.env.VUE_APP_HASH
      }
    },
    user: function () {
      return this.$store.state.GET_USER_RESPONSE
    }
  },
  mounted() {}
}
</script>

<style scoped lang="scss">
.versionInfo {
  opacity: 0.6;
  padding-bottom: 10px;
  padding-top: 30px;
  text-align: center;
}
.page {
  padding-bottom: 100px;
}
.link {
  font-weight: bold;
  border-left: 1px solid rgba(255, 255, 255, 0.7);
  padding: 0 1rem 0 2rem;
  margin-left: 2rem;
}
.alert.alert-warning {
  background: #3f9fcf;
  margin-bottom: 2rem;
  color: white;
  a {
    color: white;
  }
}
</style>
