<template>
  <div class="page container">
    <transition name="slide-fade">
      <div>
        <header>
          <h1 class="page-header">
            Promotional Codes
          </h1>
          <div class="page-description" />
        </header>

        <promo-ui @promo-code-added="syncPromoCodes" />
        <promo-listing
          v-if="promotionalCodes.length"
          :promotional-codes="promotionalCodes"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import PromoUi from '@/components/promoCodes/PromoUi'
import PromoListing from '@/components/promoCodes/PromoListing'

export default {
  name: 'PromoCode',
  components: { PromoUi, PromoListing },
  data () {
    return {
      promotionalCodes: []
    }
  },
  mounted: function () {
    this.syncPromoCodes()
  },
  methods: {
    syncPromoCodes () {
      this.promotionalCodes = []
      this.$store
        .dispatch('getAllPromoCodes')
        .then((response) => {
          this.promotionalCodes = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
