import { createAsyncTypes } from './mutation-types'

export default {
  POST_LOGIN: createAsyncTypes('POST_LOGIN'),
  POST_AUTHENTICATION_CODE: createAsyncTypes('POST_AUTHENTICATION_CODE'),
  POST_REFRESH: createAsyncTypes('POST_REFRESH'),
  GET_USER: createAsyncTypes('GET_USER'),
  GET_USER_BY_ID: createAsyncTypes('GET_USER_BY_ID'),
  GET_USERS: createAsyncTypes('GET_USERS'),
  SEARCH: createAsyncTypes('SEARCH'),
  PUT_USER: createAsyncTypes('PUT_USER'),
  POST_USER: createAsyncTypes('POST_USER'),
  PATCH_USER: createAsyncTypes('PATCH_USER'),
  POST_EMAIL_VERIFICATION: createAsyncTypes('POST_EMAIL_VERIFICATION'),
  GET_RESET_PASSWORD: createAsyncTypes('GET_RESET_PASSWORD'),
  POST_RESET_PASSWORD: createAsyncTypes('POST_RESET_PASSWORD'),
  POST_TEMPORARY_PASSWORD: createAsyncTypes('POST_TEMPORARY_PASSWORD'),
  GET_ROLES: createAsyncTypes('GET_ROLES'),
  GET_PARENTAL_CONSENT: createAsyncTypes('GET_PARENTAL_CONSENT'),
  POST_PARENTAL_CONSENT: createAsyncTypes('POST_PARENTAL_CONSENT'),
  POST_PARENTAL_CONSENT_EMAIL: createAsyncTypes('POST_PARENTAL_CONSENT_EMAIL'),
  GET_DASHBOARD: createAsyncTypes('GET_DASHBOARD'),
  GET_KITS: createAsyncTypes('GET_KITS'),
  GET_KITS_BY_USER_ID: createAsyncTypes('GET_KITS_BY_USER_ID'),
  GET_KIT_BY_KIT_ID: createAsyncTypes('GET_KIT_BY_KIT_ID'),
  GET_SENT_KITS: createAsyncTypes('GET_SENT_KITS'),
  GET_SENT_KITS_BY_USER_ID: createAsyncTypes('GET_SENT_KITS_BY_USER_ID'),
  POST_KIT_ACTIVATION: createAsyncTypes('POST_KIT_ACTIVATION'),
  DELETE_KIT_SHIPPING: createAsyncTypes('DELETE_KIT_SHIPPING'),
  PUT_SHIPPING_ORDER: createAsyncTypes('PUT_SHIPPING_ORDER'),
  POST_ORDER: createAsyncTypes('POST_ORDER'),
  GET_SURVEY: createAsyncTypes('GET_SURVEY'),
  PUT_SAMPLE: createAsyncTypes('PUT_SAMPLE'),
  GET_KIT_TIMELINE: createAsyncTypes('GET_KIT_TIMELINE'),
  GET_KIT_SAMPLE_STATUSES: createAsyncTypes('GET_KIT_SAMPLE_STATUSES'),
  GET_SAMPLE_BY_IDENTIFIER: createAsyncTypes('GET_SAMPLE_BY_IDENTIFIER'),
  GET_SAMPLE_BY_EXTERNAL_REFERENCE: createAsyncTypes(
    'GET_SAMPLE_BY_EXTERNAL_REFERENCE'
  ),
  POST_RESEARCH_ORDER: createAsyncTypes('POST_RESEARCH_ORDER'),
  POST_RESEARCH_PARTICIPANT: createAsyncTypes('POST_RESEARCH_PARTICIPANT'),
  GET_RUNS: createAsyncTypes('GET_RUNS'),
  GET_LAB_RUN_BY_ID: createAsyncTypes('GET_LAB_RUN_BY_ID'),
  GET_PLATES: createAsyncTypes('GET_PLATES'),
  GET_EXPERIMENTS: createAsyncTypes('GET_EXPERIMENTS'),
  GET_EXPERIMENT_BY_ID: createAsyncTypes('GET_EXPERIMENT_BY_ID'),
  GET_PLATE_BY_ID: createAsyncTypes('GET_PLATE_BY_ID'),
  GET_WELLS_BY_PLATE_ID: createAsyncTypes('GET_WELLS_BY_PLATE_ID'),
  PATCH_PLATE_BY_ID: createAsyncTypes('PATCH_PLATE_BY_ID'),
  POST_RUN: createAsyncTypes('POST_RUN'),
  PUT_UPDATE_RUN: createAsyncTypes('PUT_UPDATE_RUN'),
  GET_SAMPLESHEET: createAsyncTypes('GET_SAMPLESHEET'),
  GET_PLATE_PREPARATION_CANDIDATES: createAsyncTypes(
    'GET_PLATE_PREPARATION_CANDIDATES'
  ),
  POST_UPLOAD_SAMPLESHEET: createAsyncTypes('POST_UPLOAD_SAMPLESHEET'),
  POST_VALIDATE_SAMPLESHEET: createAsyncTypes('POST_VALIDATE_SAMPLESHEET'),
  POST_RUN_PREPARATION: createAsyncTypes('POST_RUN_PREPARATION'),
  POST_PLATE_TO_EXPERIMENT: createAsyncTypes('POST_PLATE_TO_EXPERIMENT'),
  POST_PLATE: createAsyncTypes('POST_PLATE'),
  DELETE_PLATE_FROM_EXPERIMENT: createAsyncTypes(
    'DELETE_PLATE_FROM_EXPERIMENT'
  ),
  GET_VOUCHER_CODE_BY_KIT_ID: createAsyncTypes('GET_VOUCHER_CODE_BY_KIT_ID'),
  GET_RESEARCH_PROJECT_SEARCH_TYPES: createAsyncTypes(
    'GET_RESEARCH_PROJECT_SEARCH_TYPES'
  ),
  GET_RESEARCH_PROJECT_BY_TYPE: createAsyncTypes(
    'GET_RESEARCH_PROJECT_BY_TYPE'
  ),
  GET_RESEARCH_PROJECT_PARTICIPANTS: createAsyncTypes(
    'GET_RESEARCH_PROJECT_PARTICIPANTS'
  ),
  GET_RESEARCH_PROJECT_KITS: createAsyncTypes('GET_RESEARCH_PROJECT_KITS'),
  GET_SAMPLE_FILE: createAsyncTypes('GET_SAMPLE_FILE'),
  GET_SAMPLE_FILESLIST: createAsyncTypes('GET_SAMPLE_FILESLIST'),
  POST_SAMPLE_FILE: createAsyncTypes('POST_SAMPLE_FILE'),
  DELETE_SAMPLE_FILE: createAsyncTypes('DELETE_SAMPLE_FILE'),
  GET_SAMPLE_ALIQUOTS: createAsyncTypes('GET_SAMPLE_ALIQUOTS'),
  POST_GENERATE_SAMPLE_ALIQUOTS: createAsyncTypes(
    'POST_GENERATE_SAMPLE_ALIQUOTS'
  ),
  GET_BRANDS: createAsyncTypes('GET_BRANDS'),
  GET_REPORTS: createAsyncTypes('GET_REPORTS'),
  GET_REPORT_BY_ID: createAsyncTypes('GET_REPORT_BY_ID'),
  GET_REPORTS_BY_REFERRAL_IDS: createAsyncTypes('GET_REPORTS_BY_REFERRAL_IDS'),
  PATCH_REPORT_BY_ID: createAsyncTypes('PATCH_REPORT_BY_ID'),
  GET_REFERRAL_BY_KIT_ID: createAsyncTypes('GET_REFERRAL_BY_KIT_ID'),
  GET_REFERRALS_BY_PATIENT_NAME: createAsyncTypes(
    'GET_REFERRALS_BY_PATIENT_NAME'
  ),
  GET_REFERRALS_BY_REFERRAL_IDS: createAsyncTypes(
    'GET_REFERRALS_BY_REFERRAL_IDS'
  ),
  GET_REFERRALS_BY_PRACTITIONER_EMAIL: createAsyncTypes(
    'GET_REFERRALS_BY_PRACTITIONER_EMAIL'
  ),
  CANCEL_REFERRAL_BY_ID: createAsyncTypes('CANCEL_REFERRAL_BY_ID'),
  SEND_REFERRAL_NOTIFICATION_BY_ID: createAsyncTypes(
    'SEND_REFERRAL_NOTIFICATION_BY_ID'
  ),
  REPLACE_SAMPLE: createAsyncTypes('REPLACE_SAMPLE'),
  GET_PATIENT_BY_ID: createAsyncTypes('GET_PATIENT_BY_ID')
}
