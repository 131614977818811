<template>
  <transition name="slide-fade">
    <div class="box box-shadow">
      <h3>Promotional Code Details</h3>
      <b-alert v-if="success" show variant="success">
        Promotional code saved.
      </b-alert>
      <b-alert v-if="failed" show variant="danger">
        {{ failMessage }}
      </b-alert>
      <form>
        <div class="form-group">
          <label for="promoCode">Promotional Code</label>
          <input
            id="promoCode"
            v-model="code"
            v-validate="'required|alpha_dash'"
            type="text"
            class="form-control promoCode"
            name="Promotional Code"
            placeholder="Promotional Code"
            :class="{ 'is-invalid': errors.first('Promotional Code') }"
          />
          <div class="invalid-feedback">
            {{ errors.first('Promotional Code') }}
          </div>
        </div>
        <div class="form-group">
          <label for="type">Type</label>
          <b-form-radio-group
            id="promoCodeTypes"
            v-model="type"
            :options="promoCodeTypes"
            name="type"
          />
        </div>
        <div class="form-group">
          <label for="productTypes">Product</label>
          <b-form-radio-group
            id="productTypes"
            v-model="selectedProductType"
            :options="productTypes"
            name="productTypes"
          />
        </div>
        <div class="form-group d-flex align-items-center">
          <label class="mt-2">Promo Code Usage Limit</label>
          <input
            v-if="addingUsageLimit"
            id="usage-limit"
            v-model="usageLimit"
            v-validate="'required|integer|min_value:0'"
            class="form-control col-3 ml-2"
            :class="{ 'is-invalid': errors.first('Usage Limit') }"
            name="Usage Limit"
            type="number"
          />
          <a
            href="#"
            class="ml-2 col-2 row"
            @click.prevent="addOrRemovePromoLimit"
          >
            <i
              :class="[
                addingUsageLimit ? 'fa fa-trash' : 'fa fa-pencil',
                'mr-2',
                'mt-1'
              ]"
            />
            {{ addingUsageLimit ? `Remove Limit` : `Add Limit` }}
          </a>
          <div class="col-3 invalid-feedback">
            <font-awesome-icon :icon="['fa', 'fa-exclamation-triangle']" />
            {{ errors.first('Usage Limit') }}
          </div>
        </div>
        <div v-if="type !== promoCodeTypes.TRACKING" class="form-group">
          <label for="value">Value</label>
          <input
            id="value"
            v-model="value"
            v-validate="'required'"
            type="number"
            min="0"
            class="form-control"
            name="Value"
            placeholder="Value"
            :class="{ 'is-invalid': errors.first('Value') }"
          />
          <div class="invalid-feedback">
            {{ errors.first('Value') }}
          </div>
        </div>
        <div class="form-group">
          <label for="messageToCustomer">Display Message To Customer</label>
          <textarea
            id="messageToCustomer"
            v-model="messageToCustomer"
            v-validate="'required'"
            class="form-control"
            name="Message To Customer"
            placeholder="Display message to customer"
            :class="{ 'is-invalid': errors.first('Message To Customer') }"
          />
          <div class="invalid-feedback">
            {{ errors.first('Message To Customer') }}
          </div>
        </div>
        <div class="form-group pb-3">
          <button
            type="button"
            class="btn btn-primary pull-right"
            @click="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </transition>
</template>
<script>
import promoCodeTypes from '@/enums/promoCodeTypes'
import productTypes from '@/enums/productTypes'

export default {
  name: 'PromoUi',
  data() {
    return {
      promoCodeTypes: promoCodeTypes,
      productTypes: productTypes,
      selectedProductType: productTypes.CONSUMER,
      code: null,
      type: promoCodeTypes.TRACKING,
      value: 0,
      addingUsageLimit: false,
      usageLimit: null,
      messageToCustomer: null,
      success: null,
      failed: null,
      failMessage: null
    }
  },
  methods: {
    addOrRemovePromoLimit() {
      this.addingUsageLimit = !this.addingUsageLimit
      this.usageLimit = null
    },
    submit() {
      this.success = null
      this.failed = null
      this.$validator.validate().then(() => {
        if (!this.errors.any()) {
          this.$store
            .dispatch('generatePromoCode', {
              code: this.code.toUpperCase(),
              type: this.type,
              value: this.value,
              usageLimit: this.usageLimit,
              messageToCustomer: this.messageToCustomer,
              productType: this.selectedProductType
            })
            .then(() => {
              this.success = true
              this.resetUI()
              this.$emit('promo-code-added')
            })
            .catch((error) => {
              this.failed = true
              this.failMessage =
                error.response.status === 403
                  ? `Promotional code (${this.code.toUpperCase()}) already exists.`
                  : 'Failed to save promotional code.'
            })
            .finally(() => this.errors.clear())
        }
      })
    },
    resetUI() {
      this.code = null
      this.type = promoCodeTypes.TRACKING
      this.value = 0
      this.messageToCustomer = null
    }
  }
}
</script>
<style lang="scss" scoped>
.promoCode {
  text-transform: uppercase;
}
</style>
