<template>
  <div>
    <b-form-checkbox
      v-model="showDisabledAccounts"
      class="text-right mb-5"
      name="check-button"
      switch
    >
      {{ toggleText }}
    </b-form-checkbox>
    <b-table
      :fields="fields"
      :items="staffAccountsToDisplay"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      hover
      small
      striped
    >
      <template #cell(edit_row)="row">
        <b-button size="sm" @click="row.toggleDetails">
          <i :class="[row.detailsShowing ? 'fas fa-times' : 'fas fa-edit']" />
        </b-button>
      </template>
      <template #row-details="row">
        <UserEditUI
          :roles="roles"
          :user-info="row.item"
          @cancelEdit="row.toggleDetails"
          @fetchUpdatedDetails="fetchData()"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import UserEditUI from '@/components/elements/UserEditUI'
import { Failure } from '@microbadevs/library'

export default {
  name: 'StaffListTable',
  components: { UserEditUI },
  data: function () {
    return {
      showDisabledAccounts: false,
      sortBy: 'firstName',
      sortDesc: false,
      fields: [
        { key: 'firstName', sortable: true },
        { key: 'lastName', sortable: true },
        { key: 'email', sortable: true },
        { key: 'edit_row', label: 'Edit', sortable: false }
      ],
      allStaffAccounts: [],
      roles: null
    }
  },
  computed: {
    toggleText() {
      const accountType = this.showDisabledAccounts ? 'Enabled' : 'Disabled'
      return `Show ${accountType} Accounts`
    },
    staffAccountsToDisplay() {
      return this.allStaffAccounts.filter((account) => {
        return account.enabled === !this.showDisabledAccounts
      })
    }
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    getStaff() {
      this.$store
        .dispatch('getStaffList')
        .then((response) => {
          this.allStaffAccounts = response?.data
        })
        .catch((error) => {
          Failure({
            text: `Failed to fetch staff ${error?.response}`
          })
        })
    },
    getRoles() {
      this.$store
        .dispatch('getRoles')
        .then((response) => {
          this.roles = response?.data
        })
        .catch((error) => {
          Failure({
            text: `Failed to fetch roles ${error?.response}`
          })
        })
    },
    fetchData() {
      this.getStaff()
      this.getRoles()
    }
  }
}
</script>
