<template>
  <transition name="slide-fade">
    <div>
      <div class="central-message">
        Oops, it looks like you don't have access to this page
      </div>
      <div>
        <a
          class="nav-link text-center"
          @click="goBack"
        >
          <span class="nav-title">Go back</span>
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Unauthorised',
  methods: {
    goBack () {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
body {
  background: white;
}
.central-message {
  font-size: 24px;
  color: blue;
  padding-top: 100px;
  text-align: center;
  font-weight: bold;
}
</style>
