'use strict'
import data from './data'

export class Address {
  constructor (jsonObj) {
    this.externalIdentifier = jsonObj.externalIdentifier || null
    this.provider = jsonObj.provider || null
    this.addressLine1 = jsonObj.addressLine1 || null
    this.state = jsonObj.state || null
    this.postcode = jsonObj.postcode || null
    this.suburb = jsonObj.suburb || null
    this.country = jsonObj.country || null
  }
}
export class addressService {
  static getCountries () {
    const countries = data.map(country => ({
      code: country.countryShortCode,
      name: country.countryName
    }))
    return countries
  }

  static getStates (country) {
    if (!country) return []

    let states =
      data.find(
        countryObject => countryObject.countryShortCode === country.code
      ).regions || []
    states = states.map(state => ({ code: state.shortCode, name: state.name }))
    return states
  }

  static async getSuggestionList (
    apiBaseUrl,
    country,
    addressLine1,
    sessionId
  ) {
    if (!country) throw new Error('Missing country')
    if (!apiBaseUrl) throw new Error('Api base url missing')

    let url = `${apiBaseUrl}/public/address/autocomplete?query=${addressLine1}&country=${country.code}`
    url = sessionId ? `${url}&session=${sessionId}` : url
    return fetch(url, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(json => {
        let suggestionList = json.candidates || []
        const sessionId = json.session || null
        suggestionList = suggestionList.map(suggestion => ({
          value: suggestion.externalIdentifier,
          name: suggestion.fullAddress,
          provider: suggestion.provider
        }))
        return { suggestionList, sessionId }
      })
      .catch(() => ({ suggestionList: null, sessionId: null }))
  }

  static async getSelectedSuggestionInfo (
    apiBaseUrl,
    country,
    sessionId,
    provider,
    identifier
  ) {
    if (!country) throw new Error('Missing country')
    if (!sessionId) throw new Error('Missing session')
    if (!provider) throw new Error('Missing provider')
    if (!identifier) throw new Error('Missing identifier')
    if (!apiBaseUrl) throw new Error('Missing api base url')

    let url = `${apiBaseUrl}/public/address/info?provider=${provider}&identifier=${identifier}`
    url = sessionId ? `${url}&session=${sessionId}` : url
    return fetch(url, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(json => {
        const addressLine1 = json.addressLine1
        const addressLine2 =
          json.addressLine2 && json.addressLine2.length
            ? `, ${json.addressLine2}`
            : ''
        json.addressLine1 = `${addressLine1} ${addressLine2}`
        return json
      })
  }
}
