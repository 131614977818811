<template>
  <div class="row">
    <div class="col-12">
      <download-excel
        class="btn btn-primary m-4 pull-right"
        :data="practitioners"
        type="csv"
        :fields="csvColumns"
        name="HCP_CSV.csv"
      >
        <font-awesome-icon :icon="['fa', 'fa-save']" />
        Download CSV
      </download-excel>
    </div>
    <div class="col-12">
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <th
              v-for="(col, key) in tableCol"
              :key="key"
              class="clickable"
              @click="sortBy(key)"
            >
              {{ col.name }}
              <span v-if="tableSort == key">
                <i
                  v-if="tableCol[tableSort]['sort'] == 'asc'"
                  class="fa fa-chevron-down"
                  aria-hidden="true"
                />
                <i v-else class="fa fa-chevron-up" aria-hidden="true" />
              </span>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="practitioner in practitioners" :key="practitioner.email">
            <td>{{ practitioner.name }}</td>
            <td>{{ practitioner.email }}</td>
            <td>{{ practitioner.phoneNumber }}</td>
            <td>{{ practitioner.practiceState }}</td>
            <td>{{ practitioner.referralCode }}</td>
            <td>{{ practitioner.practitionerType }}</td>
            <td>{{ practitioner.practiceName }}</td>
            <td>{{ practitioner.numberOfPatientsServedAnnually }}</td>
            <td>{{ practitioner.percentOfPatientsBenefit }}</td>
            <td>{{ practitioner.status }}</td>
            <td>
              {{ practitioner.registrationDate | DDMMYYYYSlashSeparated }}
            </td>
            <td>{{ practitioner.registeringBody }}</td>
            <td>{{ practitioner.registrationNumber }}</td>
            <td class="text-right">
              <a
                href="#"
                class="btn btn-outline-primary pt-1 pb-1 pl-3 pr-3"
                @click.prevent="viewProfessional(practitioner)"
                >Edit</a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="practitioners.length < 1" class="text-center">
        No professionals found
        <span v-if="search.length > 0">for this search</span>.
      </div>
    </div>
  </div>
</template>

<script>
import UserMixins from '@/mixins/userProfile'
import _ from 'lodash'
import { DDMMYYYYSlashSeparated } from '@/filters/timeFormatFilters'

export default {
  name: 'ResearchTable',
  components: {},
  filters: {
    DDMMYYYYSlashSeparated
  },
  mixins: [UserMixins],
  props: ['showData', 'search', 'items'],
  data: function () {
    return {
      tableSort: 'name',
      tableCol: {
        name: { sort: 'asc', name: 'Name' },
        email: { sort: 'asc', name: 'Email' },
        phoneNumber: { sort: 'asc', name: 'Phone' },
        practiceState: { sort: 'asc', name: 'Practice State' },
        referralCode: { sort: 'asc', name: 'Referral Code' },
        practitionerType: { sort: 'asc', name: 'Type' },
        practiceName: { sort: 'asc', name: 'Practice' },
        numberOfPatientsServedAnnually: { sort: 'asc', name: '# Patients' },
        percentOfPatientsBenefit: { sort: 'asc', name: '% Patients' },
        status: { sort: 'asc', name: 'Status' },
        registrationDate: { sort: 'asc', name: 'Registration Date' },
        registeringBody: { sort: 'asc', name: 'Registering Body' },
        registrationNumber: { sort: 'asc', name: 'Registering Number' }
      }
    }
  },
  computed: {
    csvColumns() {
      let columns = {}
      Object.entries(this.tableCol).forEach((columnObject) => {
        columns[columnObject[1].name] = columnObject[0]
      })
      return columns
    },
    practitioners() {
      const searchString = this.search ? this.search.toLowerCase() : null
      let practitioners = this.items
        .map((practitioner) => {
          switch (practitioner.approved) {
            case null:
              practitioner.status = 'Pending'
              break
            case false:
              practitioner.status = 'Declined'
              break
            case true:
              practitioner.status = practitioner.courseCompleted
                ? 'Course completed'
                : 'Pending course completion'
          }
          // set Referral Code
          practitioner.referralCode =
            practitioner.approved && practitioner.courseCompleted
              ? practitioner.referralCode
              : null
          return practitioner
        })
        .filter((practitioner) => {
          if (!searchString) {
            return true
          }
          return (
            this.isPropMatched(practitioner, 'name') ||
            this.isPropMatched(practitioner, 'email') ||
            this.isPropMatched(practitioner, 'practiceState') ||
            this.isPropMatched(practitioner, 'referralCode') ||
            this.isPropMatched(practitioner, 'practitionerType') ||
            this.isPropMatched(
              practitioner,
              'numberOfPatientsServedAnnually'
            ) ||
            this.isPropMatched(practitioner, 'registeringBody') ||
            this.isPropMatched(practitioner, 'registrationNumber')
          )
        })

      return _.orderBy(
        practitioners,
        [this.tableSort],
        [this.tableCol[this.tableSort]['sort']]
      )
    }
  },
  methods: {
    isPropMatched(practitioner, prop) {
      const searchString = this.search ? this.search.toLowerCase() : null
      return practitioner[prop]
        ? String(practitioner[prop]).toLowerCase().includes(searchString)
        : false
    },
    viewProfessional(obj) {
      this.$emit('selectProfessional', obj)
    },
    sortBy(col) {
      if (this.tableSort === col) {
        if (this.tableCol[col].sort === 'asc') {
          this.tableCol[col].sort = 'desc'
        } else {
          this.tableCol[col].sort = 'asc'
        }
      } else {
        this.tableSort = col
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chart-wrap {
  position: relative;
  height: 200px;
  margin: 50px auto;
}

em {
  font-size: 17px;
  opacity: 0.7;
  max-width: 500px;
  margin: 0px auto;
}
.padded-row {
  padding-bottom: 0;
  @media (min-width: 992px) {
    padding-bottom: 1rem;
  }
}

.mb-4 {
  margin-bottom: 1.25rem !important;
  @media (min-width: 992px) {
    margin-bottom: 1.5rem !important;
  }
}

.table-hover tr {
  cursor: pointer;
}

.dashboard {
  //padding-bottom:2.5rem;
  padding-bottom: 100px;
}

.clickable {
  cursor: pointer;
}
</style>
