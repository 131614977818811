<template>
  <transition name="slide-fade">
    <div class="login">
      <div class="login-bg" />

      <transition name="slide-fade">
        <main
          v-if="!isForgotPassword"
          class="main"
        >
          <a
            href="http://microba.com"
          ><img
            src="../assets/images/logo-full-mono-light.png"
            alt=""
          ></a>
          <h1>Staff sign in</h1>

          <div class="box">
            <SignIn
              :api-response="apiResponse"
              :hide-register-button="true"
              :message-type="messageType"
              @eventSignIn="signIn"
              @eventForgotPassword="isForgotPassword = true"
            />
          </div>

          <footer class="additional-info">
            <p>
              By logging in, you are agreeing to our
              <a
                href="https://www.microba.com/wp-content/uploads/2018/02/2018-02-15-Terms-and-Conditions-Microba-Pty-Limited-v2.pdf"
                target="_blank"
              >Terms & Conditions</a>
              and
              <a
                href="https://assets-microba-prod.microba.com/privacy-policy.pdf"
                target="_blank"
              >Privacy Policy</a>
            </p>
          </footer>
        </main>
      </transition>

      <transition name="slide-fade">
        <main
          v-if="isForgotPassword"
          class="main forgot-password"
        >
          <a
            style="cursor: pointer"
          ><img
            src="../assets/images/logo-full-mono-light.png"
            alt=""
            @click="isForgotPassword = false"
          ></a>

          <h1>Reset your password</h1>

          <div class="box">
            <transition name="slide-fade">
              <div
                v-if="isPasswordResetFailed"
                class="alert alert-danger"
                role="alert"
              >
                Sorry, failed to reset your password. Please try again later or contact dev team.
              </div>
            </transition>

            <div
              class="form-group has-feedback has-feedback-left col-12"
              :class="{ 'has-danger': errors.has('email') }"
            >
              <label
                class="form-control-label hidden"
                for="inputEmail"
              >Email</label>
              <div class="input-icon">
                <span class="oi oi-envelope-closed" />
              </div>
              <input
                id="inputEmail"
                v-model="email"
                v-validate="'required|email'"
                type="email"
                :class="{
                  'is-invalid': errors.has('email') && veeFields.email.touched
                }"
                class="form-control"
                data-vv-as="email"
                name="email"
                placeholder="Email"
              >
              <div class="text-danger">
                {{ errors.first('email') }}
              </div>
            </div>

            <div class="row">
              <Button
                text="Reset Password"
                @onClick="resetPassword"
              />
            </div>
          </div>
        </main>
      </transition>
    </div>
  </transition>
</template>

<script>
/* eslint-disable */
import Button from '@/components/elements/DynamicButton.vue'
import { SignIn } from '@microbadevs/library'

export default {
  name: 'Login',
  components: {
    Button,
    SignIn
  },
  data() {
    return {
      email: '',
      isForgotPassword: false,
      isPasswordResetFailed: false,
      apiResponse: new Object(),
      messageType: null
    }
  },
  methods: {
    signIn(payload) {
        this.$store.dispatch('login', payload)
        .catch (error => {
          this.messageType = null
          this.apiResponse = error?.response?.data
        })
    },
    async resetPassword() {
      const isEmailValid = await this.$validator.validate()
      if (!isEmailValid) return

      this.$store.dispatch('getPasswordReset', this.email).then(() => {
        this.isForgotPassword = false
        this.isPasswordResetFailed = false
        this.messageType = 'RESET_PASSWORD_SUCCESS'
      }).catch((error) => {
        this.isPasswordResetFailed = true
      })
    }
  }
}
</script>

<style lang="scss">
.signin-component {
  a {
    font-weight: 500;
    color: #ff8708 !important;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
body {
  background: white;
}

.radio-wrapper-outer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0rem 1rem 0rem;

  @media (min-width: 768px) {
    justify-content: flex-start;
    padding: 0;
  }
}

.radio-wrapper-inner {
  display: block;
}

.forgot-password {
  .row.suplimentary {
    padding-top: 1.5rem;

    @media (min-width: 768px) {
      padding-top: 0;
    }
  }
}
</style>
