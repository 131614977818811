export default {
  items: [
    {
      name: 'Dashboard',
      icon: 'fa fa-th-list',
      url: '/dashboard',
      children: [
        {
          name: 'Home',
          url: '/home'
        },
        {
          name: 'World',
          url: '/world'
        }
      ]
    },
    {
      name: 'Profile',
      icon: 'fa fa-th-list',
      url: '/profile',
      children: [
        {
          name: 'Profile',
          url: '/profile'
        }
      ]
    }
  ]
}
