<script>
import logisticTypes from '@/enums/logisticTypes'
export default {
  name: 'ClientManagedDispatchUI',
  data () {
    return {
      researchOrganisations: [],
      selectedResearchOrganisation: null,
      researchProjects: [],
      selectedResearchProject: null
    }
  },
  computed: {
    selectedProjectName () {
      return (
        this.researchProjects?.find((researchProject) => {
          return researchProject.value === this.selectedResearchProject
        })?.text || null
      )
    }
  },
  mounted () {
    this.fetchResearchOrganisations()
  },
  methods: {
    fetchResearchOrganisations () {
      this.$store.dispatch('getResearchers').then((response) => {
        const researchOrganisations = this.sortResearchOrganisationOrProjects(
          response?.data || []
        )
        this.researchOrganisations = researchOrganisations?.map(
          (organisation) => ({
            value: organisation.id,
            text: organisation.name
          })
        )
      })
    },
    fetchResearchProject (researchOrganisationId) {
      this.researchProjects = []
      this.selectedResearchProject = null
      this.$store
        .dispatch('getProjects', { id: researchOrganisationId })
        .then((response) => {
          let researchProjects = this.sortResearchOrganisationOrProjects(
            response?.data || []
          )?.filter(
            (project) => project.logisticType === logisticTypes.CLIENT_MANAGED
          )
          this.researchProjects = researchProjects?.map((project) => ({
            value: project.id,
            text: project.name
          }))
        })
    },
    sortResearchOrganisationOrProjects (researchOrProjects) {
      return researchOrProjects.sort((firstEntry, nextEntry) => {
        const firstEntryName = firstEntry?.name?.toUpperCase()
        const nextEntryName = nextEntry?.name?.toUpperCase()

        if (firstEntryName < nextEntryName) {
          return -1
        }
        if (firstEntryName > nextEntryName) {
          return 1
        }
        return 0
      })
    }
  }
}
</script>
<template>
  <div class="clientManagedDispatchUI">
    <b-jumbotron
      header="Dispatch Research"
      :lead="selectedProjectName || 'Client managed'"
    >
      <div class="row">
        <div class="col">
          <b-form-select
            v-model="selectedResearchOrganisation"
            :options="researchOrganisations"
            @input="fetchResearchProject"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Select Research Organisation
              </b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="col">
          <b-form-select
            v-model="selectedResearchProject"
            :options="researchProjects"
            @change="
              $emit('client-managed-project-changed', selectedResearchProject)
            "
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Select Research Project
              </b-form-select-option>
            </template>
          </b-form-select>
        </div>
      </div>
    </b-jumbotron>
  </div>
</template>
