<template>
  <transition name="slide-fade">
    <div class="box box-shadow" :class="isDispatch ? dispatchType : ''">
      <h3 v-if="isDispatch">Dispatch {{ dispatchTypeLabel }}</h3>
      <div class="form-group">
        <label for="code" class="stack-zero"> Code </label>
        <div class="inline-zero form-inline">
          <input
            id="code"
            ref="code"
            v-model="code"
            v-validate.disable="'required'"
            type="text"
            name="Code"
            class="form-control"
            placeholder="Scan (preferred) or type code..."
            :disabled="scanDisabled"
            :class="{ 'is-invalid': errors.first('Code') }"
            @keyup.enter="submit"
          />
          <button
            type="button"
            class="btn btn-primary"
            :disabled="scanDisabled"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <div v-if="errors" class="invalid-feedback">
          {{ errors.first('Code') }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { dispatchTypes, dispatchTypeLabels } from '@/enums/dispatchTypes'

export default {
  name: 'DispatchUi',
  components: {},
  props: {
    clearCode: Boolean,
    isDispatch: Boolean,
    nextShippingOrder: {
      type: Object,
      default: null
    },
    dispatchType: {
      required: true,
      type: String,
      default: dispatchTypes.REGULAR
    },
    scanDisabled: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      code: null
    }
  },
  computed: {
    dispatchTypeLabel() {
      return dispatchTypeLabels[this.dispatchType]
    }
  },
  watch: {
    clearCode: function () {
      this.code = this.clearCode ? null : this.sampleIdentifier
      if (!this.code) {
        this.$refs.code.focus()
      }
    }
  },
  methods: {
    submit() {
      this.$validator
        .validate()
        .then(() => {
          if (!this.errors.any()) {
            let payload = {
              code: this.code
            }
            /*
              Microba managed research sample will be similar to
              regular sample dispatch with two shipments(in and out).
              The only difference is research samples will
              print the voucher code instead of sample identifier.

              For client managed research sample, there is only one
              outgoing shippment since client will manage the sample.
            */
            switch (this.dispatchType) {
              case dispatchTypes.RESEARCH_CLIENT_MANAGED:
                payload.researchProjectId = this.nextShippingOrder
                  ?.researchProjectId
                  ? this.nextShippingOrder.researchProjectId
                  : null
                break
              default:
                if (this.nextShippingOrder) {
                  payload.shippingOrderId = this.nextShippingOrder?.id
                }
            }
            this.$emit('code-scanned', payload)
            this.code = null
          }
        })
        .catch((error) => console.log(error))
    }
  }
}
</script>

<style scoped>
.REGULAR {
  background-color: #5cd69f38;
}

.RESEARCH_MICROBA_MANAGED {
  background-color: #f274747a;
}

.RESEARCH_CLIENT_MANAGED {
  background-color: #ffeb0057;
}

.DISTRIBUTOR {
  background-color: lightBlue;
}

.ORBIT_M {
  background-color: #e8d8ff;
}
</style>
