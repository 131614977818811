<script>
import { sampleStatus } from '@/enums/kitItemStatus'
import sampleService from '@/services/sampleService'
import { Loader, Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import { Report, QCStatus } from '@/Models/qcReport'
import SampleToolbar from '@/components/elements/SampleToolbar'
import QCReport from '@/components/qcReport/qcReport'
import QCFilter from '@/components/qcReport/qcFilter'
import httpClientService from '@/services/httpClientService'
import productService from '@/services/productService'
import qs from 'qs'
import vSelect from 'vue-select'
import authService from '@/services/authService'

const defaultResultsFields = [
  {
    key: 'sampleIdentifier',
    label: 'Sample Identifier'
  }
]

export default {
  name: 'ResultsRelease',
  components: {
    SampleToolbar,
    QCReport,
    QCFilter,
    vSelect
  },
  data() {
    return {
      samples: [],
      activeTab: undefined,
      QCStatus,
      selectedSamples: [],
      sampleStatuses: sampleStatus,
      qcFilters: {},
      subFields: [
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'pageNo',
          label: 'Page No.'
        },
        {
          key: 'rms',
          label: 'Score'
        },
        {
          key: 'statusMessage',
          label: 'Status Message'
        },
        {
          key: 'QCDiffImage',
          label: 'View Image'
        }
      ],
      productData: null,
      selectedProduct: null,
      activeResultReleaseProductType: null,
      excludedProductListProductTypes: ['BAAS'],
      excludedReportProductTypes: ['BAAS', 'WHITE_LABEL', 'LBAAS']
    }
  },
  computed: {
    samplesWithStatus() {
      return this.samples?.map((sample) => {
        if (
          sample.sampleStatus === this.sampleStatuses.RESULTS_PROCESSED &&
          !this.sampleIsQCReportEnabled(sample)
        ) {
          return {
            ...sample,
            status: QCStatus.SUCCESS,
            _rowVariant: QCStatus.SUCCESS
          }
        }
        if (
          sample.sampleStatus ===
            this.sampleStatuses.RESULTS_PROCESSING_FAILED
        ) {
          sample.status = QCStatus.FAILURE
          sample._rowVariant = QCStatus.DANGER
          return {
            ...sample,
            status: QCStatus.FAILURE,
            _rowVariant: QCStatus.DANGER
          }
        }
        let status = QCStatus.SUCCESS
        let _rowVariant = QCStatus.SUCCESS
        sample.status = status
        sample._rowVariant = _rowVariant
        return {
          ...sample,
          status,
          _rowVariant
        }
      })
    },
    products() {
      return !this.productData
        ? []
        : this.productData
            .map((product) => {
              return {
                id: product.productId,
                name: product.productName,
                label: `${product.partnerCode} - ${product.productName} - ${product.productType}`,
                productType: product.productType,
                isQCReportEnabled: true,
                partnerCode: product.partnerCode,
                fields: [
                  {
                    key: 'selected',
                    label: '',
                    thStyle: { width: '3rem' }
                  },
                  {
                    key: 'sampleIdentifier',
                    label: 'Sample Identifier',
                    thStyle: { width: '10rem' }
                  },
                  ...(product.fields || defaultResultsFields),
                  {
                    key: 'sampleStatus',
                    label: 'Status'
                  },
                  {
                    key: 'actions',
                    label: 'Actions',
                    thStyle: { width: '10rem' }
                  }
                ]
              }
            })
            .filter(
              (product) =>
                !this.excludedProductListProductTypes.includes(
                  product.productType
                )
            )
    },
    samplesByProduct() {
      let samplesByProduct = {}
      this.products?.forEach((product) => {
        const samples = this.samplesWithStatus?.filter(
          (sample) => sample.productId === product.id
        )
        const filters = this.qcFilters[product.id]
        samplesByProduct[product.id] = {
          samples,
          filteredSamples: samples?.filter((sample) => {
            return (
              (sample.status === QCStatus.FAILURE && filters.error) ||
              (sample.status === QCStatus.WARNING && filters.warning) ||
              (sample.status === QCStatus.SUCCESS && filters.success)
            )
          }),
          samplesFailed: samples?.filter(
            (sample) => sample.status === QCStatus.FAILURE
          ),
          samplesPassed: samples?.filter(
            (sample) => sample.status === QCStatus.SUCCESS
          ),
          samplesWarning: samples?.filter(
            (sample) => sample.status === QCStatus.WARNING
          )
        }
      })
      return samplesByProduct
    },
    showPreviewButtonForProduct() {
      const productsList = ['insight', 'mybiome', 'gensense', 'microbiomix']
      return productsList.includes(this.selectedProduct.name.toLowerCase())
    }
  },
  async mounted() {
    await this.getSamples()
    await this.fetchProductData()
  },
  methods: {
    async getSamples() {
      try {
        Loader.show()
        const {
          data: samplesResultsProcessed
        } = await sampleService.getSamplesByStatus(
          sampleStatus.RESULTS_PROCESSED
        )
        const {
          data: samplesResultsProcessingFailed
        } = await sampleService.getSamplesByStatus(
          sampleStatus.RESULTS_PROCESSING_FAILED
        )
        this.samples = [
          ...samplesResultsProcessed,
          ...samplesResultsProcessingFailed
        ]
        Loader.hide()
      } catch (error) {
        Notification({
          title: 'Failed',
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    switchActiveResultReleaseProduct(resultReleaseProduct) {
      this.unselectAll()
      this.activeResultReleaseProduct = resultReleaseProduct
    },
    async previewReport(sampleData, reportData) {
      try {
        if (this.selectedProduct.productName === 'myBIOME') {
          const externalSampleIdentifier = sampleData?.sampleExternalReference
          const {
            data: synlabTokenDetails
          } = await httpClientService
            .synlabApiHttpClient()
            .post(
              `${process.env.VUE_APP_API_ROOT}/oauth/token`,
              qs.stringify({ grant_type: 'client_credentials' })
            )
          const synlabClientToken = synlabTokenDetails.access_token
          const {
            data: synlabReportTokenDetails
          } = await httpClientService
            .getAPIHttpClient(true, synlabClientToken)
            .get(
              `${process.env.VUE_APP_API_ROOT}/distributor/sample/${externalSampleIdentifier}/token`
            )
          const synlabReportToken = synlabReportTokenDetails?.access_token
          const queryParams = {}
          if (reportData?.sample?.language)
            queryParams.lang = reportData?.sample?.language
          if (reportData?.sample?.theme)
            queryParams.theme = reportData?.sample?.theme
          const queryString = new URLSearchParams(queryParams).toString()
          window.open(
            `${process.env.VUE_APP_SYNLAB_REPORT_URL}es/${synlabReportToken}?${queryString}`,
            '_blank'
          )
        } else {
          const accessToken = authService.getAccessToken()
          window.open(
            `${process.env.VUE_APP_INSIGHT_PDF}/report/${sampleData.sampleIdentifier}/${accessToken}/`,
            '_blank'
          )
        }
      } catch (error) {
        Notification({
          title: 'Failed',
          text: error.response.data.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    async publishSingleResult(sampleData) {
      const userResponse = await Notification({
        title: `Publish the result for ${sampleData.sampleIdentifier}`,
        text: 'Are you sure?',
        style: NOTIFICATION_STYLES.QUESTION
      })
      if (userResponse.isConfirmed) {
        try {
          this.publishResult(sampleData)
          await this.getSamples()
          this.unselectAll()
          Notification({
            text: 'Result has been successfully released.',
            style: NOTIFICATION_STYLES.SUCCESS
          })
        } catch (error) {
          Notification({
            title: 'Failed',
            text: error.response?.data?.message || 'Could not release result',
            style: NOTIFICATION_STYLES.ERROR
          })
        }
      }
    },
    async publishResult(sampleData) {
      await this.$store.dispatch('putSample', {
        id: sampleData.id,
        sampleStatus: sampleStatus.RESULTS_AVAILABLE
      })
    },
    formatSeconds(seconds) {
      if (seconds > 59) {
        const minutes = Math.floor(seconds / 60)
        const secondsRemaining = seconds % 60
        return `${minutes} minute/s and ${secondsRemaining} second/s`
      }
      return `${seconds} second/s`
    },
    async publishSelectedSamples() {
      const userResponse = await Notification({
        title: `Publish the result for selected samples.`,
        text: `Are you sure. This will take approximately ${this.formatSeconds(
          this.selectedSamples.length + 1
        )}`,
        style: NOTIFICATION_STYLES.QUESTION
      })
      if (userResponse.isConfirmed) {
        Loader.show()
        let errors = []
        for (const [index, sample] of this.selectedSamples.entries()) {
          await new Promise((resolve) => {
            const timeout = setTimeout(() => {
              resolve()
              clearTimeout(timeout)
            }, 1000)
          })
          try {
            this.publishResult(sample)
          } catch (error) {
            errors.push({
              sampleIdentifier: sample.sampleIdentifier,
              error:
                error.response?.data?.message || 'Could not release results'
            })
          }
        }
        Loader.hide()
        if (errors.length) {
          await Notification({
            title: 'Failed Samples',
            text: errors.reduce(
              (text, error) =>
                `${text} ${error.sampleIdentifier}: Error: ${error.error}<br/>`,
              ''
            ),
            style: NOTIFICATION_STYLES.ERROR
          })
        } else {
          await Notification({
            text: 'Results have been successfully released.',
            style: NOTIFICATION_STYLES.SUCCESS
          })
        }
        try {
          await this.getSamples()
          this.unselectAll()
        } catch (error) {
          Notification({
            title: 'Failed',
            text: error.response.data.message,
            style: NOTIFICATION_STYLES.ERROR
          })
        }
      }
    },
    unselectAll() {
      this.selectedSamples = []
    },
    selectAll() {
      this.unselectAll()
      this.selectedSamples =
        this.samplesByProduct[this.activeResultReleaseProduct?.id].samples || []
    },
    updateFilter(filterKey, resultKey, enabled) {
      this.qcFilters = {
        ...this.qcFilters,
        [resultKey]: {
          ...this.qcFilters[resultKey],
          [filterKey]: enabled
        }
      }
    },
    async reloadData() {
      await this.getSamples()
      // await this.getQCReports()
    },
    async fetchProductData() {
      try {
        Loader.show()
        const {
          data: productData
        } = await productService.getProductSpecifications()
        this.productData = [
          ...productData,
          {
            // Hardcode appended support for RESEARCH as a product
            distributorId: 8,
            partnerCode: 'mic',
            productId: null,
            productName: 'RESEARCH',
            productType: 'LBRAAS',
            description: 'Research product for Microba',
            specification: {
              'bio-service-name': 'insight',
              'bio-service-version': '2.0.0'
            }
          }
        ]
        const qcFilters = {}
        this.productData.forEach(({ productId }) => {
          qcFilters[productId] = {
            success: true,
            warning: true,
            error: true
          }
        })
        this.qcFilters = qcFilters
        Loader.hide()
      } catch (error) {
        Notification({
          title: 'Failed',
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    setProduct() {
      if (this.selectedProduct) {
        this.switchActiveResultReleaseProduct(this.selectedProduct)
      }
    },
    sampleIsQCReportEnabled(sample) {
      return this.products?.find((product) => product.id === sample.productId)
        ?.isQCReportEnabled
    }
  }
}
</script>
<template>
  <div class="results-release page container">
    <header class="inline-between">
      <h1>Results Release</h1>

      <a
        href="https://microba.atlassian.net/l/c/cmmLuTwG"
        target="_blank"
        class="btn btn-sm btn-outline-warning inline-half"
        ><span class="fa fa-question-circle"></span> User guide</a
      >
    </header>
    <div class="box box-shadow">
      <label for="select-product">Select a product</label>

      <vSelect
        id="select-product"
        v-model="selectedProduct"
        placeholder="Select Product"
        :options="products"
        @input="setProduct"
      >
        <template #option="{ id, label }">
          {{ label }}
          <span class="float-right">
            <b-badge pill variant="success">
              {{ samplesByProduct[id].samplesPassed.length }}
            </b-badge>
            <b-badge pill variant="primary">
              {{ samplesByProduct[id].samplesWarning.length }}
            </b-badge>
            <b-badge pill variant="danger">
              {{ samplesByProduct[id].samplesFailed.length }}
            </b-badge>
          </span>
        </template>
      </vSelect>

      <q-c-filter
        v-if="selectedProduct"
        button-size="sm"
        :success-count="
          samplesByProduct[selectedProduct.id].samplesPassed.length
        "
        :warning-count="
          samplesByProduct[selectedProduct.id].samplesWarning.length
        "
        :error-count="samplesByProduct[selectedProduct.id].samplesFailed.length"
        :success-visible="qcFilters[selectedProduct.id].success"
        :warning-visible="qcFilters[selectedProduct.id].warning"
        :error-visible="qcFilters[selectedProduct.id].error"
        @success-toggle="updateFilter('success', selectedProduct.id, $event)"
        @warning-toggle="updateFilter('warning', selectedProduct.id, $event)"
        @error-toggle="updateFilter('error', selectedProduct.id, $event)"
      />

      <div v-if="selectedProduct">
        <b-table
          hover
          :items="samplesByProduct[selectedProduct.id].filteredSamples"
          :fields="selectedProduct.fields"
          show-empty
          empty-text="There is no sample to release."
        >
          <template v-slot:cell(selected)="row">
            <b-form-checkbox
              v-model="selectedSamples"
              type="checkbox"
              :value="row.item"
            />
          </template>

          <template v-slot:cell(practitionerName)="row">
            {{ row.item.practitionerFirstName }}
            {{ row.item.practitionerLastName }}
          </template>

          <template v-slot:cell(sampleStatus)="row">
            <div
              :class="[
                'tag',

                row.item.sampleStatus === 'RESULTS_PROCESSING_FAILED'
                  ? 'failed'
                  : 'info'
              ]"
            >
              {{ row.item.sampleStatus }}
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="row">
              <SampleToolbar
                class="mr-2"
                :sample-identifier="row.item.sampleIdentifier"
                :sample-status="row.item.sampleStatus"
                :product-type="row.item.productType"
                :product-code="row.item.productCode"
                :can-aliquot="!row.item.aliquotOfSample"
                @sampleData:updated="reloadData"
              />
              <i
                v-if="
                  showPreviewButtonForProduct &&
                  row.item.sampleStatus !== 'RESULTS_PROCESSING_FAILED'
                "
                v-b-popover.hover="'Preview'"
                role="button"
                class="fa fa-eye fa-2x mr-2"
                @click.prevent="previewReport(row.item)"
              />
              <i
                v-b-popover.hover="'Publish'"
                class="fa fa-upload fa-2x mr-2"
                @click="publishSingleResult(row.item)"
              />
              <i
                v-if="selectedProduct.isQCReportEnabled"
                v-b-popover.hover="'More Info'"
                class="fa fa-info-circle fa-2x mr-2 my-auto"
                @click="row.toggleDetails"
              />
            </div>
          </template>

          <template v-if="selectedProduct.isQCReportEnabled" #row-details="row">
            <b-card>
              <QCReport
                v-bind.sync="row.item"
                @preview-report="previewReport(row.item, $event)"
              />
              <b-button class="pull-right" size="sm" @click="row.toggleDetails">
                Hide Details
              </b-button>
            </b-card>
          </template>
        </b-table>
        <b-button size="sm" class="mr-1" @click="selectAll()">
          Select All
        </b-button>
        <b-button size="sm" @click="unselectAll"> Unselect All </b-button>
        <div class="float-right">
          <b-button
            size="sm"
            :disabled="selectedSamples.length < 1"
            @click="publishSelectedSamples"
          >
            Publish Selected Samples
          </b-button>
        </div>
        <div class="text-right mt-2">
          <span>
            * Please enable popup to review multiple selected samples.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
