<script>
import ResearchProjectService from '@/services/researchProjectService'
import { Notification, NOTIFICATION_STYLES, Loader } from '@microbadevs/library'

export default {
  name: 'OrbitMDispatch',
  props: {
    researchProjectId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      sites: [],
      selectedSite: null
    }
  },
  computed: {
    selectedSiteDetails () {
      if (!this.selectedSite) return undefined

      return this.sites?.find(site => site.id === this.selectedSite)
    },
    shippingAddress () {
      if (!this.selectedSiteDetails) return undefined
      return this.selectedSiteDetails?.shippingAddress
    },
    shippingContact () {
      if (!this.selectedSiteDetails) return undefined
      return this.selectedSiteDetails?.shippingContact
    }
  },
  async mounted () {
    this.fetchSites()
  },
  methods: {
    async fetchSites () {
      try {
        Loader.show()
        const {data: sites} = await ResearchProjectService.getSitesByProjectId(this.researchProjectId)
        this.sites = sites
        Loader.hide()
      } catch (error) {
        const errorMessage = error?.response?.data?.message || error?.message
        Notification({
          text: `Failed to fetch sites. ${errorMessage}`,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    }
  }
}
</script>
<template>
  <div>
    <b-form-group
      id="input-group-site"
      label="Select dispatch site:"
      label-for="input-group-site"
    >
      <b-form-select
        id="input-3"
        v-model="selectedSite"
        :options="sites"
        value-field="id"
        text-field="name"
        required
        @input="$emit('siteUpdated', selectedSiteDetails)"
      >
        <template #first>
          <b-form-select-option
            :value="null"
            disabled
          >
            -- Please select a site --
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
    <hr>
    <b-row v-if="selectedSiteDetails">
      <b-col
        v-if="shippingAddress"
        class="border-right ml-5"
      >
        <h3>Shipping Address:</h3>
        <address class="ml-5">
          {{ shippingAddress.addressLine1 }}<br>
          <span v-if="shippingAddress.addressLine2">{{ shippingAddress.addressLine2 }}</span>
          {{ shippingAddress.suburb }}<br>
          {{ shippingAddress.state }}<br>
          {{ shippingAddress.postcode }}
        </address>
      </b-col>
      <b-col
        v-if="shippingContact"
        class="ml-5"
      >
        <h3>Shipping Contact:</h3>
        <address class="ml-5">
          {{ shippingContact.firstName }} {{ shippingContact.lastName }}<br>
          {{ shippingContact.email }}<br>
          {{ shippingContact.phone }}<br>
        </address>
      </b-col>
    </b-row>
  </div>
</template>
