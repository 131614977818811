<template>
  <div class="box box-shadow stack">
    <header class="inline-between inline-wrap">
      <h2>Referrals</h2>
      <div>
        <b-form-select v-model="referralStatusFilter">
          <b-form-select-option :value="null"
            >All statuses</b-form-select-option
          >
          <b-form-select-option
            v-for="status in referralStatusFilterOptions"
            :key="status.name"
            :value="status.code"
          >
            {{ status.name }}
          </b-form-select-option>
        </b-form-select>
      </div>
    </header>
    <div class="table-wrapper">
      <b-table
        ref="referralsTable"
        :fields="fieldsList"
        :items="filteredReferrals"
        :select-mode="selectMode"
        :selectable="isSelectable"
        hover
        show-empty
        striped
        @row-selected="selectNextReferral"
      >
        <template v-slot:cell(referralId)="data">
          {{ data.item.referralId || '-' }}
        </template>
        <template v-slot:cell(productCode)="data">
          {{ data.item.productCode || '-' }}
        </template>
        <template v-slot:cell(referralStatus)="data">
          <div :class="['tag', data.item.referralStatus.toLowerCase()]">
            {{ data.item.referralStatus || '-' }}
          </div>
        </template>
        <template v-slot:cell(notificationSentAt)="data">
          {{ data.item.notificationSentAt || '-' }}
        </template>
        <template v-slot:cell(patientFullName)="data">
          {{ data.item.patientFullName || '-' }}
        </template>
        <template v-slot:cell(practiceName)="data">
          {{ data.item.practiceName || '-' }}
        </template>
        <template v-slot:cell(kitId)="data">
          {{ data.item.kitId || '-' }}
        </template>
        <template v-slot:cell(reportId)="data">
          {{ data.item.reportId || '-' }}
        </template>
        <template v-slot:cell(actions)="row">
          <div class="inline">
            <b-link
              v-if="canCancel(row)"
              v-b-popover.hover="'Cancel Referral'"
              @click="cancelReferral(row.item)"
            >
              <i aria-hidden="true" class="fa fa-trash fa-2x" />
            </b-link>
            <b-link
              v-if="canResend(row)"
              v-b-popover.hover="'Resend Referral Notification'"
              @click="sendReferralNotification(row.item)"
            >
              <i aria-hidden="true" class="fa fa-paper-plane fa-2x" />
            </b-link>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { referralStatus, referralStatusLabels } from '@/enums/referralStatus'

export default {
  name: 'Referrals',
  components: {},
  props: {
    allReferrals: Array
  },
  data() {
    return {
      referralStatusFilter: null,
      selectMode: 'single',
      isSelectable: false,
      fieldsList: [
        { key: 'referralId', label: 'Referral ID' },
        { key: 'productCode', label: 'Product Code' },
        { key: 'referralStatus', label: 'Referral Status', sortable: true },
        {
          key: 'notificationSentAt',
          label: 'Notification Sent',
          sortable: true
        },
        { key: 'patientFullName', label: 'Patient Name' },
        { key: 'practiceName', label: 'Practice Name' },
        { key: 'kitId', label: 'Kit ID' },
        { key: 'reportId', label: 'Report ID' },
        { key: 'actions', label: 'Action' }
      ]
    }
  },
  computed: {
    referralStatusFilterOptions() {
      const uniqueStatuses = Array.from(
        new Set(this.allReferrals.map((r) => r.referralStatus))
      )
      return Array.from(
        uniqueStatuses.map((s) => ({
          name: referralStatusLabels[s],
          code: s
        }))
      )
    },
    filteredReferrals() {
      if (this.referralStatusFilter) {
        return this.allReferrals.filter(
          (referral) => referral.referralStatus === this.referralStatusFilter
        )
      } else {
        return this.allReferrals
      }
    }
  },
  methods: {
    selectNextReferral(payload) {
      const [selectedReferral] = payload
      this.$emit('selected-referral', selectedReferral)
    },
    canCancel(row) {
      return (
        row.item.referralStatus === referralStatus.CREATED ||
        row.item.referralStatus === referralStatus.SENT
      )
    },
    canResend(row) {
      return this.canCancel(row)
    },
    cancelReferral(item) {
      if (confirm('Cancel this referral')) {
        this.$emit('cancel-referral', item)
      }
    },
    sendReferralNotification(item) {
      this.$emit('send-referral-notification', item)
    }
  }
}
</script>

<style scoped>
h2 {
  margin: 0;
}

.table-wrapper {
  overflow: auto;
}

:deep(th > div) {
  white-space: nowrap;
}

.tag.report_available {
  color: var(--color-positive-100);
  background-color: var(--color-positive-10);
}

.tag.sent {
  color: var(--color-primary-100);
  background-color: var(--color-primary-10);
}

.tag.actioned {
  color: var(--color-secondary-100);
  background-color: var(--color-secondary-10);
}

.tag.cancelled {
  color: var(--color-critical-100);
  background-color: var(--color-critical-10);
}
</style>
