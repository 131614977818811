var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slide-fade"}},[_c('div',{staticClass:"login"},[_c('div',{staticClass:"login-bg"}),_c('main',{staticClass:"main"},[_c('img',{attrs:{"src":require("../assets/images/logo-full-mono-light.png"),"alt":""}}),_c('h1',[_vm._v("Reset password")]),(_vm.token)?_c('div',{staticClass:"box row"},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.localError)?_c('div',{staticClass:"alert alert-danger col",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.localError)+" ")]):_vm._e()]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.serverError)?_c('div',{staticClass:"alert alert-danger col",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.serverError)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group has-feedback has-feedback-left col-12",class:{ 'has-danger': _vm.errors.has('password') }},[_c('label',{staticClass:"form-control-label hidden",attrs:{"for":"password"}},[_vm._v("Password")]),_c('div',{staticClass:"input-icon"},[_c('span',{staticClass:"oi oi-lock-locked"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"},{name:"validate",rawName:"v-validate",value:('required|min:8'),expression:"'required|min:8'"}],staticClass:"form-control",class:{
              'is-invalid': _vm.errors.has('password') && _vm.veeFields.password.touched
            },attrs:{"id":"password","type":"password","data-vv-as":"password","name":"password","placeholder":"New Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password')),expression:"errors.has('password')"}],staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('password'))+" ")])]),_c('div',{staticClass:"form-group has-feedback has-feedback-left col-12",class:{ 'has-danger': _vm.errors.has('password') }},[_c('label',{staticClass:"form-control-label hidden",attrs:{"for":"passwordConfirm"}},[_vm._v("Password")]),_c('div',{staticClass:"input-icon"},[_c('span',{staticClass:"oi oi-lock-locked"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirm),expression:"passwordConfirm"},{name:"validate",rawName:"v-validate",value:('required|min:8'),expression:"'required|min:8'"}],staticClass:"form-control",class:{
              'is-invalid':
                _vm.errors.has('passwordConfirm') &&
                _vm.veeFields.passwordConfirm.touched
            },attrs:{"id":"passwordConfirm","type":"password","data-vv-as":"password","name":"passwordConfirm","placeholder":"Confirm"},domProps:{"value":(_vm.passwordConfirm)},on:{"input":function($event){if($event.target.composing)return;_vm.passwordConfirm=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('passwordConfirm')),expression:"errors.has('passwordConfirm')"}],staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('passwordConfirm'))+" ")])]),_c('b-button',{staticClass:"loading",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.postPasswordReset}},[_vm._v(" Reset ")])],1):_vm._e(),(!_vm.token)?_c('div',{staticClass:"box row"},[_c('div',{staticClass:"alert alert-danger col-12",attrs:{"role":"alert"}},[_vm._v(" Could not parse token ")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }