<template>
  <transition name="slide-fade">
    <div class="dashboard">
      <div class="container">
        <header>
          <div class="row">
            <h1 class="col-md-8 mb-2 page-header">Key Statistics</h1>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" />
                  <th scope="col">Past 24 hours</th>
                  <th scope="col">Past 7 days</th>
                  <th scope="col">Past 30 days</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">New Account</th>
                  <td>{{ stats.numberOfNewUsersPastDay }}</td>
                  <td>{{ stats.numberOfNewUsersPastWeek }}</td>
                  <td>{{ stats.numberOfNewUsersPastMonth }}</td>
                  <td>{{ stats.numberOfUsers }}</td>
                </tr>
                <tr>
                  <th scope="row">Adult Account</th>
                  <td>{{ stats.numberOfAdultUsersPastDay }}</td>
                  <td>{{ stats.numberOfAdultUsersPastWeek }}</td>
                  <td>{{ stats.numberOfAdultUsersPastMonth }}</td>
                  <td>{{ stats.numberOfAdultUsers }}</td>
                </tr>
                <tr>
                  <th scope="row">Yes to data share %</th>
                  <td>
                    {{
                      (stats.numberOfNewUsersAgreeToDataSharePastDay /
                        stats.numberOfNewUsersPastDay)
                        | percentage
                    }}
                  </td>
                  <td>
                    {{
                      (stats.numberOfNewUsersAgreeToDataSharePastWeek /
                        stats.numberOfNewUsersPastWeek)
                        | percentage
                    }}
                  </td>
                  <td>
                    {{
                      (stats.numberOfNewUsersAgreeToDataSharePastMonth /
                        stats.numberOfNewUsersPastMonth)
                        | percentage
                    }}
                  </td>
                  <td>
                    {{
                      (stats.numberOfUsersAgreeToDataUsage /
                        stats.numberOfAdultUsers)
                        | percentage
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="!biDashboardLoaded" class="row">
            <h1 class="col-sm-12">
              Loading Dashboard
              <font-awesome-icon
                :icon="['fa', 'fa-circle-o-notch']"
                class="fa-spin fa-fw"
              />
            </h1>
          </div>
          <div v-if="biDashboardEnabled" class="row">
            <iframe
              :src="getDashboardUrl('VUE_APP_BI_TOKEN_MICROBA_DASHBOARD')"
              frameborder="0"
              height="800"
              allowtransparency
              class="col-sm-12"
              @load="biDashboardLoaded = true"
            />
          </div>
          <div class="row">
            <iframe
              :src="getDashboardUrl('VUE_APP_BI_TOKEN_SUPPORT_DASHBOARD')"
              frameborder="0"
              height="800"
              allowtransparency
              class="col-sm-12"
              @load="biDashboardLoaded = true"
            />
          </div>
        </header>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from 'moment'
import UserMixins from '@/mixins/userProfile'
import { biAuthorisedUsers } from '@/enums/biAuthorisedUsers'

export default {
  name: 'Dashboard',
  mixins: [UserMixins],
  data: function () {
    return {
      showPurchaseModal: false,
      stats: {},
      biAuthorisedUsers,
      biDashboardLoaded: false
    }
  },
  computed: {
    user: function () {
      return this.$store.state.GET_USER_RESPONSE
    },
    users: function () {
      return this.$store.state.GET_USERS_RESPONSE
    },
    biDashboardEnabled: function () {
      return this.user && this.biAuthorisedUsers.includes(this.user.email)
    }
  },
  watch: {
    users: function () {
      if (this.users !== undefined) {
        this.calcStats()
      }
    }
  },
  mounted: function () {
    this.$store.dispatch('getUsers')
  },
  methods: {
    calcStats: function () {
      this.stats = {
        numberOfNewUsersPastDay: 0,
        numberOfNewUsersPastWeek: 0,
        numberOfNewUsersPastMonth: 0,
        numberOfUsers: 0,
        numberOfAdultUsersPastDay: 0,
        numberOfAdultUsersPastWeek: 0,
        numberOfAdultUsersPastMonth: 0,
        numberOfAdultUsers: 0,
        numberOfNewUsersAgreeToDataSharePastDay: 0,
        numberOfNewUsersAgreeToDataSharePastWeek: 0,
        numberOfNewUsersAgreeToDataSharePastMonth: 0,
        numberOfUsersAgreeToDataUsage: 0
      }
      this.users.map((user) => {
        this.stats.numberOfUsers++
        if (!user.underAge) {
          this.stats.numberOfAdultUsers++
        }
        if (user.agreeToDataUsage) {
          this.stats.numberOfUsersAgreeToDataUsage++
        }
        let createdDate = moment(user.createdDate)
        if (moment().diff(createdDate, 'days') <= 30) {
          this.stats.numberOfNewUsersPastMonth++
          if (!user.underAge) {
            this.stats.numberOfAdultUsersPastMonth++
          }
          if (user.agreeToDataUsage) {
            this.stats.numberOfNewUsersAgreeToDataSharePastMonth++
          }
        }
        if (moment().diff(createdDate, 'days') <= 7) {
          this.stats.numberOfNewUsersPastWeek++
          if (!user.underAge) {
            this.stats.numberOfAdultUsersPastWeek++
          }
          if (user.agreeToDataUsage) {
            this.stats.numberOfNewUsersAgreeToDataSharePastWeek++
          }
        }
        if (moment().diff(createdDate, 'days') <= 1) {
          this.stats.numberOfNewUsersPastDay++
          if (!user.underAge) {
            this.stats.numberOfAdultUsersPastDay++
          }
          if (user.agreeToDataUsage) {
            this.stats.numberOfNewUsersAgreeToDataSharePastDay++
          }
        }
      })
    },
    getDashboardUrl(token) {
      return `${process.env.VUE_APP_BI_URL}/embed/dashboard/${process.env[token]}#bordered=false&titled=false`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chart-wrap {
  position: relative;
  height: 200px;
  margin: 50px auto;
}

em {
  font-size: 17px;
  opacity: 0.7;
  max-width: 500px;
  margin: 0px auto;
}
.padded-row {
  padding-bottom: 0;
  @media (min-width: 992px) {
    padding-bottom: 1rem;
  }
}

.mb-4 {
  margin-bottom: 1.25rem !important;
  @media (min-width: 992px) {
    margin-bottom: 1.5rem !important;
  }
}
.dashboard {
  padding-bottom: 100px;
}
</style>
