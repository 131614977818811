<script lang="js">
import { Notification, NOTIFICATION_STYLES } from '@microbadevs/library'

import httpClientService from '@/services/httpClientService'
import {kitStatus} from '@/enums/kitItemStatus'

export default {
  name: 'RefundKit',
  props: {
    kit: Object,
    default: null
  },
  data () {
    return {
      refundData: null,
      refundAmount: null,
      apiSuccess: null,
      apiError: null
    }
  },
  computed: {
    maxToRefund () {
      return this.refundData
        ? Math.max(0, this.refundData.totalPaidAmount - this.refundData.totalRefundedAmount)
        : 0
    }
  },
  created () {
    this.getRefundData()
  },
  methods: {
    checkRefundAmount: function (data) {
      this.$validator
        .validate()
        .then(response => {
          this.submitRefund()
        })
        .catch(error => {
          Notification({
            text: error.response.data.message,
            style: NOTIFICATION_STYLES.ERROR
          })
          console.error(error)
        })
    },
    getRefundData: function () {
      // Get the refund info
      const url = '/kit/' + this.kit.id + '/refund'
      httpClientService.getAPIHttpClient().get(url)
        .then(response => {
          this.refundData = {
            stripeChargeId: response.data.stripeChargeId,
            kitCost: response.data.kitCost,
            orderId: response.data.orderId,
            totalPaidAmount: response.data.totalPaidAmount,
            totalRefundedAmount: response.data.totalRefundedAmount,
            orderedKitNumber: response.data.orderedKitNumber,
            message: response.data.message
          }

          if (response.data.kitStatus === kitStatus.REFUNDED || response.data.totalPaidAmount <= 0) {
            this.apiSuccess = response.data.message
          } else {
            this.refundAmount = response.data.kitCost
          }
        })
        .catch(error => {
          this.apiError = error.response
            ? error.response.data.message
            : 'Error occurred while retrieving payment information'
        })
    },
    submitRefund: function () {
      const url = '/refund'
      httpClientService.getAPIHttpClient().post(url, {
        kitId: this.kit.id,
        stripeChargeId: this.refundData.stripeChargeId,
        refundAmount: this.refundAmount
      })
        .then(response => {
          this.apiSuccess = `Refund requested for amount $ ${response.data.refundedAmount}.`
        })
        .catch(error => {
          this.apiError = error.response
            ? error.response.data.message
            : 'Error occurred while requesting the refund'
        })
    }
  }
}
</script>

<template>
  <section class="refund-kit">
    <b-card-group deck>
      <b-card header="Refund Form">
        <b-card-text>
          <!-- Information block -->
          <div class="row">
            <div class="col-5">Owned by:</div>
            <div class="col-7">
              {{ kit.ownedBy }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Kit Id:</div>
            <div class="col-7">
              {{ kit.id }}
            </div>
          </div>
          <div class="row">
            <div class="col-5">Sample Id:</div>
            <div class="col-7">
              {{ kit.sampleIdentifier }}
            </div>
          </div>
          <div v-if="refundData" class="row">
            <div class="col-5">Order Id:</div>
            <div class="col-7">
              {{ refundData.orderId }}
            </div>
          </div>
          <div v-if="refundData" class="row">
            <div class="col-5">Payment reference:</div>
            <div class="col-7">
              {{ refundData.stripeChargeId }}
            </div>
          </div>
          <div v-if="refundData" class="row">
            <div class="col-5">Number of kits:</div>
            <div class="col-7">
              {{ refundData.orderedKitNumber }}
            </div>
          </div>
          <div v-if="refundData" class="row">
            <div class="col-5">Average kit cost:</div>
            <div class="col-7">$ {{ refundData.kitCost }}</div>
          </div>
          <div v-if="refundData" class="row">
            <div class="col-5">Total paid for the order:</div>
            <div class="col-7">$ {{ refundData.totalPaidAmount }}</div>
          </div>
          <div v-if="refundData" class="row">
            <div class="col-5">Total refunded for the order:</div>
            <div class="col-7">$ {{ refundData.totalRefundedAmount }}</div>
          </div>

          <div v-if="apiError" class="api-error-container">
            Error: {{ apiError }}
          </div>

          <div v-else-if="apiSuccess" class="api-success-container">
            {{ apiSuccess }}
          </div>

          <!-- Input form -->
          <div v-else>
            <div class="row mt-3">
              <div class="col-9">
                <input
                  id="refundAmount"
                  v-model="refundAmount"
                  v-validate="
                    `required|decimal:2|min_value:0.01|max_value:${maxToRefund}`
                  "
                  type="number"
                  step="0.01"
                  name="refundAmount"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('refundAmount') }"
                  placeholder="Amount to refund"
                />
                <p v-if="errors.any()" class="error-text">
                  {{ errors.items[0].msg }}
                </p>
                <p
                  v-if="errors.items[0] && errors.items[0].rule === 'max_value'"
                  class="error-text"
                >
                  The refundAmount is more than the refundable credit for the
                  order
                </p>
              </div>
              <div class="col-3 text-center">
                <b-button
                  :disabled="errors.any()"
                  variant="primary"
                  @click="checkRefundAmount"
                >
                  Refund
                </b-button>
              </div>
            </div>
          </div>
        </b-card-text>
      </b-card>
    </b-card-group>
  </section>
</template>

<style lang="scss" scoped>
.refund-kit {
  width: 600px;
  max-width: 100%;
}
.error-text {
  margin-top: 10px;
  margin-left: 10px;
  color: #dc3545;
}
.api-success-container {
  margin-top: 10px;
  color: forestgreen;
}
.api-error-container {
  margin-top: 10px;
  color: #dc3545;
}
</style>
