const printers = Object.freeze({
  A4: 'A4',
  LABEL: 'LABEL',
  ADDRESS: 'ADDRESS',
  BRADY: 'BRADY'
})

const printerOptions = Object.freeze({
  A4: null,
  LABEL: null,
  ADDRESS: {
    size: { width: 4, height: 6 },
    margins: { top: 0, right: 0, bottom: 0, left: 0.07 },
    units: 'in',
    density: '203'
  },
  BRADY: {
    size: { width: 1, height: 0.375 },
    units: 'in',
    density: '203'
  }
})

const printGenre = Object.freeze({
  AUSPOST_MANIFEST: 'AUSPOST_MANIFEST',
  AUSPOST_ADDRESS_LABELS: 'AUSPOST_ADDRESS_LABELS',
  ACTIVATION_CODE: 'ACTIVATION_CODE',
  VOUCHER_CODE: 'VOUCHER_CODE',
  KIT_OWNER: 'KIT_OWNER',
  BATCH_LOT: 'BATCH_LOT',
  SYNLAB_IDENTIFIER: 'SYNLAB_IDENTIFIER',
  ORBIT_M_KIT_ACTIVATION: 'ORBIT_M_KIT_ACTIVATION',
  ORBIT_M_KIT_REPLACEMENT: 'ORBIT_M_KIT_REPLACEMENT',
  ORBIT_M_K2EDTA_TUBE: 'ORBIT_M_K2EDTA_TUBE',
  ORBIT_M_NAHEPARIN_TUBE: 'ORBIT_M_NAHEPARIN_TUBE',
  ORBIT_M_BLANK_LABEL: 'ORBIT_M_BLANK_LABEL',
  ORBIT_M_KIT_HASHED_ID_ONLY: 'ORBIT_M_KIT_HASHED_ID_ONLY',
  COBIOME_ACTIVATION_QR: 'COBIOME_ACTIVATION_QR',
  SAMPLE_IDENTIFIER: 'SAMPLE_IDENTIFIER'
})

export { printers, printerOptions, printGenre }
