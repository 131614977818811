<template>
  <div class="timelineComponent">
    <div v-if="!kitId">
      <h2 class="text-info">
        <i
          class="fa fa-exclamation-circle"
          aria-hidden="true"
        /> Timeline is not available for aliquoted samples
      </h2>
    </div>
    <div v-else-if="failedToLoadData">
      <h2 class="text-danger">
        <i
          class="fa fa-exclamation-circle"
          aria-hidden="true"
        /> Failed to load
        timeline data.
      </h2>
    </div>
    <div v-else>
      <h2 class="text-center">
        Kit Timeline
      </h2>
      <div class="timeline">
        <div
          v-for="(info, index) in timelineOrderByDate"
          :key="index"
          class="container"
          :class="index % 2 === 0 ? 'left' : 'right'"
        >
          <div class="content">
            <h3>
              <i
                class="fa fa-calendar"
                aria-hidden="true"
              />
              {{ info.dateTime | formatDate() }}
            </h3>
            <p>
              <span class="status">{{ getStatusLabel(info.eventType) }}:</span>
              {{ getStatusDescription(info.eventType) }}
            </p>
            <p class="itemNumber">
              {{ index + 1 }} of {{ timelineData.length }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateMixin from '@/mixins/date'

export default {
  name: 'KitTimeline',
  mixins: [dateMixin],
  props: {
    kitId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      failedToLoadData: false,
      timelineData: [],
      kitSampleStatusesData: []
    }
  },
  computed: {
    kitSampleStatuses () {
      let kitSampleStatuses = {}
      this.kitSampleStatusesData.forEach(status => {
        kitSampleStatuses[status.name] = status
      })
      return kitSampleStatuses
    },
    timelineOrderByDate () {
      const timelineData = [...this.timelineData]
      const timelineOrderByDate = timelineData.sort((dataA, dataB) => {
        return new Date(dataA.dateTime) - new Date(dataB.dateTime)
      })
      return timelineOrderByDate
    }
  },
  watch: {
    kitId: {
      handler () {
        if (this.kitId) {
          this.fetchData()
        }
      },
      immediate: true
    }
  },
  methods: {
    getStatusLabel (status = null) {
      return this.kitSampleStatuses[status]?.label || 'Status label missing.'
    },
    getStatusDescription (status = null) {
      return this.kitSampleStatuses[status]?.description || 'Status details missing.'
    },
    getKitSampleStatusesData () {
      this.$store
        .dispatch('getKitSampleStatuses')
        .then((response) => {
          this.kitSampleStatusesData = response?.data
        })
        .catch((error) => {
          throw new Error(error)
        })
    },
    getKitTimelineData () {
      this.$store
        .dispatch('getKitTimeline', this.kitId)
        .then((response) => {
          this.failedToLoadData = false
          this.timelineData = response?.data
        })
        .catch((error) => {
          this.failedToLoadData = true
          throw new Error(error)
        })
    },
    fetchData () {
      this.getKitTimelineData()
      this.getKitSampleStatusesData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../styles/global/_colours.scss';

/* The actual timeline (the vertical ruler) */
$timelineColor: $orange-bright;

.timelineComponent {
  margin-top: 3em;
}

.timeline {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  .status {
    font-weight: bold;
  }
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: $timelineColor;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  margin-left: unset;
  margin-right: unset;
}

/* The circles on the timeline */
.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -13px;
  background-color: white;
  border: 4px solid $timelineColor;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid #eeeeee;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #eeeeee;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: ' ';
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #eeeeee;
  border-width: 10px 10px 10px 0;
  border-color: transparent #eeeeee transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -13px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: #eeeeee;
  position: relative;
  border-radius: 6px;
}

.itemNumber {
  float: right;
  font-weight: bold;
  font-style: italic;
}
/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}
</style>
