<template>
  <div class="row">
    <div class="component component-progress-bar col-12">
      <div class="progressBar">
        <div
          class="progress"
          style="border-radius: 99em"
        >
          <div
            :class="className"
            role="progressbar"
            :style="{ width: percent + '%' }"
            :aria-valuenow="percent"
            aria-valuemin="0"
            aria-valuemax="percent"
          />
        </div>
        <div
          v-if="tip"
          class="tip"
        >
          <span class="tip-title">{{ formattedTip }}</span>
          <span class="tip-date">{{ date }}</span>
        </div>
      </div>
      <div
        v-if="tick"
        class="tick"
      >
        <span
          v-if="percent === 100"
          class="icon-check"
        />
      </div>
    </div>
    <div
      v-if="enableQuestionnaireTransfer && percent > 0"
      class="col-12"
    >
      <transfer-questionnaire
        :survey-type="tip"
        :kits="kits"
        :source-kit-id="kitId"
        @questionnaire-copied="$emit('questionnaire-copied')"
      />
    </div>
  </div>
</template>

<script>
import TransferQuestionnaire from '@/components/elements/TransferQuestionnaire'
import apiStringMappings from '@/mixins/apiStringMapping'
export default {
  name: 'ProgressBar',
  components: { TransferQuestionnaire },
  mixins: [apiStringMappings],
  props: {
    kitId: Number,
    total: Number,
    count: Number,
    tip: String,
    date: String,
    tick: {
      type: Boolean,
      default: true
    },
    enableQuestionnaireTransfer: { type: Boolean, default: false },
    kits: Array
  },
  computed: {
    formattedTip: function () {
      return this.sampleToString(this.tip)
    },
    percent: function () {
      return (Number(this.count) / Number(this.total)) * 100
    },
    className: function () {
      return this.percent === 100
        ? 'progress-bar bg-success'
        : 'progress-bar bg-info'
    }
  }
}
</script>

<style scoped lang="scss">
.component-progress-bar {
  display: flex;
  flex-basis: 100%;
  cursor: pointer;

  + .component-progress-bar {
    padding-top: 4px;
  }

  &:hover {
    .progress-bar.bg-success {
      background-color: #7bf9c0 !important;
    }

    .progress-bar.bg-info {
      background-color: #8ed9ff !important;
    }

    .tick {
      color: #7bf9c0;
    }
  }
}
.tick {
  flex-basis: 30px;
  overflow: hidden;
  text-align: right;
  color: #5cd69f;
}

.progressBar {
  flex: 1;
  position: relative;
  &:hover {
    .tip {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

.progress {
  border-radius: 99em;
  margin: 0;
  pointer-events: none;
  overflow: hidden;
  height: 15px;
}

.progress-bar {
  transition: 0.35s ease;
}

.tip {
  background: #778598;
  color: white;
  position: absolute;
  bottom: calc(100% + 2px);
  right: -3%;
  min-width: 63%;
  max-width: 100%;
  padding: 0.75rem 1.125rem;
  text-align: center;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);

  transition: all 0.3s;
  border-radius: 2px;
  pointer-events: none;

  opacity: 0;
  transform: translateY(20px);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #778598;
    display: block;
    margin-left: 0px;
    bottom: -7px;
  }

  .tip-title {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
    display: block;
    margin: 0 auto;

    + .tip-date {
      margin-top: 0.375rem;
    }
  }

  .tip-date {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 0.875rem;
    display: block;
    margin: 0 auto;
  }
}
</style>
