<template>
  <div>
    <b-dropdown
      id="toolbarDropdown"
      toggle-class="text-decoration-none"
      no-caret
      right
      size="sm"
      text="Dropdown Button"
    >
      <template #button-content>
        <font-awesome-icon :icon="['fas', 'fa-cog']" class="px-1" />
        <span class="sr-only">Search</span>
      </template>
      <b-dropdown-item @click="printSampleBarcode">
        <i class="icon-print pr-2" /> Print sample code
      </b-dropdown-item>
      <b-dropdown-item
        :disabled="!canInvalidateSample"
        @click="showInvalidateModal = true"
      >
        <font-awesome-icon :icon="['fas', 'fa-ban']" class="pr-2" />
        Invalidate sample
      </b-dropdown-item>
      <b-dropdown-item :disabled="!canAliquot" @click="openAliquot">
        <font-awesome-icon :icon="['fas', 'fa-vials']" class="pr-2" />
        Aliquot sample
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item :disabled="hideReProcessSample" @click="reProcessSample">
        <font-awesome-icon :icon="['fas', 'fa-recycle']" class="pr-2" />
        Re-process Results
      </b-dropdown-item>
      <b-dropdown-item :disabled="hideReProcessSample" @click="regeneratePdf">
        <font-awesome-icon :icon="['fas', 'fa-file-pdf']" class="pr-2" />
        Re-generate Report
      </b-dropdown-item>
      <b-dropdown-item :disabled="hideReProcessSample" @click="reQcPdf">
        <font-awesome-icon :icon="['fas', 'fa-file-pdf']" class="pr-2" />
        Re-QC Report
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canOpenReport"
        :disabled="hideReProcessSample"
        @click="openReport"
      >
        <font-awesome-icon :icon="['fas', 'fa-file-pdf']" class="pr-2" />
        View Report
      </b-dropdown-item>
      <b-dropdown-divider v-if="canReplaceKit" />
      <b-dropdown-item
        v-if="canReplaceKit"
        :disabled="isKitReplaced"
        @click="openReplacementModal"
      >
        <font-awesome-icon :icon="['fas', 'fa-arrow-right']" class="pr-2" />
        Replace Kit
      </b-dropdown-item>
    </b-dropdown>
    <ModalInvalidateSample
      v-show="showInvalidateModal"
      :sample-identifier="sampleIdentifier"
      :sample-status="status"
      :sample-notes="sampleNotes"
      :show="showInvalidateModal"
      @close="showInvalidateModal = false"
      @invalidation:failed="invalidationFailed"
      @invalidation:success="invalidationSuccess"
    />
    <ModalReplaceSample
      v-show="showReplacementModal"
      :show="showReplacementModal"
      :sample-identifier="sampleIdentifier"
      @close="showReplacementModal = false"
    />
  </div>
</template>

<script>
import qs from 'qs'

import printService from '@/services/printService'
import { printers, printGenre } from '@/enums/printers'
import { Confirm, Failure, Loader, Success, Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import NotificationQueueService from '@/services/notificationQueueService'
import queues from '@/enums/queues'
import {
  kitStatuses,
  sampleStatus,
  cannotReprocessSample
} from '@/enums/kitItemStatus'
import authService from '@/services/authService'
import httpClientService from '@/services/httpClientService'
import websocketMessageTypes from '@/enums/websocketMessageTypes'
import ModalInvalidateSample from '@/components/models/ModalInvalidateSample'
import ModalReplaceSample from '@/components/models/ModalReplaceSample'

export default {
  name: 'SampleToolbar',
  components: {
    ModalInvalidateSample,
    ModalReplaceSample
  },
  props: {
    distributorCode: {
      type: String,
      required: true
    },
    sampleExternalReference: {
      type: String,
      default: null
    },
    sampleIdentifier: {
      type: String,
      default: null,
      required: true
    },
    sampleStatus: {
      type: String,
      default: null,
      required: true
    },
    sampleNotes: {
      type: String,
      default: ''
    },
    productType: {
      type: String,
      default: null,
      required: false
    },
    productCode: {
      type: String,
      default: null,
      required: true
    },
    canAliquot: {
      type: Boolean,
      default: true
    },
    kitStatus: {
      type: String
    }
  },
  data() {
    return {
      showInvalidateModal: false,
      showReplacementModal: false,
      status: this.sampleStatus,
      openReportByCode: {
        syn: this.openSynReport
      }
    }
  },
  computed: {
    isKitReplaced() {
      return this.kitStatus === kitStatuses.REPLACED
    },
    hideReProcessSample: function () {
      return false // cannotReprocessSample(this.status)
    },
    canInvalidateSample: function () {
      return this.status !== sampleStatus.INVALIDATED
    },
    canOpenReport() {
      return typeof this.openReportByCode[this.distributorCode] === 'function'
    },
    canReplaceKit: function () {
      const roles = authService.getRoles()
      const validRoles = ['ROLE_ADMIN', 'STAFF_CUSTOMER_SUPPORT']
      return roles.some((item) => validRoles.includes(item))
    }
  },
  watch: {
    sampleIdentifier(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.status = this.sampleStatus
      }
    }
  },
  methods: {
    invalidationFailed() {
      this.showInvalidateModal = true
    },
    openReplacementModal() {
      this.showReplacementModal = true
    },
    invalidationSuccess() {
      this.status = sampleStatus.INVALIDATED
      this.$emit('sampleData:updated')
    },
    openAliquot() {
      this.$router.push({
        name: 'aliquot',
        params: {
          sampleIdentifier: this.sampleIdentifier
        }
      })
    },
    printSampleBarcode() {
      Confirm({
        title: `Print QR code for ${this.sampleIdentifier}?`
      }).then((result) => {
        if (result.value) {
          printService.printLabels(
            this.sampleIdentifier,
            printers.BRADY,
            printGenre.SYNLAB_IDENTIFIER
          )
        }
      })
    },
    reProcessSample: function () {
      Confirm({
        title: 'Re-process sample?',
        text: `This will re-submit sample ${this.sampleIdentifier} for processing.`,
        showCancelButton: true,
        confirmButtonText: 'Re-process',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {
          Loader.show({ text: 'Sending message to queue.' })
          const username = authService.getUsername()
          const payload = {
            sampleIdentifier: this.sampleIdentifier
          }
          const notificationQueue = new NotificationQueueService(
            queues.RESULT_ORCHESTRATOR
          )
          notificationQueue
            .sendMessage(payload, true, websocketMessageTypes.PRIVATE, username)
            .then(() => {
              Success({ title: 'Success!' })
            })
            .catch((error) => {
              Failure({
                text:
                  error?.response?.data?.message ||
                  'Something gone wrong, unable to send message to reprocess sample.'
              })
            })
        }
      })
    },
    regeneratePdf() {
      Confirm({
        title: 'Regenerate PDF',
        text: `<br>Regenerate the PDF for sample ${this.sampleIdentifier}?</br></br>
               This will NOT reprocess any data (e.g. questionnaires).`,
        showCancelButton: true,
        confirmButtonText: 'Regenerate',
        cancelButtonText: 'Cancel'
      }).then((userConfirmation) => {
        if (userConfirmation.value) {
          const payload = {
            sampleIdentifier: this.sampleIdentifier,
            product: this.productCode,
            fullGeneration: true,
            overwritePdf: true
          }
          const username = authService.getUsername()
          const notificationQueue = new NotificationQueueService(
            queues.PDF_GENERATOR
          )
          notificationQueue
            .sendMessage(payload, true, websocketMessageTypes.PRIVATE, username)
            .then(() => {
              Success({ title: 'Success!' })
            })
            .catch((error) => {
              Failure({
                text:
                  error?.response?.data?.message ||
                  'Something gone wrong, unable to send message to regenerate pdf.'
              })
            })
        }
      })
    },
    reQcPdf() {
      Confirm({
        title: 'Rerun PDF QC',
        text: `<br>Rerun the PDF QC for sample ${this.sampleIdentifier}?</br></br>
               This will NOT reprocess or regenerate any data/PDFs.`,
        showCancelButton: true,
        confirmButtonText: 'Rerun QC',
        cancelButtonText: 'Cancel'
      }).then((userConfirmation) => {
        if (userConfirmation.value) {
          const payload = {
            sampleIdentifier: this.sampleIdentifier,
            mgdbVersion: 'mgdb_v2',
            rerunqc: 1
          }
          const username = authService.getUsername()
          const notificationQueue = new NotificationQueueService(
            queues.REPORT_QC_ORCHESTRATOR
          )
          notificationQueue
            .sendMessage(payload, true, websocketMessageTypes.PRIVATE, username)
            .then(() => {
              Success({ title: 'Success!' })
            })
            .catch((error) => {
              Failure({
                text:
                  error?.response?.data?.message ||
                  'Something gone wrong, unable to send message to rerun PDF QC.'
              })
            })
        }
      })
    },
    openReport() {
      if (!this.canOpenReport) return
      this.openReportByCode[this.distributorCode]()
    },
    async openSynReport() {
      try {
        const clientResponse = await httpClientService
          .synlabApiHttpClient()
          .post(
            `${process.env.VUE_APP_API_ROOT}/oauth/token`,
            qs.stringify({ grant_type: 'client_credentials' })
          )
        const reportResponse = await httpClientService
          .getAPIHttpClient(true, clientResponse.data?.access_token)
          .get(
            `${process.env.VUE_APP_API_ROOT}/distributor/sample/${this.sampleExternalReference}/token`
          )
        window.open(
          `${process.env.VUE_APP_SYNLAB_REPORT_URL}es/${reportResponse.data?.access_token}`
        )
      } catch (error) {
        Notification({
          title: 'Failed',
          text: error.response.data.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    }
  }
}
</script>
