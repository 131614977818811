const dispatchTypes = Object.freeze({
  REGULAR: 'REGULAR',
  WHITE_LABEL: 'WHITE_LABEL',
  DISTRIBUTOR: 'DISTRIBUTOR',
  RESEARCH_MICROBA_MANAGED: 'RESEARCH_MICROBA_MANAGED',
  RESEARCH_CLIENT_MANAGED: 'RESEARCH_CLIENT_MANAGED',
  ORBIT_M: 'ORBIT_M'
})

const dispatchTypeLabels = Object.freeze({
  REGULAR: 'Product Express Post',
  WHITE_LABEL: 'Metabiome',
  DISTRIBUTOR: 'Metabiome Referrals',
  RESEARCH_MICROBA_MANAGED: 'Research Microba Managed',
  RESEARCH_CLIENT_MANAGED: 'Research Client Managed',
  ORBIT_M: 'ORBIT-M'
})

export { dispatchTypes, dispatchTypeLabels }
