<script>
import { dispatchTypes } from '@/enums/dispatchTypes'
import DispatchUI from '@/components/dispatch/DispatchUi'
import { Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import printService from '@/services/printService'
import { printers, printGenre } from '@/enums/printers'
import SampleService from '@/services/sampleService'
import { KIT_SAMPLE_BUNDLE, KIT_LABELS, KIT_TYPES } from '@/enums/kitSampleTypes'
const RESEARCH_PROJECT_ID = process.env.VUE_APP_ORBIT_M_PROJECT_ID

export default {
  name: 'OrbitMAssembly',
  components: {
    DispatchUI
  },
  data () {
    return {
      dispatchType: dispatchTypes.ORBIT_M,
      samplesInNextKitPack: [],
      kit: undefined,
      kitType: null,
      allowedKitTypes: [KIT_TYPES.ORBITM_B_KIT, KIT_TYPES.ORBITM_S_KIT, KIT_TYPES.ORBITM_R_KIT],
      expiryDate: null
    }
  },
  computed: {
    kitTypes () {
      let kitTypes = []
      this.allowedKitTypes?.forEach(kitType => {
        kitTypes.push({
          text: KIT_LABELS[kitType],
          value: kitType
        })
      })
      return kitTypes
    },
    kitTypeLabel () {
      return KIT_LABELS[this.kitType]?.toLowerCase() || 'Orbit M kit'
    },
    noOfSamplesRequired () {
      if (!this.kitType) return undefined
      return KIT_SAMPLE_BUNDLE[this.kitType]?.numberOfSamplesIncluded
    },
    kitReadyToAssemble () {
      return (
        this.kitType &&
        this.samplesInNextKitPack.length === this.noOfSamplesRequired
      )
    }
  },
  methods: {
    async addSampleToNextKitPack (payload) {
      try {
        const sampleIdentifier = payload.code
        const {
          data: sampleDetails
        } = await SampleService.getSampleByIdentifier(sampleIdentifier)
        if (
          !this.samplesInNextKitPack?.find(
            (sample) => sample.sampleIdentifier === sampleIdentifier
          )
        ) {
          this.samplesInNextKitPack.push(sampleDetails)
        }
      } catch (error) {
        Notification({
          text: error?.response?.data?.message || error?.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    async removeSampleFromNextKitPack (sampleIdentifier) {
      const { isConfirmed } = await Notification({
        text: `Do you want to remove sample ${sampleIdentifier}?`,
        style: NOTIFICATION_STYLES.QUESTION
      })
      if (isConfirmed) {
        this.samplesInNextKitPack = this.samplesInNextKitPack.filter(
          (sample) => sample.sampleIdentifier !== sampleIdentifier
        )
      }
    },
    async triggerKitAssembly () {
      const { isConfirmed } = await Notification({
        text: `Do you want to assemble kit?`,
        style: NOTIFICATION_STYLES.QUESTION
      })
      if (isConfirmed) {
        try {
          const { data } = await this.$store.dispatch('postKitBundling', {
            kitType: this.kitType,
            researchProjectId: RESEARCH_PROJECT_ID,
            sampleIdentifiers: this.samplesInNextKitPack?.map(
              (sample) => sample.sampleIdentifier
            )
          })
          Notification({
            text: 'Kit bundled successfully.',
            style: NOTIFICATION_STYLES.SUCCESS
          })
          this.kit = data
          this.samplesInNextKitPack = []
        } catch (error) {
          Notification({
            text: `Failed to assemble kit. <br> ${error?.response?.data?.message}`,
            style: NOTIFICATION_STYLES.ERROR
          })
        }
      }
    },
    printKitReplacementLabel () {
      const hashedId = this.kit?.hashedId
      const urlLink = `${process.env.VUE_APP_ORBIT_M_KIT_REPLACEMENT_URL}/${hashedId}`
      const data = {
        expiryDate: this.expiryDate,
        hashedId,
        urlLink
      }
      printService.printLabels(
        data,
        printers.LABEL,
        printGenre.ORBIT_M_KIT_REPLACEMENT
      )
    },
    printKitLabel () {
      const hashedId = this.kit?.hashedId
      const urlLink = `${process.env.VUE_APP_ORBIT_M_KIT_REQUISITION_URL}/${hashedId}`
      const data = {
        expiryDate: this.expiryDate,
        hashedId,
        urlLink
      }
      const printLabelGenre = this.kitType === KIT_TYPES.ORBITM_B_KIT ? printGenre.ORBIT_M_KIT_ACTIVATION : printGenre.ORBIT_M_KIT_HASHED_ID_ONLY
      printService.printLabels(
        data,
        printers.LABEL,
        printLabelGenre
      )
    },
    printBlankLabel () {
      printService.printLabels({expiryDate: this.expiryDate}, printers.LABEL, printGenre.ORBIT_M_BLANK_LABEL)
    },
    prepareNextKit () {
      this.kit = null
    }
  }
}
</script>
<template>
  <div class="page container">
    <transition name="slide-fade" />
    <div>
      <header>
        <h1 class="page-header">
          Orbit-M Kit Assembly
        </h1>
      </header>
      <DispatchUI
        :is-dispatch="false"
        :scan-disabled="!kitType"
        :dispatch-type="dispatchType"
        @code-scanned="addSampleToNextKitPack"
      />
      <div class="box box-shadow">
        <b-jumbotron
          :header="`${kitTypeLabel} Assembly`"
          :lead="
            !kit
              ? 'Select kit type & scan tubes to assemble kit.'
              : 'Print required labels when done, click `Prepare Next Kit`.'
          "
        >
          <div>
            <hr>
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Select Kit Type"
            >
              <b-form-radio-group
                id="kitType"
                v-model="kitType"
                :options="kitTypes"
                :aria-describedby="ariaDescribedby"
                :disabled="Boolean(kit)"
                name="radio-options"
              />
            </b-form-group>
          </div>
          <div v-if="samplesInNextKitPack.length || kit">
            <ul>
              <li
                v-for="sample in samplesInNextKitPack"
                :key="`samples-in-kit-pack-${sample.sampleIdentifier}`"
              >
                <h3>
                  {{ sample.sampleIdentifier }} ({{ sample.sampleMetadata.consumableType }})
                  <a
                    href="#"
                    @click.prevent="
                      removeSampleFromNextKitPack(sample.sampleIdentifier)
                    "
                  >
                    <i
                      class="fa fa-trash-alt text-danger"
                      aria-hidden="true"
                    />
                  </a>
                </h3>
              </li>
            </ul>
            <div v-if="kitReadyToAssemble">
              <b-button
                variant="primary"
                href="#"
                class="mx-1 pull-right"
                @click="triggerKitAssembly"
              >
                Assemble Kit
              </b-button>
            </div>
            <div
              v-if="kit"
              class="form-group"
            >
              <label for="expiryDate">Expiry Date</label>
              <input
                id="expiryDate"
                v-model="expiryDate"
                v-validate.disable="'required'"
                type="month"
                name="Expiry Date"
                class="form-control"
                placeholder="Expiry Date"
                :class="{ 'is-invalid': errors.first('Expiry Date') }"
              >
              <div class="invalid-feedback">
                {{ errors.first('Expiry Date') }}
              </div>
            </div>
            <div v-if="kit && expiryDate">
              <hr class="mt-5">
              <p>
                <span class="display-4">Kit Hashed ID: {{ kit.hashedId }}</span>
              </p>
              <hr class="mb-5">
              <b-button
                variant="primary"
                href="#"
                class="mx-1 pull-right"
                @click="prepareNextKit"
              >
                Prepare Next Kit
              </b-button>
              <b-button
                variant="primary"
                href="#"
                class="mx-1 pull-right"
                @click="printBlankLabel"
              >
                Print Blank Label
              </b-button>
              <b-button
                variant="primary"
                href="#"
                class="mx-1 pull-right"
                @click="printKitReplacementLabel"
              >
                Print Kit Replacement Code
              </b-button>
              <b-button
                variant="primary"
                href="#"
                class="mx-1 pull-right"
                @click="printKitLabel"
              >
                Print Kit Label
              </b-button>
            </div>
          </div>
        </b-jumbotron>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.jumbotron {
  .display-3 {
    text-transform: capitalize;
  }
}
</style>
