export const biAuthorisedUsers = [
  'blake.wills@microba.com',
  'james.heath@microba.com',
  'luke.holtham@microba.com',
  'drew.webb@microba.com',
  'Charlotte.Nolan@microba.com',
  'kelly.bingham@microba.com',
  'nicola.angel@microba.com',
  'danny.bosch@microba.com'
]
