<template>
  <div
    class="qc-filter"
    :class="`qc-filter-${buttonSize}`"
  >
    <div
      class="qc-filter-btn border border-success"
      :class="{
        'bg-success': successVisible,
        'text-success': !successVisible
      }"
      @click="$emit('success-toggle', !successVisible)"
    >
      {{ successName }}: {{ successCount }}
    </div>
    <div
      class="qc-filter-btn border border-primary"
      :class="{
        'bg-primary': warningVisible,
        'text-primary': !warningVisible
      }"
      @click="$emit('warning-toggle', !warningVisible)"
    >
      {{ warningName }}: {{ warningCount }}
    </div>
    <div
      class="qc-filter-btn border border-danger"
      :class="{
        'bg-danger': errorVisible,
        'text-danger': !errorVisible
      }"
      @click="$emit('error-toggle', !errorVisible)"
    >
      {{ errorName }}: {{ errorCount }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'QCFilter',
  props: {
    buttonSize: {
      type: String,
      default: 'lg',
      validator: (size) => {
        return ['sm', 'lg'].includes(size)
      }
    },
    successCount: {
      type: Number,
      default: 0
    },
    successVisible: {
      type: Boolean,
      default: true
    },
    successName: {
      type: String,
      default: 'Passed'
    },
    warningCount: {
      type: Number,
      default: 0
    },
    warningVisible: {
      type: Boolean,
      default: true
    },
    warningName: {
      type: String,
      default: 'Warned'
    },
    errorCount: {
      type: Number,
      default: 0
    },
    errorVisible: {
      type: Boolean,
      default: true
    },
    errorName: {
      type: String,
      default: 'Failed'
    }
  }
}
</script>
<style scoped lang="scss">
.qc-filter {
  display: flex;
  margin: 5px -5px;
  .qc-filter-btn {
    border-radius: 4px;
    font-weight: bold;
    color: white;
    justify-content: center;
    align-content: center;
    flex: 1;
    cursor: pointer;
    display: inline-flex;
    padding: 10px;
    margin: 0 5px;
  }
  &.qc-filter-sm {
    .qc-filter-btn {
      padding: 5px;
    }
  }
}
</style>
