<template>
  <div v-if="canTransfer">
    <div class="row">
      <div class="col-12 py-2">
        {{ surveyLabel }}
      </div>
      <div class="col-sm-12 col-lg-8">
        <div class="form-group-select">
          <vSelect
            v-model="destinationKit"
            v-validate="'required'"
            name="Kit"
            :class="{ 'is-invalid': errors.has('Kit') }"
            :options="copyToOptions"
            placeholder="Destination Kit*"
            label="label"
            :state="errors.has('Kit') ? false : null"
          />
          <div
            v-if="errors.has('Kit')"
            class="invalid-feedback"
            :class="{ 'd-block': errors.has('Kit') }"
          >
            {{ errors.first('Kit') }}
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-lg-4">
        <btVueButton
          btn-class="btn btn-primary btn-sm copy"
          btn-value="Copy"
          :btn-final-state="
            sucessfullyCopied ? 'success' : failedToCopy ? 'failure' : ''
          "
          @clicked="copyQuestionnaire()"
        />
      </div>
      <b-alert
        class="col-sm-12 col-lg-10 ml-lg-3 my-2"
        :show="sucessfullyCopied || failedToCopy"
        :variant="copyStatus"
      >
        {{ copyResponse }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import btVueButton from 'bt-vue-button/dist/bt-vue-button.common'
import surveyTypes from '@/enums/surveyTypes'
import vSelect from 'vue-select'
import apiStringMappings from '@/mixins/apiStringMapping'

export default {
  name: 'TransferQuestionnaire',
  components: {
    vSelect,
    btVueButton
  },
  mixins: [apiStringMappings],
  props: {
    surveyType: String,
    kits: Array,
    sourceKitId: Number
  },
  data () {
    return {
      destinationKit: null,
      sucessfullyCopied: null,
      failedToCopy: null
    }
  },
  computed: {
    canTransfer: function () {
      return this.surveyType !== surveyTypes.DAY_OF_SAMPLING
    },
    copyToOptions: function () {
      const options = []
      let kits = this.kits
      kits.sort((a, b) => a.id - b.id)
      if (kits) {
        kits.forEach((element) => {
          const optionEntry = {
            value: element.id,
            label: `${element.id} - ${element.ownedBy}`
          }
          options.push(optionEntry)
        })
      }
      return options
    },
    surveyLabel () {
      return `Copy ${this.sampleToString(this.surveyType)} qestionnaires`
    },
    copyStatus () {
      return this.sucessfullyCopied
        ? 'success'
        : this.failedToCopy
          ? 'danger'
          : ''
    },
    copyResponse () {
      return this.sucessfullyCopied
        ? 'Questionnaire copied.'
        : this.failedToCopy
          ? 'Failed, please try again.'
          : ''
    }
  },
  methods: {
    copyQuestionnaire () {
      this.$validator.validate().then(() => {
        if (!this.errors.any()) {
          const payload = {
            sourceKitId: this.sourceKitId,
            surveyType: this.sampleToSlug(this.surveyType),
            destinationKitId: this.destinationKit.value
          }
          this.sucessfullyCopied = null
          this.failedToCopy = null
          this.$store
            .dispatch('postCopyQuestionnaires', payload)
            .then((ok) => {
              this.sucessfullyCopied = true
              this.$emit('questionnaire-copied')
            })
            .catch(() => {
              this.failedToCopy = true
            })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.kit-item {
  .copy {
    width: auto !important;
    padding: 5px 15px;
  }
}
</style>
