import store from '@/stores'

export default class BaseService {
  static async getById (id, storeAction, payload) {
    if (!id) throw new Error('Missing id.')

    return store.dispatch(storeAction, payload)
  }

  static async updateById (id, storeAction, payload) {
    if (!id) throw new Error('Missing id.')
    payload.id = id
    return store.dispatch(storeAction, payload)
  }

  static async callStore (storeAction, payload) {
    return store.dispatch(storeAction, payload)
  }
}
