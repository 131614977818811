<template>
  <div>
    <label
      class="btn btn-file-dynamic btn-outline-primary"
      :class="className"
    >
      <span
        v-if="!success"
        class="text"
      >{{ text }}</span>
      <input
        ref="file"
        type="file"
        name="file"
        style="display: none"
        @change="handleFileUpload()"
      >
      <transition name="up">
        <span
          v-if="success"
          class="oi oi-check"
        />
      </transition>
    </label>
    <span
      v-if="error"
      class="error"
    >{{ errorMessage }}</span>
  </div>
</template>

<script>
export default {
  name: 'FileUploadButton',
  props: {
    text: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className: function () {
      if (this.error === true) return 'btn-danger'
      else if (this.success === true) return 'btn-success'
      else if (this.loading === true) return 'btn-outline-primary btn-loading'
      return 'btn-outline-primary'
    }
  },
  watch: {
    loading: function (val) {
      if (val === true) {
        this.error = false
        this.success = false
      }
    },
    error: function (val) {
      if (val === true) {
        this.loading = false
        this.success = false
      }
    },
    success: function (val) {
      if (val === true) {
        this.loading = false
        this.error = false
      }
    }
  },
  methods: {
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
      let formData = new FormData()
      formData.append('file', this.file)
      console.log('file', formData, this.file)
      this.$emit('onChange', formData)
    }
  }
}
</script>

<style scoped lang="scss">
.error {
  color: red;
}
label.btn-dynamic {
  background: transparent !important;
}
.btn-file-dynamic {
  transition: all 0.1s;
  outline: none;
  overflow: hidden;
  min-width: 150px;
  position: relative;
  height: 42px;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  z-index: 2;
  width: 100%;
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before {
    content: '';
    transition: all 0.1s;
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    border: 1px solid #ff8708;
    border-radius: inherit;
    transition: all 0.2s ease;
    z-index: -1;
  }

  &:after {
    content: '';
    width: 0%;
    background: #ff8708;
    height: 100%;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    position: absolute;
    border-radius: inherit;
    transition: all 0.2s ease;
  }

  &:hover {
    &:before {
      background: #e17400;
    }
  }

  @media (min-width: 768px) {
    max-width: 200px;
  }
}

.btn-loading {
  &:before {
    background-color: #ffb261 !important;
    height: 20px;
    transition: height 0.2s ease;
  }

  &:after {
    height: 20px;
    width: 97%;
    transition: height 0.2s ease,
      width 3s cubic-bezier(0.25, 1.13, 0.85, 0.99) 0.2s;
  }
}

.btn-success {
  color: white;
  border: 1px solid #5cd69f !important;
  &:before {
    background-color: #52cf97 !important;
    border: 1px solid #5cd69f !important;
  }
}

.btn-error,
.btn-danger {
  color: white;
  &:before {
    background-color: #ff4d4d !important;
    border: 1px solid #ff4d4d !important;
  }
}

.btn-success,
.btn-error,
.btn-danger {
  padding: 0.625rem 2rem !important;
}

.oi {
  transition: all 0.3s;
  transform: translateY(0px);
  height: 20px;
}

.up-enter-active {
  transition: all 0.3s ease;
}

.up-enter,
.up-leave-to {
  transform: translateY(40px) rotate(55deg);
  opacity: 0;
}

.up-active,
.up-leave-active {
  transition: all 0.3s;
}
.up-enter,
.up-leave-to {
  opacity: transformY(40px);
}

.up-enter-active {
  transition: all 0.3s ease;
}

.up-enter,
.up-leave-to {
  transform: translateY(40px) rotate(55deg);
  opacity: 0;
}

.up-active,
.up-leave-active {
  transition: all 0.3s;
}
.up-enter,
.up-leave-to {
  opacity: transformY(40px);
}
</style>
