import moment from 'moment'
import store from '@/stores/index.js'
import {
  Confirm,
  TokenService,
  AuthService,
  USER_TYPE
} from '@microbadevs/library'

const TOKEN_EVENTS = {
  REFRESH_TOKEN_EXPIRY_NOTICE: 'REFRESH_TOKEN_EXPIRY_NOTICE',
  ACCESS_TOKEN_EXPIRY_NOTICE: 'ACCESS_TOKEN_EXPIRY_NOTICE'
}
const alertNSecondsBeforeRefreshTokenExpire = 1800
const checkEveryNSeconds = 60
const ignore2Fa = localStorage.getItem('ignore_2fa')

export function responseErrorHandler (error) {
  if (error.response?.status === 503) {
    store.dispatch('maintenance/show', error.response?.data)
  }
}

const authService = {
  async isUserEnabling2FA() {
    const { accessToken, refreshToken } = TokenService.getTokenDetails()
    const authService = new AuthService({ accessToken, refreshToken, responseErrorHandler })
    const is2FAEnabled = await authService.is2FAEnabled(USER_TYPE.USER)

    if (ignore2Fa) {
      return false
    }

    if (process.env.VUE_APP_IS_MFA_ENABLED === 'true' && !is2FAEnabled) {
      return true
    }

    return false
  },

  is2FARequired(error) {
    const isForbidden = error?.response?.status === 403
    const mfaToken = error?.response?.data?.mfa_token
    return isForbidden && mfaToken
  },

  initialize(accessToken, refreshToken) {
    const tokenService = new TokenService(accessToken, refreshToken)
    tokenService.setupRefreshTokenExpiryAlert(
      alertNSecondsBeforeRefreshTokenExpire,
      checkEveryNSeconds
    )

    addEventListener(
      TOKEN_EVENTS.REFRESH_TOKEN_EXPIRY_NOTICE,
      showRefreshTokenExpiryNotice
    )
    addEventListener(
      TOKEN_EVENTS.ACCESS_TOKEN_EXPIRY_NOTICE,
      triggerAccessTokenRenewal
    )
  },

  getUserID() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.userId
  },

  getUserEmail() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.userEmail
  },

  getUsername() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.userName
  },

  getRoles() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.roles
  },

  getAccessToken() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.accessToken
  },

  getAccessTokenExpiration() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.accessTokenExpiry
  },

  getRefreshToken() {
    const tokenDetails = TokenService.getTokenDetails()
    return tokenDetails?.refreshToken
  },

  tokenHasExpired() {
    const expiry = authService.getAccessTokenExpiration()
    return expiry && moment().unix() > expiry
  },

  isLoggedIn() {
    return !!authService.getAccessToken() && !this.tokenHasExpired()
  },

  logout() {
    TokenService.clearTokenDetails()
  }
}

function triggerAccessTokenRenewal() {
  store
    .dispatch('refreshAccessToken', {
      refreshToken: authService.getRefreshToken()
    })
    .then(({ data }) => {
      TokenService.updateTokenDetails(data?.access_token, data?.refresh_token)
    })
}

function showRefreshTokenExpiryNotice() {
  Confirm({
    title: 'Session is about to expire',
    text: `Your session is about to expire in next ${
      alertNSecondsBeforeRefreshTokenExpire / 60
    } minutes, do you want to start a new session?</b>`,
    type: 'warning',
    confirmButtonText: 'Yes Re-login'
  }).then((result) => {
    if (!result.value) {
      return
    }
    authService.logout()
    window.location.reload(false)
  })
}

export default authService
