import Hashids from 'hashids'

export default class activationCodeService {
  static decodeActivationCode (activationCode) {
    const activationCodeSalt =
      process.env.VUE_APP_ACTIVATION_CODE_SALT || `G1GM4KMDZCI1SQR`
    const activationCodeLength =
      process.env.VUE_APP_ACTIVATION_CODE_LENGTH || 10
    const activationCodeCharPool =
      process.env.VUE_APP_ACTIVATION_CODE_CHAR_POOL ||
      'ABCDFGHIJKLMNPQRSTUVWXYZ23456789'
    const acceptableChars = [
      'B',
      'C',
      'D',
      'F',
      'G',
      'H',
      'J',
      'K',
      'L',
      'M',
      'N',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'V',
      'W',
      'X',
      'Y',
      'Z'
    ]
    let sampleIdentifier = null
    if (!activationCodeSalt) {
      throw Error('Activation code salt missing.')
    }

    if (!activationCode) {
      throw Error('Activation code missing.')
    }
    var hashids = new Hashids(
      activationCodeSalt,
      activationCodeLength,
      activationCodeCharPool
    )
    const decodedCode = hashids.decode(activationCode)
    if (decodedCode.length === 4) {
      // decodedCode is array of 4 items, first three are characters from acceptableChars list and 4th is the number.
      // So below we re-creating sampleIdentifier by putting chars from acceptableChars and number together.
      sampleIdentifier = `${acceptableChars[decodedCode[0]]}${
        acceptableChars[decodedCode[1]]
      }${acceptableChars[decodedCode[2]]}${decodedCode[3]
        .toString()
        .padStart(4, 0)}`
    }
    return sampleIdentifier
  }
}
