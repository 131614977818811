<template>
  <div class="page page-profile">
    <div class="container">
      <transition name="slide-fade">
        <div v-if="user">
          <transition name="slide-fade">
            <div
              v-if="localError"
              class="alert alert-danger"
              role="alert"
            >
              {{ localError }}
            </div>
          </transition>

          <header>
            <h1 class="page-header">My Profile</h1>
          </header>

          <div class="box box-shadow">
            <h3 v-if="user.guardian">
              Please complete your child or dependant's details
            </h3>
            <h3 v-else>
              Personal details
            </h3>

            <div class="row">
              <div class="form-group col col-md-6">
                <label for="firstName">First name</label>
                <b-form-input
                  v-model="user.firstName"
                  v-validate="'required'"
                  type="text"
                  class=""
                  required
                  autocomplete="given-name"
                  maxlength="25"
                  name="first name"
                  id="firstName"
                  :state="errors.has('first name') ? false : null"
                />
                <div v-show="errors.has('first name')" class="invalid-feedback">
                  {{ errors.first('first name') }}
                </div>
              </div>

              <div class="form-group col col-md-6">
                <label for="lastName">Last name</label>
                <b-form-input
                  v-model="user.lastName"
                  v-validate="'required'"
                  type="text"
                  class=""
                  autocomplete="family-name"
                  required
                  maxlength="25"
                  id="lastName"
                  name="last name"
                  :state="errors.has('last name') ? false : null"
                />
                <div v-show="errors.has('last name')" class="invalid-feedback">
                  {{ errors.first('last name') }}
                </div>
              </div>

              <div class="form-group col-md-6">
                <label for="email">Email</label>
                <b-form-input
                  v-model="user.email"
                  v-validate="'required|email'"
                  class=""
                  type="email"
                  autocomplete="email"
                  maxlength="25"
                  id="email"
                  name="email"
                  :state="errors.has('email') ? false : null"
                  required
                  :disabled="true"
                />
                <div v-show="errors.has('email')" class="invalid-feedback">
                  {{ errors.first('email') }}
                </div>
              </div>

              <div class="form-group col-md-6">
                <label for="phone">Phone number</label>
                <b-form-input
                  v-model="user.phoneNumber"
                  v-validate="'required'"
                  class=""
                  type="tel"
                  maxlength="15"
                  autocomplete="tel"
                  required
                  id="phone"
                  name="phone"
                  :state="errors.has('phone') ? false : null"
                />
                <div v-show="errors.has('phone')" class="invalid-feedback">
                  {{ errors.first('phone') }}
                </div>
              </div>
            </div>
            <!--.row -->
          </div>
          <!--.box -->

          <div class="box box-shadow">
            <h3>Shipping Address</h3>

            <div class="row">
              <div class="form-group col-12 col-md-12">
                <label for="address-one">Address line 1</label>
                <b-form-input
                  v-model="user.addressLine1"
                  type="text"
                  autocomplete="address-line1"
                  id="address-one"
                  name="address1"
                  class="fs-hide"
                />
              </div>

              <div class="form-group col-12 col-md-12">
                <label for="address-two">Address line 2</label>
                <b-form-input
                  v-model="user.addressLine2"
                  type="text"
                  class="fs-hide"
                  id="address-two"
                  autocomplete="address-line2"
                />
              </div>

              <div class="form-group col-12 col-md-4">
                <label for="suburb">Suburb</label>
                <b-form-input
                  v-model="user.suburb"
                  type="text"
                  class="fs-hide"
                  id="suburb"
                  name="suburb"
                  autocomplete="locality"
                />
              </div>

              <div class="col-12 col-md-4">
                <label for="state">State</label>
                <select
                  v-model="user.state"
                  id="state"
                  name="state"
                  class="form-control"
                  :class="{ 'is-invalid': errors.has('state') }"
                >
                  <option value="ACT">ACT</option>
                  <option value="NSW">NSW</option>
                  <option value="NT">NT</option>
                  <option value="SA">SA</option>
                  <option value="TAS">TAS</option>
                  <option value="QLD">QLD</option>
                  <option value="VIC">VIC</option>
                  <option value="WA">WA</option>
                </select>
              </div>

              <div class="form-group col col-md-4">
                <label for="postcode">Post code</label>
                <b-form-input
                  v-model="user.postcode"
                  type="text"
                  id="postcode"
                  name="postcode"
                  autocomplete="shipping postal-code"
                />
              </div>
            </div>
            <!--.row -->
          </div>
          <!--.box -->

          <div
            class="box box-shadow box-password-reset"
            :class="{ 'box-collapse': collapsePassword }"
          >
            <div
              v-if="collapsePassword"
              v-b-toggle.resetPasswordBox
              class="box-header row"
            >
              <div class="col-8">
                <h3>I would like to change my password</h3>
              </div>
              <div class="col-4">
                <div class="box-toggle-icon change-password-toggle">
                  <i class="icon-chevron-bottom" />
                </div>
                <!--<div class="box-toggle-icon btn btn-outline-light change-password-toggle">Change password</div>-->
              </div>
            </div>

            <b-collapse
              id="resetPasswordBox"
              :visible="!collapsePassword"
              class="box-main"
            >
              <h3>Change Password</h3>

              <div class="form-row">
                <div class="form-group col-12 col-md-7">
                  <label for="current-password">Current Password</label>
                  <b-form-input
                    v-model="currentPassword"
                    type="password"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    id="current-password"
                  />
                  <div class="invalid-feedback">
                    <span v-if="!currentPasswordValid"
                      >Please enter your current password</span
                    >
                  </div>
                </div>

                <div class="form-group col-12 col-md-7">
                  <label for="new-password">New Password</label>
                  <b-form-input
                    v-model="newPassword"
                    type="password"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    :is-invalid="!isNewPasswordRequired"
                    id="new-password"
                  />                  
                  <div class="invalid-feedback">
                    <span v-if="!isNewPasswordRequired">Please type a new password</span>
                  </div>
                </div>

                <div class="form-group col-12 col-md-7">
                  <label for="new-password-confirm">Confirm New Password</label>
                  <b-form-input
                    v-model="newPasswordConfirm"
                    type="password"
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    :is-invalid="!validPassword"
                    id="new-password-confirm"
                  />

                  <div class="invalid-feedback">
                    <span v-if="!validPassword">Passwords do not match</span>
                  </div>
                </div>
              </div>
              <!--.box -->
            </b-collapse>
          </div>
          <div v-if="is2FAEnabled === true" class="box box-shadow">
            <div>
              <h2>Disable 2FA</h2>
              <p>
                This will remove 2FA from your account. You will be logged out
                automatically after removing it and will have to set up 2FA
                again when you next login.
              </p>
              <b-button variant="danger" @click="disable2FA">
                Disable
              </b-button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="text-center">
                <Button
                  class="user-details-button"
                  text="Save changes"
                  :saving="loading"
                  :success="buttonSuccess"
                  :error="error"
                  @onClick="onClickSave"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- if-user -->
      </transition>

      <Loader v-if="loading" />
    </div>
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader'
import Button from '@/components/elements/DynamicButton'
import userProfile from '@/mixins/userProfile'
import vSelect from 'vue-select'
import {
  Notification,
  NOTIFICATION_STYLES,
  USER_TYPE,
  AuthService,
  TokenService
} from '@microbadevs/library'
import { responseErrorHandler } from '@/services/authService'

export default {
  name: 'Profile',
  components: {
    Loader,
    Button,
    vSelect
  },
  mixins: [userProfile],
  data() {
    return {
      currentPassword: null,
      newPassword: null,
      newPasswordConfirm: null,
      buttonSuccess: false,
      showDataUsageModal: false,
      showDataUsageOptOutModal: false,
      collapsePassword: true,
      localError: null,
      parentError: null,
      originalUser: null, // Used to check if user is opting out/in
      skipMinorConsent: false,
      is2FAEnabled: null
    }
  },
  computed: {
    authServiceComputed() {
      const { accessToken, refreshToken } = TokenService.getTokenDetails()
      return new AuthService({ accessToken, refreshToken, responseErrorHandler })
    },
    user: function () {
      return this.$store.state.GET_USER_RESPONSE
    },
    loading: function () {
      return this.$store.state.GET_USER_PENDING
    },
    saving: function () {
      return this.$store.state.PUT_USER_PENDING
    },
    success: function () {
      return this.$store.state.PUT_USER_SUCCESS
    },
    error: function () {
      return this.$store.state.PUT_USER_ERROR
    },
    failure: function () {
      return !!this.$store.state.PUT_USER_FAILURE
    },
    validatedUser: function () {
      const newUser = this.user
      if (this.validPassword && this.currentPassword && this.newPassword) {
        newUser.password = this.currentPassword
        newUser.newPassword = this.newPassword
      }
      return newUser
    },
    validPassword: function () {
      return this.newPassword === this.newPasswordConfirm
    },
    isNewPasswordRequired: function(){
      if(this.currentPassword && !this.newPassword){
        return false
      }
      return true
    },
    currentPasswordValid: function () {
      if (
        (this.newPassword || this.newPasswordConfirm) &&
        !this.currentPassword
      ) {
        return false
      }
      return true
    },
    collapseDataConsent: function () {
      // make data consent collapsible if it has been answered previously
      return this.user.agreeToDataUsage != null && this.wasInvalid !== true
    },
    // if the dashboard.profileCompleted was false we assume they were redirected here so on save we redirect back to the dashboard
    wasInvalid: function () {
      return this.$store.state.GET_DASHBOARD_RESPONSE
        ? this.$store.state.GET_DASHBOARD_RESPONSE.profileCompleted === false
        : true
    },
    isOptingOut: function () {
      if (!this.user) return false
      return (
        this.user.agreeToDataUsage !== true &&
        this.originalUser.agreeToDataUsage === true
      )
    },
    dashboard: function () {
      return this.$store.state.GET_DASHBOARD_RESPONSE
    }
  },
  watch: {
    saving: function (val) {
      if (this.success === true) {
        this.$bus.$emit('enableSidebar')
      }
    },
    user: function () {
      if (this.originalUser === null) {
        this.originalUser = this.user
      }
    }
  },
  async mounted() {
    this.$store.dispatch('getUser')
    this.is2FAEnabled = await this.getIs2FAEnabled()
  },
  methods: {
    skipMinorConsentToggle () {
      this.skipMinorConsent = !this.skipMinorConsent
    },
    onParentalSuccess: function () {
      this.user.parentConsented = null
      this.parentError = null
    },
    onClickSave: function () {
      this.localError = null
      this.parentError = null

      this.$validator.validate().then(() => {
        if (this.errors.any()) {
          this.localError = 'Please fill out or correct the highlighted fields'
          scroll(0, 0)
          return
        }
        this.doSave()
      })
    },
    doSave: async function (event) {
      this.buttonSuccess = false
      await this.$store
        .dispatch('putUser', this.validatedUser)
        .then((success) => {
          if (this.wasInvalid && this.validPassword && this.isNewPasswordRequired) {
            this.buttonSuccess = true
            setTimeout(() => {
              this.buttonSuccess = false
              this.$router.push({name: 'Dashboard'})
            }, 2000)
          }
        })
        .catch((error) => {
          Notification({
            title: 'Something went wrong',
            text: error.response?.data?.message,
            style: NOTIFICATION_STYLES.ERROR
          })
        })
    },
    onDataUsageModalClose: function () {
      this.showDataUsageModal = false
    },
    onDataUsageOptOutModalClose: function () {
      this.showDataUsageOptOutModal = false
    },
    async disable2FA() {
      try {
        const userResponse = await Notification({
          title: 'Are you sure?',
          text: process.env.VUE_APP_IS_MFA_ENABLED === 'true'?
              'Would you like to disable your 2FA? <br/> You will be logged out and may have to set up 2FA again when you next login.': 
              'Would you like to disable your 2FA? <br/> You will be logged out upon confirming.',
          style: NOTIFICATION_STYLES.QUESTION
        })
        if (userResponse.isConfirmed) {
          await this.authServiceComputed.disable2FA(USER_TYPE.USER)
          TokenService.clearTokenDetails()
          this.$router.push({ name: 'Login' })
        }
      } catch (error) {
        Notification({
          title: 'Failed',
          text:
            error?.response?.data?.message ||
            error?.message ||
            'Failed to disable two factor authentication, please contact dev team',
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    async getIs2FAEnabled() {
      const res = await this.authServiceComputed.is2FAEnabled(USER_TYPE.USER)
      return res
    }
  }
}
</script>

<style scoped>
h1 {
  margin-bottom: 40px;
}

img {
  border-radius: 99em;
  margin: 0px auto 0;
  display: block;
  background: red;
  box-shadow: 0 9px 16px rgba(0, 0, 0, 0.15);
  border: 4px solid white;
}

.input-group {
  margin-bottom: 1.2rem;
}
.invalid-feedback {
  display: block !important;
}
.change-password-toggle {
  float: right;
}
.box-password-reset {
  padding-top: 1.5rem;
}
.box-password-reset h3 {
  margin: 0;
  line-height: 42px;
}
.box-main {
  padding-top: 2rem;
}

.change-password-toggle {
  margin-top: 12px;
}
</style>
