// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import { sync } from 'vuex-router-sync'

import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import VueJWT from 'vuejs-jwt'
import JsonExcel from 'vue-json-excel'
import VueMuuri from 'vue-muuri'
import * as Sentry from '@sentry/vue'
// eslint-disable-next-line import/no-unresolved
import Result from '@microbadevs/report'
import i18n from '@microbadevs/report/src/language-pack'
import VueI18n from 'vue-i18n'
import store from '@/stores/'
import Bus from '@/event'
import VueTelInput from 'vue-tel-input'
import 'vue-muuri/dist/vue-muuri.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas, fad, fal, far)

const SENTRY_AUTH_TOKEN = process.env.VUE_APP_SENTRY_AUTH_TOKEN
const APP_API_ROOT = process.env.VUE_APP_API_ROOT
const APP_HCP_JOURNEY_URL = process.env.VUE_APP_HCP_JOURNEY_URL
const APP_REPORT_SERVICE_URL = process.env.VUE_APP_REPORT_SERVICE_URL

if (SENTRY_AUTH_TOKEN) {
  Sentry.init({
    Vue,
    dsn:
      'https://0e7dfd9fc4464f0c9f8303ece32d3aa0@o287399.ingest.sentry.io/1527783',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    sampleRate: 0,
    tracesSampleRate: 0,
    tracePropagationTargets: [
      'localhost',
      new RegExp(`^${APP_API_ROOT}`),
      new RegExp(`^${APP_HCP_JOURNEY_URL}`),
      new RegExp(`^${APP_REPORT_SERVICE_URL}`)
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('downloadExcel', JsonExcel)
Vue.use(Bus)
Vue.use(BootstrapVue)
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' })
Vue.use(VueI18n)
Vue.use(Result)
Vue.use(VueJWT, {})
Vue.use(VueMuuri)
Vue.use(VueTelInput)

Vue.config.productionTip = false

Vue.prototype.$appName = 'Microba'

sync(store, router)

Vue.filter('percentage', function (value, decimals) {
  if (!value) value = 0
  if (!decimals) decimals = 0

  value = value * 100
  return (
    Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals) + '%'
  )
})
localStorage.setItem('API_ROOT', process.env.VUE_APP_API_ROOT)
/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  i18n,
  components: {
    App
  },
  template: '<App/>'
})
