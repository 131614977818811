import store from '@/stores'
import BaseService from '@/services/baseService'

export default class ResearchProjectService extends BaseService {
  static async getOrganisations () {
    const action = 'getResearchers'
    return super.callStore(action)
  }

  static async getProjectByType ({ type, term }) {
    if (!type || !term) throw new Error('Missing search term or type')
    const action = 'getProjectByType'
    return super.callStore(action, { type, term })
  }

  static async getSearchTypes () {
    const action = 'getSearchTypes'
    return super.callStore(action)
  }

  static async getProjectsByOrganisationId (id) {
    const action = 'getProjects'
    const payload = { id }
    return super.getById(id, action, payload)
  }

  static async getProjectByProjectId (id) {
    const action = 'getProject'
    const payload = { id }
    return super.getById(id, action, payload)
  }

  static async deleteResearchProjectById (id) {
    if (!id) throw new Error('Missing project id.')
    return store.dispatch('deleteProject', { id })
  }

  static async getSitesByProjectId (id) {
    if (!id) throw new Error('Missing project id')
    const action = 'getSitesByProjectId'
    return super.callStore(action, { id })
  }
}
