<template>
  <transition name="slide-fade">
    <div class="login">
      <div class="login-bg" />

      <main class="main">
        <img
          src="../assets/images/logo-full-mono-light.png"
          alt=""
        >
        <h1>Reset password</h1>

        <div
          v-if="token"
          class="box row"
        >
          <transition name="slide-fade">
            <div
              v-if="localError"
              class="alert alert-danger col"
              role="alert"
            >
              {{ localError }}
            </div>
          </transition>

          <transition name="slide-fade">
            <div
              v-if="serverError"
              class="alert alert-danger col"
              role="alert"
            >
              {{ serverError }}
            </div>
          </transition>

          <div
            class="form-group has-feedback has-feedback-left col-12"
            :class="{ 'has-danger': errors.has('password') }"
          >
            <label
              class="form-control-label hidden"
              for="password"
            >Password</label>
            <div class="input-icon">
              <span class="oi oi-lock-locked" />
            </div>
            <input
              id="password"
              v-model="password"
              v-validate="'required|min:8'"
              type="password"
              :class="{
                'is-invalid': errors.has('password') && veeFields.password.touched
              }"
              class="form-control"
              data-vv-as="password"
              name="password"
              placeholder="New Password"
            >
            <div
              v-show="errors.has('password')"
              class="invalid-feedback"
            >
              {{ errors.first('password') }}
            </div>
          </div>

          <div
            class="form-group has-feedback has-feedback-left col-12"
            :class="{ 'has-danger': errors.has('password') }"
          >
            <label
              class="form-control-label hidden"
              for="passwordConfirm"
            >Password</label>
            <div class="input-icon">
              <span class="oi oi-lock-locked" />
            </div>
            <input
              id="passwordConfirm"
              v-model="passwordConfirm"
              v-validate="'required|min:8'"
              type="password"
              :class="{
                'is-invalid':
                  errors.has('passwordConfirm') &&
                  veeFields.passwordConfirm.touched
              }"
              class="form-control"
              data-vv-as="password"
              name="passwordConfirm"
              placeholder="Confirm"
            >
            <div
              v-show="errors.has('passwordConfirm')"
              class="invalid-feedback"
            >
              {{ errors.first('passwordConfirm') }}
            </div>
          </div>

          <b-button
            type="submit"
            variant="primary"
            class="loading"
            @click="postPasswordReset"
          >
            Reset
          </b-button>
        </div>

        <div
          v-if="!token"
          class="box row"
        >
          <div
            class="alert alert-danger col-12"
            role="alert"
          >
            Could not parse token
          </div>
        </div>
      </main>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ResetPassword',
  data () {
    return {
      password: '',
      passwordConfirm: '',
      localError: null
    }
  },
  computed: {
    validPassword: function () {
      return this.password === this.passwordConfirm ? this.password : false
    },
    token: function () {
      return Object.keys(this.$route.query)[0]
    },
    serverError: function () {
      return this.$store.state.POST_PASSWORD_RESET_FAILURE
        ? 'Could not reset password'
        : null
    }
  },
  methods: {
    postPasswordReset () {
      if (this.errors.any()) {
        return
      }

      this.localError = null
      if (this.validPassword) {
        this.$store
          .dispatch('postPasswordReset', {
            token: this.token,
            password: this.validPassword
          })
          .then((success) => {
            this.$router.push('/login')
          })
          .catch((error) => {
            this.error = error.response.data.message
          })
      } else {
        this.localError = 'Passwords do not match'
      }
    }
  }
}
</script>

<style scoped lang="scss">
body {
  background: white;
}
</style>
