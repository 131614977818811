import httpClientService from '@/services/httpClientService'
import websocketMessageTypes from '@/enums/websocketMessageTypes'

export default class NotificationQueueService {
  constructor (notificationQueue) {
    if (!notificationQueue) throw new Error('Missing notification queue.')
    this.queue = notificationQueue
  }

  sendMessage (
    payload = {},
    requiresResponseViaWebsocket = false,
    messageType = websocketMessageTypes.PRIVATE,
    topicName = null
  ) {
    if (requiresResponseViaWebsocket && !topicName) {
      throw new Error('Missing topic name.')
    }

    const url = `admin/sqs/${this.queue}`

    if (requiresResponseViaWebsocket) {
      payload = Object.assign(payload, {
        wsAsyncResponse: requiresResponseViaWebsocket,
        wsMessageType: messageType,
        wsTopicName: topicName
      })
    }
    return httpClientService
      .getAPIHttpClient()
      .post(url, payload)
      .catch((e) => e)
  }
}
