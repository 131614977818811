const productTypes = Object.freeze({
  CONSUMER: 'CONSUMER',
  RESEARCH: 'RESEARCH',
  WHITE_LABEL: 'WHITE_LABEL',
  LBRAAS: 'LBRAAS',
  LBAAS: 'LBAAS',
  BRAAS: 'BRAAS',
  BAAS: 'BAAS',
  LABORATORY_PROCESSING_CONTROL: 'LABORATORY_PROCESSING_CONTROL'
})

export const productTypeAsOptions = Object.entries(productTypes).map(([key, value]) => {
  return {
    text: key,
    value
  }
})

export default productTypes
