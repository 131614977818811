<template>
  <transition name="modal">
    <div v-show="show" class="modal-mask" @click="close">
      <div class="modal-container" :class="className" @click.stop>
        <slot name="header">
          <a
            v-if="!hideCloseButton"
            class="closeButton closeBlack"
            @click="close"
          >
            <font-awesome-icon :icon="['fa', 'fa-times']" aria-hidden="true" />
          </a>
        </slot>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    }
  },
  watch: {
    show: function (val) {
      if (val === true) {
        this.onShow()
      }
    }
  },
  mounted: function () {
    document.addEventListener('keydown', (e) => {
      if (this.show && e.keyCode === 27) {
        this.$emit('close')
      }
    })
  },
  methods: {
    close: function () {
      let self = this
      self.$emit('close')
    },
    back: function () {
      let self = this
      self.$emit('back')
    },
    onShow: function () {}
  }
}
</script>

<style scoped lang="scss">
.text-right {
  text-align: right;
}

.form-label {
  display: block;
  margin-bottom: 1em;
}

.form-label > .form-control {
  margin-top: 0.5em;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  line-height: 1.5;
  border: 1px solid #ddd;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.closeButton {
  font-size: 20px;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

@media only screen and (max-width: 480px) {
  .modal-container {
    padding-bottom: 200px;
  }
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: translateY(200%);
}
</style>
