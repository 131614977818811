import HttpClient from '@microbadevs/http-client'
import authService from '@/services/authService'
import store from '@/stores/'

export default class HttpClientService {
  static getAPIHttpClient (isExternalClientRequest = false, accessToken = null) {
    const apiHttpClient = new HttpClient(
      process.env.VUE_APP_API_ROOT,
      process.env.VUE_APP_API_USER,
      process.env.VUE_APP_API_PASS,
      accessToken || authService.getAccessToken(),
      authService.getRefreshToken(),
      false,
      false,
      isExternalClientRequest
    )
    apiHttpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 503) {
          store.dispatch('maintenance/show', error.response?.data)
        }
        return Promise.reject(error)
      }
    )
    return apiHttpClient
  }
  static synlabApiHttpClient() {
    let synlabApiHttpClient = new HttpClient(
      process.env.VUE_APP_API_ROOT,
      null,
      null,
      null,
      null,
      false,
      true
    )
    synlabApiHttpClient.client.defaults.auth = {
      username: process.env.VUE_APP_SYNLAB_API,
      password: process.env.VUE_APP_SYNLAB_API_PASSWORD
    }
    synlabApiHttpClient.client.defaults.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    synlabApiHttpClient.client.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.status === 503) {
          store.dispatch('maintenance/show', error.response?.data)
        }
        return Promise.reject(error)
      },
    )
    
    return synlabApiHttpClient.client
  }
}
