import httpClientService from '@/services/httpClientService'

export default class QCReportService {
  static async getReportBySampleBatch (sampleIdentifiersArray) {
    return httpClientService
      .getAPIHttpClient()
      .post(`${process.env.VUE_APP_QCREPORT_API}report`, { sampleIdentifiers: sampleIdentifiersArray })
  }

  static async getQCReport (sampleIdentifier) {
    return httpClientService
      .getAPIHttpClient()
      .get(`${process.env.VUE_APP_QCREPORT_API}report?sampleIdentifier=${sampleIdentifier}`)
  }

  static async getComparisonImage ({ sampleIdentifier, imageSource, imageName }) {
    return httpClientService
      .getAPIHttpClient()
      .get(`${process.env.VUE_APP_QCREPORT_API}image/${sampleIdentifier}/${imageSource}/${imageName}`)
  }
}

