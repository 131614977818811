<template>
  <div class="page container">
    <transition name="slide-fade">
      <div>
        <header class="inline-between">
          <h1>Referrals</h1>
          <a
            href="https://microba.atlassian.net/wiki/spaces/OT/pages/1751482388/Referral+Notifications+Management+-+Staff+Portal"
            target="_blank"
            class="btn btn-sm btn-outline-warning inline-half"
          >
            <span class="fa fa-question-circle"></span> Docs
          </a>
        </header>
        <ReferralSearchUi @search-clicked="refreshMatchingReferrals" />
        <Referrals
          :allReferrals="allMatchingReferrals"
          @cancel-referral="cancelReferral"
          @send-referral-notification="sendReferralNotification"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import ReferralSearchUi from '@/components/referrals/ReferralSearchUi'
import Referrals from '@/components/referrals/Referrals'
import { Loader, Notification, NOTIFICATION_STYLES } from '@microbadevs/library'

export default {
  name: 'ReferralTools',
  components: {
    ReferralSearchUi,
    Referrals
  },
  data() {
    return {
      allMatchingReferrals: [],
      lastSearchPayload: {}
    }
  },
  methods: {
    async refreshMatchingReferrals(payload = undefined) {
      try {
        let p = payload
        if (payload) {
          this.lastSearchPayload = payload
        } else {
          p = this.lastSearchPayload
        }

        Loader.show()

        const query = {
          patientFname: p.patientFname,
          patientLname: p.patientLname,
          keyword: p.keyword
        }

        const { data } = await this.$store.dispatch(
          'getReferralsByPatientName',
          query
        )
        Loader.hide()
        this.allMatchingReferrals = data.content

        // Update the URL with search query
        this.$router
          .push({
            query
          })
          .catch(() => {})
      } catch (error) {
        Loader.hide()
        Notification({
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    async cancelReferral(payload) {
      try {
        Loader.show()
        const { data } = await this.$store.dispatch('cancelReferralById', {
          referralId: payload.referralId
        })
        Loader.hide()
      } catch (error) {
        Loader.hide()
        Notification({
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
        return
      }
      await this.refreshMatchingReferrals()
      Notification({
        title: 'Referral cancelled.',
        style: NOTIFICATION_STYLES.SUCCESS
      })
    },
    async sendReferralNotification(payload) {
      try {
        Loader.show()
        const { data } = await this.$store.dispatch(
          'sendReferralNotificationById',
          {
            referralId: payload.referralId
          }
        )
        Loader.hide()
      } catch (error) {
        Loader.show()
        Notification({
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
        return
      }
      await this.refreshMatchingReferrals()
      Notification({
        title: 'Referral notification sent.',
        style: NOTIFICATION_STYLES.SUCCESS
      })
    }
  }
}
</script>
