const rules = {
  isValidPhoneNumber: {
    getMessage: (field) => {
      return `Please enter a valid phone number`
    },
    validate: (phoneNumber, [valid]) => {
      return valid === 'true'
    }
  }
}

export default (Validator) => {
  Object.keys(rules).forEach((rule) => {
    Validator.extend(rule, rules[rule])
  })
}
