<template>
  <transition name="slide-fade">
    <div class="dashboard">
      <div :class="showView === 'DASHBOARD' ? 'container' : 'container-fluid'">
        <header>
          <div class="row">
            <div class="col-md-8 page-header">
              <h1>Health care Professionals</h1>
              <h3 v-if="showView == 'DASHBOARD'">Dashboard</h3>
              <h3 v-else>Referral code management</h3>
            </div>
            <div class="col-md-4 pt-3 text-right">
              <div class="form-group mt-2 col-md-12">
                <input
                  v-model="hcpSearch"
                  type="text"
                  class="form-control"
                  placeholder="Search Professionals"
                />
                <a
                  v-if="showView == 'DASHBOARD'"
                  href="#"
                  class="pl-3"
                  @click.prevent="showList"
                  >View Professionals</a
                >
                <a
                  v-else-if="hcpSearch.length < 1"
                  href="#"
                  class="pl-3"
                  @click.prevent="showDashboard"
                  >View Dashboard</a
                >
              </div>
            </div>
          </div>
        </header>

        <template v-if="showView == 'DASHBOARD'">
          <HCPStats :items="items" />
          <HCPApplications
            :items="items"
            @selectProfessional="viewProfessional"
          />
        </template>
        <template v-else>
          <professional-table
            :items="items"
            :search="hcpSearch"
            @selectProfessional="viewProfessional"
          />
        </template>

        <div v-if="isLoading" class="row">
          <div class="col-12 text-center">
            <font-awesome-icon
              :icon="['fa', 'fa-spinner']"
              class="fa-spin fa-3x fa-fw"
            />
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <ModalEditHealthProfessional
        v-if="professionalObj"
        :show="editHealthProfessional"
        :obj="professionalObj"
        @close="closeEditModal"
      />
    </div>
  </transition>
</template>

<script>
import { Loader } from '@microbadevs/library'
import UserMixins from '@/mixins/userProfile'
import ProfessionalTable from '@/components/HealthProfessionals/professionalTable.vue'
import HCPStats from '@/components/HealthProfessionals/HCPStats.vue'
import HCPApplications from '@/components/HealthProfessionals/HCPApplications.vue'
import ModalEditHealthProfessional from '@/components/models/ModalEditHealthProfessional'

export default {
  name: 'Research',
  components: {
    ProfessionalTable,
    HCPStats,
    HCPApplications,
    ModalEditHealthProfessional
  },
  mixins: [UserMixins],
  data: function () {
    return {
      view: 'DASHBOARD',
      items: [],
      hcpSearch: '',
      researcherTableSort: 'name',
      professionalObj: null,
      editHealthProfessional: false,
      isLoading: false
    }
  },
  computed: {
    showView() {
      if (this.view === 'DASHBOARD' && this.hcpSearch < 1) {
        return 'DASHBOARD'
      }
      return 'LIST'
    }
  },
  mounted: function () {
    this.$store.dispatch('getUser')
    this.fetchData()
  },
  methods: {
    showList() {
      this.view = 'LIST'
    },
    showDashboard() {
      this.view = 'DASHBOARD'
    },
    viewProfessional(obj) {
      this.professionalObj = obj
      this.editHealthProfessional = true
    },
    sortResearchersBy(col) {
      this.researcherTableSort = col
    },
    deleteProject() {
      var r = confirm('Are you sure you want to terminate this project?')
      if (r === true) {
        let self = this
        let payload = self.projectObj
        this.$store
          .dispatch('deleteProject', payload)
          .then((response) => {
            self.projectObj = null
          })
          .catch((error) => {
            console.log('Unable to terminate project', error)
          })
      }
    },
    showAddProfessionalModal() {
      this.showAddProfessional = true
      this.professionalObj = null
    },
    fetchData() {
      Loader.show()
      let self = this
      this.$store
        .dispatch('getHealthProfessionals')
        .then((response) => {
          self.items = response.data
        })
        .catch((error) => {
          console.log(error)
          alert(error.response)
        })
        .finally(() => {
          Loader.hide()
        })
    },
    closeEditModal() {
      this.fetchData()
      this.editHealthProfessional = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chart-wrap {
  position: relative;
  height: 200px;
  margin: 50px auto;
}

em {
  font-size: 17px;
  opacity: 0.7;
  max-width: 500px;
  margin: 0px auto;
}
.padded-row {
  padding-bottom: 0;
  @media (min-width: 992px) {
    padding-bottom: 1rem;
  }
}

.mb-4 {
  margin-bottom: 1.25rem !important;
  @media (min-width: 992px) {
    margin-bottom: 1.5rem !important;
  }
}

.table-hover tr {
  cursor: pointer;
}

.dashboard {
  //padding-bottom:2.5rem;
  padding-bottom: 100px;
}

.clickable {
  cursor: pointer;
}
.font-20 {
  font-size: 20px;
}

.page-header h1 {
  margin-bottom: 0px !important;
}
</style>
