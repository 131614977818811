<template>
  <Modal
    :show="show"
    class-name="p-5 pb-5"
    @close="close()"
  >
    <div
      v-if="action == null"
      class="modal-main ml-4 mr-4 w-100"
    >
      <h2 class="text-center">
        Health Care Professional
      </h2>

      <div class="row form">
        <div class="col-12 text-center">
          <ul class="nav nav-pills nav-fill ml-3 mr-3 mt-3 mb-3">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: tab == 'PERSONAL' }"
                href="#"
                @click.prevent="tab = 'PERSONAL'"
              >Personal</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: tab == 'QUALIFICATIONS' }"
                href="#"
                @click.prevent="tab = 'QUALIFICATIONS'"
              >Qualifications</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: tab == 'PRACTICE' }"
                href="#"
                @click.prevent="tab = 'PRACTICE'"
              >Practice</a>
            </li>
          </ul>
        </div>
        <div class="col-12">
          <template v-if="tab == 'PERSONAL'">
            <div class="form-block pl-5 pr-5">
              <div class="row">
                <div class="form-group col col-md-6">
                  <label>First name</label>
                  <input
                    v-model="obj.firstName"
                    type="text"
                    class="form-control"
                    required
                    placeholder="First name"
                  >
                </div>
                <div class="form-group col col-md-6">
                  <label>Last name</label>
                  <input
                    v-model="obj.lastName"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Last name"
                  >
                </div>
              </div>
              <div class="row">
                <div class="form-group col col-md-6">
                  <label>Phone</label>
                  <input
                    v-model="obj.phoneNumber"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Phone"
                  >
                </div>
                <div class="form-group col col-md-6">
                  <label>Email</label>
                  <input
                    v-model="obj.email"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Email"
                  >
                </div>
              </div>
              <div class="row">
                <div class="form-group col col-md-12">
                  <label>How did you hear about the program</label>
                  <select
                    v-model="obj.programAwarenessBy"
                    class="form-control"
                  >
                    <option
                      v-for="item in awarenessOptions"
                      :key="`awareness-options-${item}`"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </template>
          <template v-if="tab == 'QUALIFICATIONS'">
            <div class="form-block pl-5 pr-5">
              <div class="row">
                <div class="form-group col col-md-12">
                  <label>Qualifications</label>
                  <textarea
                    v-model="obj.qualifications"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Qualifications"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col col-md-12">
                  <label>Practitioner Type</label>
                  <select
                    v-model="obj.practitionerType"
                    class="form-control"
                  >
                    <option
                      v-for="type in practitionerTypes"
                      :key="type.value"
                      :value="type.value"
                    >
                      {{ type.text }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                v-if="obj.practitionerType == 'OTHER'"
                class="row"
              >
                <div class="form-group col col-md-12">
                  <label>Other Practitioner Type</label>
                  <input
                    v-model="obj.practitionerTypeOther"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Other Practitioner Type"
                  >
                </div>
              </div>

              <br>
              <div class="row">
                <div class="form-group col col-md-6">
                  <label>Registering Body</label>
                  <input
                    v-model="obj.registeringBody"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Other Practitioner Type"
                  >
                </div>
                <div class="form-group col col-md-6">
                  <label>Practitioner Registry Number</label>
                  <input
                    v-model="obj.registrationNumber"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Other Practitioner Type"
                  >
                </div>
              </div>
            </div>
          </template>
          <template v-if="tab == 'PRACTICE'">
            <div class="form-block pl-5 pr-5">
              <div class="row">
                <div class="form-group col col-md-12">
                  <label>Name of practice</label>
                  <input
                    v-model="obj.practiceName"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Name of practice"
                  >
                </div>
              </div>
              <div class="row">
                <div class="form-group col col-md-12">
                  <label>Location State</label>
                  <select
                    v-model="obj.practiceState"
                    class="form-control"
                  >
                    <option
                      v-for="item in states"
                      :key="`states-${item}`"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col col-md-12">
                  <label>Number of practitioners within the practice</label>
                  <input
                    v-model="obj.numberOfPractitionerInPractice"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Number of practitioners within the practice"
                  >
                </div>
              </div>
              <div class="row">
                <div class="form-group col col-md-12">
                  <label>Estimated number of patients you serve annually</label>
                  <input
                    v-model="obj.numberOfPatientsServedAnnually"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Estimated number of patients you serve annually"
                  >
                </div>
              </div>
              <div class="row">
                <div class="form-group col col-md-12">
                  <label>Estimated % of your patients you believe would benefit from
                    comprehensive microbiome testing</label>
                  <input
                    v-model="obj.percentOfPatientsBenefit"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Estimated % of your patients you believe would benefit from comprehensive microbiome testing"
                  >
                </div>
              </div>
              <div class="row">
                <div class="col col-md-12">
                  <h4>
                    Reasons for your interest in Microba's testing services.
                  </h4>

                  <div class="form-check">
                    <input
                      id="additionalValue"
                      v-model="additionalValue"
                      type="checkbox"
                      class="form-check-input"
                      value="additionalValue"
                    >
                    <label
                      class="form-check-label"
                      for="additionalValue"
                    >I wish to provide additional value to patients</label>
                  </div>
                  <div class="form-check mt-2">
                    <input
                      id="usingOverseaServicesNeedFasterTurnaround"
                      v-model="usingOverseaServicesNeedFasterTurnaround"
                      type="checkbox"
                      class="form-check-input"
                      value="usingOverseaServicesNeedFasterTurnaround"
                    >
                    <label
                      class="form-check-label"
                      for="usingOverseaServicesNeedFasterTurnaround"
                    >I am currently using services overseas and value a faster
                      turnaround time</label>
                  </div>
                  <div class="form-check mt-2">
                    <input
                      id="usingOverseasServicesValueLocalProvider"
                      v-model="usingOverseasServicesValueLocalProvider"
                      type="checkbox"
                      class="form-check-input"
                      value="usingOverseasServicesValueLocalProvider"
                    >
                    <label
                      class="form-check-label"
                      for="usingOverseasServicesValueLocalProvider"
                    >I am currently using services overseas and value a local
                      provider</label>
                  </div>
                  <div class="form-check mt-2">
                    <input
                      id="using16sServices"
                      v-model="using16sServices"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      value="using16sServices"
                      for="using16sServices"
                    >I am currently using 16S-based services and value
                      Microba’s metagenomic service with species level
                      resolution and metabolic functional profiling</label>
                  </div>
                  <div class="form-check mt-2">
                    <input
                      id="extendOffering"
                      v-model="extendOffering"
                      type="checkbox"
                      class="form-check-input"
                      value="extendOffering"
                    >
                    <label
                      class="form-check-label"
                      for="extendOffering"
                    >I wish to extend my service offering</label>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="form-group col col-md-12">
                  <label>Other comments or reasons for interest in Microba?</label>
                  <textarea
                    v-model="professional.qualifications"
                    type="text"
                    class="form-control"
                    required
                    placeholder="Other comments or reasons for interest in Microba?"
                  />
                </div>
              </div>
            </div>
          </template>

          <div
            v-if="!obj.approved"
            class="row"
          >
            <div class="col-6 mt-5 text-right">
              <a
                href="#"
                class="btn btn-success"
                @click.prevent="approveApplication"
              >
                <span v-if="loadingData">Loading</span>
                <span v-else>
                  <span>Approve</span>
                </span>
              </a>
            </div>
            <div class="col-6 å mt-5 text-left">
              <a
                href="#"
                class="btn btn-danger"
                @click.prevent="declineApplication"
              >
                <span v-if="loadingData">Loading</span>
                <span v-else>
                  <span>Decline</span>
                </span>
              </a>
            </div>
          </div>
          <div
            v-if="obj.approved && !obj.courseCompleted"
            class="row"
          >
            <div class="col-12 mt-3 text-center">
              <a
                href="#"
                class="btn btn-link"
                @click.prevent="completeCourse"
              >
                <span v-if="loadingData">Loading</span>
                <span v-else>
                  <span>Mark course as complete</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/models/Modal'
export default {
  name: 'ModalEditHealthProfessional',
  components: {
    Modal
  },
  extends: Modal,
  props: ['obj'],
  data: () => {
    return {
      loadingData: false,
      action: null,
      tab: 'PERSONAL',
      sampleStatus: '',
      additionalValue: false,
      usingOverseaServicesNeedFasterTurnaround: false,
      usingOverseasServicesValueLocalProvider: false,
      using16sServices: false,
      extendOffering: false,
      professional: {
        email: '',
        firstName: '',
        lastName: '',
        userType: 'MICROBA_INFORMED_PROFESSIONAL',
        password: 'password'
      },
      awarenessOptions: [
        'Conference or Workshop',
        'Microba Webinar',
        'Saw it discussed on a forum or social media',
        'Referred by a colleague',
        'Advised by a patient',
        'Other'
      ],
      states: [
        'Australian Capital Territory',
        'New South Wales',
        'Northern Territory',
        'South Australia',
        'Tasmania',
        'Queensland',
        'Victoria',
        'Western Australia',
        'Other'
      ],
      practitionerTypes: [
        { value: 'GASTROENTEROLOGIST', text: 'Gastroenterologist' },
        { value: 'GENERAL_PRACTITIONER', text: 'General Practitioner (GP)' },
        {
          value: 'PSYCHIATRIST_PSYCHOLOGIST',
          text: 'Psychiatrist/Psychologist'
        },
        { value: 'DIETITIAN', text: 'Dietitian' },
        { value: 'NUTRITIONIST', text: 'Nutritionist' },
        { value: 'NATUROPATH', text: 'Naturopath' },
        { value: 'OTHER', text: 'Other (specify below)' }
      ]
    }
  },
  methods: {
    onShow () {
      this.action = null
      if (this.obj) {
        this.professional = this.obj
        if (
          this.obj.interestReason &&
          this.obj.interestReason.includes('additionalValue')
        ) {
          this.additionalValue = true
        }
        if (
          this.obj.interestReason &&
          this.obj.interestReason.includes(
            'usingOverseaServicesNeedFasterTurnaround'
          )
        ) {
          this.usingOverseaServicesNeedFasterTurnaround = true
        }
        if (
          this.obj.interestReason &&
          this.obj.interestReason.includes(
            'usingOverseasServicesValueLocalProvider'
          )
        ) {
          this.usingOverseasServicesValueLocalProvider = true
        }
        if (
          this.obj.interestReason &&
          this.obj.interestReason.includes('using16sServices')
        ) {
          this.using16sServices = true
        }
        if (
          this.obj.interestReason &&
          this.obj.interestReason.includes('extendOffering')
        ) {
          this.extendOffering = true
        }
      } else {
        this.professional = {
          email: '',
          firstName: '',
          lastName: '',
          userType: 'MICROBA_INFORMED_PROFESSIONAL',
          password: 'password'
        }
      }
    },
    declineApplication () {
      this.processApplication(false)
    },
    approveApplication () {
      this.processApplication(true)
    },
    processApplication (approved) {
      let payload = {
        id: this.obj.id,
        userType: this.obj.userType,
        approved: approved
      }
      this.$store
        .dispatch('putHealthProfessional', payload)
        .then((response) => {
          this.loadingData = false
          this.close()
        })
        .catch((error) => {
          this.loadingData = false
          this.close()
          console.log(error)
        })
    },
    completeCourse () {
      let payload = {
        id: this.obj.id,
        courseCompleted: true
      }
      this.$store
        .dispatch('patchHealthProfessional', payload)
        .then((response) => {
          this.loadingData = false
          this.close()
        })
        .catch((error) => {
          this.loadingData = false
          this.close()
          console.log(error)
        })
    }
  }
}
</script>

<style></style>
