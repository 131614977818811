<template>
  <div class="component component-kit-progress-bar d-lg-flex">
    <div
      class="component-kit-progress-bar-inner row d-lg-flex flex-lg-wrap align-content-lg-between"
    >
      <div
        class="col-6 col-sm-5 col-md-4 col-lg-12 pr-0 pl-lg-0 d-flex justify-content-start align-items-center align-content-center flex-wrap"
      >
        <h4 class="text-left text-lg-center">
          {{ formattedTitle }}
        </h4>
        <p class="magic-date w-100 text-left text-lg-center">
          12/12/2018
        </p>
      </div>

      <div
        class="col-6 col-sm-7 col-md-8 col-lg-12 d-flex align-items-center justify-content-stretch w-100"
      >
        <div class="w-100">
          <div class="row">
            <div class="col-6 col-sm-7 col-md-8 col-lg-12 pr-0 pl-lg-0 pt-lg-2">
              <div class="progressBar">
                <div
                  class="progress"
                  style="border-radius: 99em"
                >
                  <div
                    :class="className"
                    role="progressbar"
                    :style="{ width: percent + '%' }"
                    :aria-valuenow="percent"
                    aria-valuemin="0"
                    aria-valuemax="percent"
                  >
                    <div
                      v-if="percent === 100"
                      class="bar-tick icon-check d-lg-none"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-6 col-sm-5 col-md-4 col-lg-12 d-flex justify-content-center align-items-center px-lg-0 pt-lg-3"
            >
              <router-link
                :to="routePath"
                class="progress-link"
              >
                {{ buttonText }}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="enableQuestionnaireTransfer && percent > 0"
        class="col-12"
      >
        <transfer-questionnaire
          :survey-type="surveyType"
          :kits="kits"
          :source-kit-id="kitId"
          @questionnaire-copied="$emit('questionnaire-copied')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TransferQuestionnaire from '@/components/elements/TransferQuestionnaire'
import apiStringMappings from '@/mixins/apiStringMapping'

export default {
  name: 'KitProgressBar',
  components: { TransferQuestionnaire },
  mixins: [apiStringMappings],
  props: {
    total: Number,
    count: Number,
    title: String,
    date: String,
    surveyType: String,
    kitId: Number,
    buttonText: {
      default: 'View',
      type: String
    },
    enableQuestionnaireTransfer: { type: Boolean, default: false },
    kits: Array
  },
  computed: {
    formattedTitle: function () {
      return this.sampleToString(this.title)
    },
    routePath: function () {
      const slug = this.sampleToSlug(this.surveyType)
      return `/surveys/${slug}/${this.kitId}`
    },
    percent: function () {
      return (Number(this.count) / Number(this.total)) * 100
    },
    className: function () {
      return this.percent === 100
        ? 'progress-bar bg-success'
        : 'progress-bar bg-info'
    }
  }
}
</script>

<style scoped lang="scss">
h4 {
  margin: 0;
  line-height: 21px;
  font-size: 1rem;
  width: 100%;

  @media (min-width: 992px) {
    font-size: 0.875rem;
    white-space: normal;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
  }
}

.magic-date {
  color: #00193d;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 0.875rem;
  margin-bottom: 0;
  margin-top: 3px;

  @media (min-width: 992px) {
    font-size: 0.625rem;
  }
}

.progress-link {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
}

.progress {
  height: 24px !important;
}
.component-kit-progress-bar {
  display: block;
  width: 100%;
  background: #f4f4f4;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  cursor: pointer;

  @media (min-width: 992px) {
    padding: 1.25rem 1.625rem 0.75rem 1.625rem;
    height: 100%;
    margin-bottom: 0rem;
  }

  + .component-progress-bar {
    padding-top: 4px;
    background: purple;
  }

  &:hover {
    .progress-bar.bg-success {
      background-color: #7bf9c0 !important;
    }

    .progress-bar.bg-info {
      background-color: #8ed9ff !important;
    }

    .tick {
      color: #7bf9c0;
    }
  }

  .component-kit-progress-bar-inner {
    flex-grow: 1;
  }
}
.tick {
  flex-basis: 30px;
  overflow: hidden;
  text-align: right;
  color: #5cd69f;
}

.progressBar {
  flex: 1;
  position: relative;
  &:hover {
    .tip {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

.progress {
  border-radius: 99em;
  margin: 0;
  pointer-events: none;
  overflow: hidden;
  height: 15px;
}

.progress-bar {
  transition: 0.35s ease;
}

.tip {
  background: #778598;
  color: white;
  position: absolute;
  bottom: calc(100% + 2px);
  right: -3%;
  min-width: 63%;
  max-width: 100%;
  padding: 0.75rem 1.125rem;
  text-align: center;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);

  transition: all 0.3s;
  border-radius: 2px;
  pointer-events: none;

  opacity: 0;
  transform: translateY(20px);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #778598;
    display: block;
    margin-left: 0px;
    bottom: -7px;
  }

  .tip-title {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
    display: block;
    margin: 0 auto;

    + .tip-date {
      margin-top: 0.375rem;
    }
  }

  .tip-date {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 0.875rem;
    display: block;
    margin: 0 auto;
  }
}
</style>
