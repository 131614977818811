<template>
  <div class="page container">
    <transition name="slide-fade">
      <div>
        <header>
          <h1 class="page-header">Subscriptions Due</h1>
        </header>
        <div class="box box-shadow">
          <b-table striped hover :items="dueSubscriptions" :fields="fields">
            <template v-slot:cell(name)="row">
              {{ row.item.consumerFirstName }} {{ row.item.consumerLastName }}
            </template>
            <template v-slot:cell(email)="row">
              <a
                v-if="row.item.consumerEmail"
                :href="`mailto:${row.item.consumerEmail}`"
                target="_blank"
              >
                {{ row.item.consumerEmail }}
              </a>
            </template>
            <template v-slot:cell(type)="row">
              {{ getSubscriptionType(row.item.type) }}
            </template>
            <template v-slot:cell(dueDate)="row">
              {{ getDueDate(row.item.nextBillingDate) }}
            </template>
            <template v-slot:cell(actions)="row">
              <b-button size="sm" @click="chargeSubscription(row.item)">
                Charge
              </b-button>
            </template>
          </b-table>
          <span @click="fetchAllSubscriptions">Show All Subscriptions</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import subscriptionType from '@/enums/subscriptionType'
import { Loader, Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import moment from 'moment'

export default {
  name: 'DueSubscriptions',
  data() {
    return {
      dueSubscriptions: [],
      fields: [
        {
          key: 'id',
          label: 'Id'
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'email',
          label: 'email'
        },
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'dueDate',
          label: 'Due Date'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ]
    }
  },
  mounted() {
    this.fetchDueSubscriptions()
  },
  methods: {
    fetchDueSubscriptions() {
      Loader.show()
      this.$store
        .dispatch('getSubscriptions', { due: 1 })
        .then((response) => {
          this.dueSubscriptions = response.data
          Loader.hide()
        })
        .catch((error) => {
          Notification({
            text: error.response.data.message,
            style: NOTIFICATION_STYLES.ERROR
          })
        })
    },
    fetchAllSubscriptions() {
      Loader.show()
      this.$store
        .dispatch('getSubscriptions')
        .then((response) => {
          this.dueSubscriptions = response.data
          Loader.hide()
        })
        .catch((error) => {
          Notification({
            text: error.response.data.message,
            style: NOTIFICATION_STYLES.ERROR
          })
        })
    },
    chargeSubscription(payload) {
      Loader.show()
      this.$store
        .dispatch('postSubscription', payload)
        .then(() => {
          this.fetchSubscriptions()
          Notification({
            title: 'Subscription charged.',
            style: NOTIFICATION_STYLES.SUCCESS
          })
        })
        .catch((error) => {
          Notification({
            text: error.response.data.message,
            style: NOTIFICATION_STYLES.ERROR
          })
        })
    },
    getSubscriptionType(type) {
      return subscriptionType[type]
    },
    getDueDate(nextBillingDate) {
      let dueDate = moment(nextBillingDate)
      let displayString =
        dueDate.format('DD/MM/YYYY') + ' (' + dueDate.fromNow() + ')'
      return displayString
    }
  }
}
</script>
