<template>
  <div class="box box-shadow">
    <h3>Promotional Codes</h3>
    <b-table
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :fields="fields"
      striped
      hover
      :items="items"
    >
      <template v-slot:cell(action)="data">
        <button
          v-if="data.item.valid"
          type="button"
          class="btn btn-primary pull-right py-1"
          @click="invalidate(data.item)"
        >
          In-validate
        </button>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  name: 'PromoListing',
  props: {
    promotionalCodes: Array
  },
  data () {
    return {
      sortBy: 'productType',
      sortDesc: true,
      fields: [
        { key: 'code', label: 'Code' },
        { key: 'value', label: 'Value' },
        { key: 'type', label: 'Type' },
        { key: 'productType', label: 'Product' },
        { key: 'usageLimit', label: 'Usage Limit' },
        { key: 'currentUsage', label: 'Current Usage' },
        { key: 'messageToCustomer', label: 'Message' },
        { key: 'action', label: 'Action' }
      ],
      items: []
    }
  },
  computed: {},
  mounted: function () {
    this.items = this.promotionalCodes.map((x) => {
      x._rowVariant = x.valid ? 'default' : 'danger'
      return x
    })
  },
  methods: {
    invalidate (data) {
      if (data) {
        this.$store
          .dispatch('invalidatePromoCode', data)
          .then(() => {
            this.items
              .filter(
                (x) =>
                  x.code === data.code && x.productType === data.productType
              )
              .map((x) => {
                x.valid = false
                x._rowVariant = 'danger'
                return x
              })
          })
          .catch((error) => console.log(error))
      }
    }
  }
}
</script>
