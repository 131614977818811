const KIT_TYPES = Object.freeze({
  INSIGHT_KIT: 'INSIGHT_KIT',
  METABIOME_KIT: 'METABIOME_KIT',
  RESEARCH_PARTICIPANT_KIT: 'RESEARCH_PARTICIPANT_KIT',
  ORBITM_B_KIT: 'ORBITM_B_KIT',
  ORBITM_S_KIT: 'ORBITM_S_KIT',
  ORBITM_R_KIT: 'ORBITM_R_KIT',
  NON_PHYSICAL_KIT: 'NON_PHYSICAL_KIT'
})

const KIT_LABELS = Object.freeze({
  INSIGHT_KIT: 'Insight Kit',
  METABIOME_KIT: 'Metabiome Kit',
  RESEARCH_PARTICIPANT_KIT: 'Research Kit',
  ORBITM_B_KIT: 'OrbitM Blood Kit',
  ORBITM_S_KIT: 'OrbitM Stool Kit',
  ORBITM_R_KIT: 'OrbitM Replacement Kit'
})

const SAMPLE_TYPES = Object.freeze({
  MICROBA_SWAB: 'MICROBA_SWAB',
  K2EDTA_TUBE: 'K2EDTA_TUBE',
  CRYOVIALS_TUBE: 'CRYOVIALS_TUBE',
  NA_HEPARIN_TUBE: 'NA_HEPARIN_TUBE',
  COPAN_FLOQ_SWAB: 'COPAN_FLOQ_SWAB',
  OMNIGENE_GUT_TUBE: 'OMNIGENE_GUT_TUBE',
  PARTNER_SWAB: 'PARTNER_SWAB',
  PLATE: 'PLATE',
  TUBE: 'TUBE',
  NONE: 'NONE',
  OTHER: 'OTHER'
})

const SampleTypeLabels = Object.freeze({
  [SAMPLE_TYPES.MICROBA_SWAB]: 'Microba Swab',
  [SAMPLE_TYPES.K2EDTA_TUBE]: 'K2EDTA Tube',
  [SAMPLE_TYPES.CRYOVIALS_TUBE]: 'Cryovials Tube',
  [SAMPLE_TYPES.NA_HEPARIN_TUBE]: 'Sodium Heparin Tube',
  [SAMPLE_TYPES.COPAN_FLOQ_SWAB]: 'COPAN FLOQSwab',
  [SAMPLE_TYPES.OMNIGENE_GUT_TUBE]: 'OMNIgene GUT Tube',
  [SAMPLE_TYPES.PARTNER_SWAB]: 'Partner Swab',
  [SAMPLE_TYPES.PLATE]: 'Plate',
  [SAMPLE_TYPES.TUBE]: 'Tube',
  [SAMPLE_TYPES.NONE]: 'None',
  [SAMPLE_TYPES.OTHER]: 'Other'
})

const KIT_SAMPLE_BUNDLE = Object.freeze({
  INSIGHT_KIT: {
    numberOfSamplesIncluded: 1,
    samplesIncluded: [
      {
        sampleType: SAMPLE_TYPES.MICROBA_SWAB,
        kitType: KIT_TYPES.INSIGHT_KIT,
        quantity: 1
      }
    ]
  },
  METABIOME_KIT: {
    numberOfSamplesIncluded: 1,
    samplesIncluded: [
      {
        sampleType: SAMPLE_TYPES.MICROBA_SWAB,
        kitType: KIT_TYPES.METABIOME_KIT,
        quantity: 1
      }
    ]
  },
  RESEARCH_PARTICIPANT_KIT: {
    numberOfSamplesIncluded: 1,
    samplesIncluded: [
      {
        sampleType: SAMPLE_TYPES.MICROBA_SWAB,
        kitType: KIT_TYPES.RESEARCH_PARTICIPANT_KIT,
        quantity: 1
      }
    ]
  },
  ORBITM_B_KIT: {
    numberOfSamplesIncluded: 3,
    samplesIncluded: [
      {
        sampleType: SAMPLE_TYPES.K2EDTA_TUBE,
        kitType: KIT_TYPES.ORBITM_B_KIT,
        quantity: 1
      },
      {
        sampleType: SAMPLE_TYPES.CRYOVIALS_TUBE,
        kitType: KIT_TYPES.ORBITM_B_KIT,
        quantity: 2
      }
    ]
  },
  ORBITM_S_KIT: {
    numberOfSamplesIncluded: 2,
    samplesIncluded: [
      {
        sampleType: SAMPLE_TYPES.COPAN_FLOQ_SWAB,
        kitType: KIT_TYPES.ORBITM_S_KIT,
        quantity: 1
      },
      {
        sampleType: SAMPLE_TYPES.OMNIGENE_GUT_TUBE,
        kitType: KIT_TYPES.ORBITM_S_KIT,
        quantity: 1
      }
    ]
  },
  ORBITM_R_KIT: {
    numberOfSamplesIncluded: 1,
    samplesIncluded: [
      {
        sampleType: SAMPLE_TYPES.K2EDTA_TUBE,
        kitType: KIT_TYPES.ORBITM_R_KIT,
        quantity: 1
      },
      {
        sampleType: SAMPLE_TYPES.CRYOVIALS_TUBE,
        kitType: KIT_TYPES.ORBITM_R_KIT,
        quantity: 1
      }
    ]
  },
  NON_PHYSICAL_KIT: null
})

export { KIT_TYPES, SAMPLE_TYPES, KIT_SAMPLE_BUNDLE, KIT_LABELS, SampleTypeLabels }
