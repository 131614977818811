<template>
  <div class="row">
    <div class="col-12">
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <th
              v-for="(col, key) in tableCol"
              :key="`tableColumns-${key}`"
              class="clickable"
              @click="sortBy(key)"
            >
              {{ col.name }}
              <span v-if="tableSort == key">
                <i
                  v-if="tableCol[tableSort]['sort'] == 'asc'"
                  class="fa fa-chevron-down"
                  aria-hidden="true"
                />
                <i
                  v-else
                  class="fa fa-chevron-up"
                  aria-hidden="true"
                />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="researcher in listItems"
            :key="`researcher-${researcher.referralCode}`"
          >
            <td>{{ researcher.name }}</td>
            <td />
            <td>{{ researcher.referralCode }}</td>
            <td>0</td>
            <td class="text-right">
              <a
                href="#"
                class="btn btn-outline-primary pt-1 pb-1 pl-3 pr-3"
                @click.prevent="viewProfessional(researcher)"
              >Edit</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="listItems.length < 1"
        class="text-center"
      >
        No professionals found<span v-if="search.length > 0">
          for this search</span>.
      </div>
    </div>
  </div>
</template>

<script>
import UserMixins from '@/mixins/userProfile'
import _ from 'lodash'

export default {
  name: 'ResearchTable',
  components: {},
  mixins: [UserMixins],
  props: ['showData', 'search'],
  data: function () {
    return {
      tableSort: 'name',
      items: [],
      tableCol: {
        name: { sort: 'asc', name: 'Name' },
        state: { sort: 'asc', name: 'State' },
        referralCode: { sort: 'asc', name: 'Referral Code' },
        usage: { sort: 'asc', name: 'Usage' }
      }
    }
  },
  computed: {
    listItems () {
      let self = this
      let data = self.items
      let serarchStr = self.search.toLowerCase()

      if (self.search.length > 0) {
        data = _.filter(data, function (o) {
          return (
            o.name.toLowerCase().includes(serarchStr) ||
            // o.practice.toLowerCase().includes(serarchStr) ||
            // o.state.toLowerCase().includes(serarchStr) ||
            o.referralCode.toLowerCase().includes(serarchStr)
          )
        })
      }

      return _.orderBy(
        data,
        [this.tableSort],
        [this.tableCol[this.tableSort]['sort']]
      )
    }
  },
  watch: {
    showData (oldVal, newVal) {
      this.fetchData()
    }
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    viewProfessional (obj) {
      this.$emit('selectProfessional', obj)
    },
    sortBy (col) {
      if (this.tableSort === col) {
        if (this.tableCol[col].sort === 'asc') {
          this.tableCol[col].sort = 'desc'
        } else {
          this.tableCol[col].sort = 'asc'
        }
      } else {
        this.tableSort = col
      }
    },
    fetchData () {
      let self = this
      this.$store
        .dispatch('getInformedProfessional')
        .then((response) => {
          self.items = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chart-wrap {
  position: relative;
  height: 200px;
  margin: 50px auto;
}

em {
  font-size: 17px;
  opacity: 0.7;
  max-width: 500px;
  margin: 0px auto;
}
.padded-row {
  padding-bottom: 0;
  @media (min-width: 992px) {
    padding-bottom: 1rem;
  }
}

.mb-4 {
  margin-bottom: 1.25rem !important;
  @media (min-width: 992px) {
    margin-bottom: 1.5rem !important;
  }
}

.table-hover tr {
  cursor: pointer;
}

.dashboard {
  //padding-bottom:2.5rem;
  padding-bottom: 100px;
}

.clickable {
  cursor: pointer;
}
</style>
