<template>
  <div class="container">
    <header class="page-header">
      <h1>Staff List</h1>
    </header>
    <StaffListTable class="box box-shadow" />
  </div>
</template>

<script>
import StaffListTable from '@/components/elements/StaffListTable'
export default {
  name: 'StaffList',
  components: { StaffListTable }
}
</script>
