<template>
  <div class="card general-info">
    <h3>General Info</h3>
    <div class="row">
      <div class="col-lg-2 mb-3">Name:</div>
      <div>{{ customer.name }}</div>
    </div>
    <div class="row">
      <div class="col-lg-2 mb-3">User Name:</div>
      <div class="max80">
        {{ customer.email }}
        <input id="customer-email" type="hidden" :value="customer.email" />
        <a
          href="#"
          class="ml-2 copy-email"
          @click.prevent="copyToClipboard(customer.email)"
        >
          <font-awesome-icon :icon="['fa', 'fa-copy']" />
          Copy To Clipboard
        </a>
        <a
          href="#"
          class="ml-2 copy-email"
          @click.prevent="changeEmail = !changeEmail"
        >
          <font-awesome-icon :icon="['fa', 'fa-pencil']" />
          Change Username/Email
        </a>
        <a
          href="#"
          class="ml-2 copy-email"
          @click.prevent="setTemporaryPassword"
        >
          <font-awesome-icon :icon="['fa', 'fa-unlock']" />
          Set Temporary Password
        </a>
      </div>
    </div>

    <!-- Change email -->
    <div v-if="changeEmail" class="row">
      <div class="col-lg-2 mb-3">Change Username/Email:</div>
      <div class="col-lg-4">
        <b-form-input
          v-model="newUsername"
          v-validate="'required|email'"
          name="Username"
          :class="{ 'is-invalid': errors.has('username') }"
          placeholder="Enter new username/email"
        />
        <div
          v-if="errors.has('Username')"
          class="invalid-feedback"
          :class="{ 'd-block': errors.has('Username') }"
        >
          {{ errors.first('Username') }}
        </div>
      </div>
      <div class="col-lg-2">
        <b-button variant="primary" @click="updateUsername"> Update </b-button>
      </div>
    </div>

    <!-- Set password -->
    <div v-if="displayPassword" class="row">
      <div class="col-lg-2 mb-3">Password is set to:</div>
      <div class="col-lg-2">
        <b>{{ tempPassword }}</b>
      </div>
      <div class="">
        <a
          href="#"
          class="ml-2 copy-email"
          @click.prevent="copyToClipboard(tempPassword)"
        >
          <font-awesome-icon :icon="['fa', 'fa-copy']" />
          Copy To Clipboard
        </a>
      </div>
    </div>

    <!-- Account info -->
    <div class="row">
      <div class="col-lg-2 mb-3">Account Type:</div>
      <div v-if="!showAccountType">
        {{ getCustomerAccountType }}
      </div>
      <select v-if="showAccountType" v-model="accountType">
        <option value="ADULT">Adult</option>
        <option value="GUARDIAN">Guardian</option>
        <option value="UNDERAGE">Underage</option>
      </select>
      <a
        v-if="!showAccountType"
        href="#"
        class="pl-3"
        @click.prevent="showAccountType = true"
        >Change</a
      >
    </div>
    <div v-if="customer.guardianFirstName" class="row">
      <div class="col-lg-2 mb-3">Guardian First Name:</div>
      <div>{{ customer.guardianFirstName }}</div>
    </div>
    <div v-if="customer.guardianLastName" class="row">
      <div class="col-lg-2 mb-3">Guardian Last Name:</div>
      <div>{{ customer.guardianLastName }}</div>
    </div>
    <div class="row">
      <div class="col-lg-2 mb-3">Address:</div>
      <div>{{ customerAddress }}</div>
    </div>
    <div class="row">
      <div class="col-lg-2 mb-3">Phone:</div>
      <div>{{ customerPhoneNumber }}</div>
    </div>
    <div class="row">
      <div class="col-lg-2 mb-3">Enabled:</div>
      <div>
        {{ customer.enabled ? 'Yes' : 'No' }}
        <a
          v-if="!customer.enabled"
          href="#"
          class="pl-3"
          @click.prevent="onClickActivate"
          >Activate</a
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 mb-3">Agree to data usage:</div>
      <div>
        <input
          type="checkbox"
          :checked="customer.agreeToDataUsage"
          @change="onClickAgreeToDataUsage"
        />
      </div>
    </div>
    <div v-if="customer.underAge" class="row">
      <div class="col-lg-2 mb-3">Parental Consent:</div>
      <div>
        <div v-if="customer.parentConsented === null">
          Pending review
          <label
            class="btn btn-file-dynamic btn-outline-primary"
            @click="onClickApprove"
            >Approve Consent</label
          >
          <label
            class="btn btn-file-dynamic btn-outline-primary"
            @click="onClickReject"
            >Reject Consent</label
          >
          <label
            class="btn btn-file-dynamic btn-outline-primary"
            @click="onClickDownload"
            >Download Consent</label
          >
        </div>
        <div v-if="customer.parentConsented === true">
          Consented and Approved
          <label
            class="btn btn-file-dynamic btn-outline-primary"
            @click="onClickDownload"
            >Download Consent</label
          >
        </div>
        <div v-if="customer.parentConsented === false">
          No consent received yet or consent rejected need new consent
          <FileUploadButton
            text="Click to upload"
            :loading="pending"
            :success="success"
            :error="isError"
            :error-message="errorDisplay"
            @onChange="onChange"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 mb-3">Subscriptions:</div>
      <span v-if="allSubscriptions && validSubscriptions.length < 1">
        No subscriptions
      </span>
      <a v-else href="#" @click.prevent="showSubscription = !showSubscription">
        {{ `${showSubscription ? 'Hide' : 'Show'}` }}
      </a>
    </div>
    <div v-if="showSubscription">
      <div
        v-for="subscription in validSubscriptions"
        :key="subscription.id"
        class="col-12 mb-3 row"
      >
        <span :class="subscription.boldFontClass" class="col-2 text-center"
          >{{ subscription.id }}
        </span>
        <span :class="subscription.boldFontClass" class="col-4 text-center"
          >{{ subscription.type }}
        </span>
        <span :class="subscription.boldFontClass" class="col-2 text-center"
          >{{ subscription.subscribedDate }}
        </span>
        <span :class="subscription.boldFontClass" class="col-2 text-center"
          >{{ subscription.nextBillingDate }}
        </span>
        <span :class="subscription.boldFontClass" class="col-2 text-center"
          >{{ subscription.cancelledDate }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadButton from '@/components/elements/FileUploadButton'
import UserMixins from '@/mixins/userProfile'
import { Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import moment from 'moment'

export default {
  name: 'CustomerInfo',
  components: {
    FileUploadButton
  },
  mixins: [UserMixins],
  props: ['customer', 'tempPassword'],
  data() {
    return {
      showAccountType: false,
      accountType: '',
      changeEmail: false,
      newUsername: null,
      displayPassword: false,
      showSubscription: false,
      allSubscriptions: null
    }
  },
  computed: {
    validSubscriptions() {
      let validSubscriptions = []
      const subscriptionHeader = {
        id: `Kit ID`,
        type: `Frequncy`,
        subscribedDate: `Subscribed Date`,
        nextBillingDate: `Next Billing Date`,
        cancelledDate: `Cancelled Date`,
        boldFontClass: `font-weight-bold`
      }
      if (this.allSubscriptions) {
        for (let i = 0; i < this.allSubscriptions.length; i++) {
          const subscription = this.allSubscriptions[i]
          const kits = subscription.kits
          for (let j = 0; j < kits.length; j++) {
            let subscriptionPerKit = {}
            subscriptionPerKit.id = kits[j].id
            subscriptionPerKit.type = subscription.type
            subscriptionPerKit.subscribedDate = this.formatDateString(
              subscription.subscribedDate
            )

            subscriptionPerKit.nextBillingDate =
              subscription.cancelledDate === null
                ? this.formatDateString(subscription.nextBillingDate)
                : 'Cancelled'
            subscriptionPerKit.cancelledDate =
              subscription.cancelledDate === null
                ? `-`
                : this.formatDateString(subscription.cancelledDate)
            subscriptionPerKit.dateToOrder =
              subscription.cancelledDate === null
                ? subscriptionPerKit.nextBillingDate
                : 0
            validSubscriptions.push(subscriptionPerKit)
          }
        }
      }

      if (validSubscriptions.length > 0) {
        validSubscriptions.unshift(subscriptionHeader)
      }
      const orderedValidSubscriptions = validSubscriptions.sort(
        (subA, subB) => {
          return -1 * (new Date(subA.dateToOrder) - new Date(subB.dateToOrder))
        }
      )
      return orderedValidSubscriptions
    },
    getCustomerAccountType() {
      return this.getAccountType(this.customer)
    },
    customerAddress() {
      return (this.customer.address && this.customer.address.trim()) || '-'
    },
    customerPhoneNumber() {
      return this.customer.phoneNumber || '-'
    },
    pending: function () {
      return this.$store.state.POST_PARENTAL_CONSENT_PENDING
    },
    success: function () {
      return this.$store.state.POST_PARENTAL_CONSENT_SUCCESS
    },
    error: function () {
      return this.$store.state.POST_PARENTAL_CONSENT_FAILURE || false
    },
    errorDisplay: function () {
      if (this.error) {
        return this.error
      }
      return this.localError
    },
    isError: function () {
      if (this.error) {
        return true
      }
      return this.errorDisplay !== null
    }
  },
  watch: {
    accountType() {
      this.onChangeAccountType()
    },
    customer() {
      this.getAllSubscriptions()
      this.showSubscription = false
    }
  },
  mounted: function () {
    this.getAllSubscriptions()
  },
  methods: {
    getAllSubscriptions() {
      this.$store
        .dispatch('getAllSubscriptions', this.customer)
        .then((response) => {
          this.allSubscriptions = response.data
        })
    },
    formatDateString(dateString) {
      if (dateString && typeof dateString === 'string') {
        return moment(dateString).format('DD/MM/YYYY')
      }
      return dateString
    },
    onClickActivate() {
      this.$emit('onClickActivateUser', this.customer)
    },
    onChange: function (formData) {
      formData.userId = this.customer.id
      this.$store
        .dispatch('postParentalConsentForm', formData)
        .then((response) => {
          this.$store
            .dispatch('getUserById', this.customer.id)
            .then((response) => {
              this.customer = response.data
            })
        })
    },
    onChangeAccountType: function () {
      let payload = {
        id: this.customer.id,
        underAge: this.accountType !== 'ADULT',
        guardian: this.accountType === 'GUARDIAN'
      }
      this.$store.dispatch('patchUser', payload).then((response) => {
        this.customer = response.data
        this.showAccountType = false
      })
    },
    onClickAgreeToDataUsage: function () {
      let payload = {
        id: this.customer.id,
        agreeToDataUsage: !this.customer.agreeToDataUsage
      }
      this.$store.dispatch('patchUser', payload).then((response) => {
        this.customer = response.data
      })
    },
    onClickDownload: function () {
      this.$store
        .dispatch('getParentalConsentForm', this.customer.id)
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers['content-type']
          })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          if (response.headers['content-type'] === 'application/pdf') {
            link.download = 'Consent.pdf'
          } else if (response.headers['content-type'] === 'image/png') {
            link.download = 'Consent.jpg'
          } else if (response.headers['content-type'] === 'image/tiff') {
            link.download = 'Consent.tif'
          } else {
            link.download = 'Consent.jpg'
          }
          link.click()
        })
    },
    onClickApprove: function () {
      let payload = {
        id: this.customer.id,
        parentConsented: true
      }
      this.$store.dispatch('patchUser', payload).then((response) => {
        this.customer = response.data
      })
    },
    onClickReject: function () {
      let payload = {
        id: this.customer.id,
        parentConsented: false
      }
      this.$store.dispatch('patchUser', payload).then((response) => {
        this.customer = response.data
      })
    },
    async copyToClipboard(content) {
      try {
        await navigator.clipboard.writeText(content)
        alert('Is copied to clipboard')
      } catch (e) {
        alert('Oops, unable to copy')
      }
    },
    updateUsername() {
      this.$validator
        .validate()
        .then(() => {
          if (!this.errors.any()) {
            this.$store
              .dispatch('patchUser', {
                id: this.customer.id,
                email: this.newUsername
              })
              .then((response) => {
                Notification({
                  title: 'User email address updated.',
                  style: NOTIFICATION_STYLES.SUCCESS
                })
                this.$emit('update:customer', response.data)
                this.changeEmail = false
                this.newUsername = null
              })
              .catch((error) => {
                Notification({
                  text: error.response.data.message,
                  style: NOTIFICATION_STYLES.ERROR
                })
                console.error(error)
              })
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async setTemporaryPassword() {
      this.displayPassword = !this.displayPassword
      if (!this.displayPassword) {
        return
      }

      const doReset = await Notification({
        title: `Reset the password for:<br/>${this.customer.name}<br/>${this.customer.email}`,
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (doReset.value) {
        this.$emit('setTemporaryPassword', this.customer)
      } else {
        this.displayPassword = false
      }
    }
  }
}
</script>

<style scoped>
.general-info {
  padding: 2em;
}
.copy-email {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  padding: 0px 0px 0px 15px;
}
.max80 {
  max-width: 80%;
}
</style>
