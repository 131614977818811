const referralStatus = Object.freeze({
  CREATED: "CREATED",
  SENT: "SENT",
  ORDERED: "ORDERED",
  ACTIONED: "ACTIONED",
  REPORT_AVAILABLE: "REPORT_AVAILABLE",
  CANCELLED: "CANCELLED",
})

const referralStatusLabels = Object.freeze({
  CREATED: "Created",
  SENT: "Sent",
  ORDERED: "Ordered",
  ACTIONED: "Actioned",
  REPORT_AVAILABLE: "Report Available",
  CANCELLED: "Cancelled",
})

export { referralStatus, referralStatusLabels }
