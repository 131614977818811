<template>
  <div
    v-show="pendingApplications.length > 0"
    class="row"
  >
    <div class="col-12">
      <h3 class="mt-5">
        Pending Applications
      </h3>
      <table class="table">
        <tbody>
          <tr
            v-for="item in pendingApplications"
            :key="item.id"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.practitionerType }}</td>
            <td>{{ item.practiceName }}</td>
            <td>{{ item.practiceState }}</td>
            <td>{{ item.registeringBody }} ({{ item.registrationNumber }})</td>
            <td class="text-right">
              <a
                href="#"
                @click.prevent="viewProfessional(item)"
              >View application</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ModalHCPApplication
      v-if="professionalObj"
      :show="showHCPApplication"
      :obj="professionalObj"
      @close="showHCPApplication = false"
    />
  </div>
</template>

<script>
import UserMixins from '@/mixins/userProfile'
import ModalHCPApplication from '@/components/models/ModalHCPApplications.vue'
import _ from 'lodash'

export default {
  name: 'ResearchTable',
  components: {
    ModalHCPApplication
  },
  mixins: [UserMixins],
  props: ['showData', 'search', 'items'],
  data: function () {
    return {
      tableSort: 'name',
      showHCPApplication: false,
      professionalObj: null,
      tableCol: {
        name: { sort: 'asc', name: 'Name' },
        practice: { sort: 'asc', name: 'Practice' },
        state: { sort: 'asc', name: 'State' },
        referralCode: { sort: 'asc', name: 'Referral Code' },
        usage: { sort: 'asc', name: 'Usage' }
      }
    }
  },
  computed: {
    pendingApplications () {
      return _.filter(this.items, function (o) {
        return o.approved === null
      })
    }
  },
  watch: {
    showData (oldVal, newVal) {
      this.fetchData()
    }
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    viewAppplication () {
      this.showHCPApplication = true
    },
    viewProfessional (obj) {
      this.$emit('selectProfessional', obj)
    },
    sortBy (col) {
      if (this.tableSort === col) {
        if (this.tableCol[col].sort === 'asc') {
          this.tableCol[col].sort = 'desc'
        } else {
          this.tableCol[col].sort = 'asc'
        }
      } else {
        this.tableSort = col
      }
    },
    fetchData () {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chart-wrap {
  position: relative;
  height: 200px;
  margin: 50px auto;
}

em {
  font-size: 17px;
  opacity: 0.7;
  max-width: 500px;
  margin: 0px auto;
}
.padded-row {
  padding-bottom: 0;
  @media (min-width: 992px) {
    padding-bottom: 1rem;
  }
}

.mb-4 {
  margin-bottom: 1.25rem !important;
  @media (min-width: 992px) {
    margin-bottom: 1.5rem !important;
  }
}

.table-hover tr {
  cursor: pointer;
}

.dashboard {
  //padding-bottom:2.5rem;
  padding-bottom: 100px;
}

.clickable {
  cursor: pointer;
}
</style>
