<template>
  <transition name="slide-fade">
    <div class="dashboard">
      <div class="container">
        <header>
          <div class="row">
            <div class="col-md-8 page-header">
              <h1 v-if="customerObj">
                {{ customerObj.name }}
              </h1>
              <h1 v-else>Customers</h1>
            </div>
            <div class="col-md-4 pt-3">
              <search-user @customer-selected="fetchCustomer" />
            </div>
          </div>
        </header>
        <div v-if="customerObj" class="row">
          <div class="col-12">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <customer-info
                    :temp-password="tempPassword"
                    :customer.sync="customerObj"
                    @onClickActivateUser="onClickActivateUser"
                    @setTemporaryPassword="setTemporaryPassword"
                  />
                </tr>
                <tr v-if="kits.length === 0">
                  <h2>
                    <b-badge>No kits found for {{ customerObj.name }}</b-badge>
                  </h2>
                </tr>
                <tr v-if="insightKits && insightKits.length">
                  <h2>
                    <b-badge>Owns the following Insight kits:</b-badge>
                  </h2>
                  <ul class="list-group">
                    <kit-item
                      v-for="kit in insightKits"
                      :key="kit.id"
                      :kit="kit"
                      :kits="otherKits(kit.id)"
                      :owner="customerObj"
                      :can-copy-questionnaire="true"
                      @questionnaire-copied="reloadKits()"
                      @onClickAdminActions="onClickAdminActions(kit)"
                      @kit-transferred="reloadKits()"
                    />
                  </ul>
                </tr>
                <tr v-if="researchKits && researchKits.length">
                  <h2>
                    <b-badge>Owns the following Research kits:</b-badge>
                  </h2>
                  <ul class="list-group">
                    <kit-item
                      v-for="kit in researchKits"
                      :key="kit.id"
                      :kit="kit"
                      :kits="otherKits(kit.id)"
                      :owner="customerObj"
                      :can-copy-questionnaire="true"
                      @questionnaire-copied="reloadKits()"
                      @onClickAdminActions="onClickAdminActions(kit)"
                      @kit-transferred="reloadKits()"
                    />
                  </ul>
                </tr>
                <tr v-if="sentInsightKits && sentInsightKits.length">
                  <h2>
                    <b-badge>Gave away the following Insight kits:</b-badge>
                  </h2>
                  <ul class="list-group">
                    <kit-item
                      v-for="kit in sentInsightKits"
                      :key="kit.id"
                      :kit="kit"
                      :owner="customerObj"
                      :can-copy-questionnaire="false"
                      @onClickAdminActions="onClickAdminActions(kit)"
                      @kit-transferred="reloadKits()"
                    />
                  </ul>
                </tr>
                <tr v-if="sentResearchKits && sentResearchKits.length">
                  <h2>
                    <b-badge>Gave away the following Research kits:</b-badge>
                  </h2>
                  <ul class="list-group">
                    <kit-item
                      v-for="kit in sentResearchKits"
                      :key="kit.id"
                      :kit="kit"
                      :owner="customerObj"
                      :can-copy-questionnaire="false"
                      @onClickAdminActions="onClickAdminActions(kit)"
                      @kit-transferred="reloadKits()"
                    />
                  </ul>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <ModalAdminActions
        :show="showAdminActionsModal"
        :kit="selectedKit"
        @close="showAdminActionsModal = false"
        @onClickCancelShipping="onClickCancelShipping"
        @onClickMarkPriority="onClickMarkPriority"
        @onClickRequestReplacementFree="onClickRequestReplacementFree"
        @onClickRequestReplacementCharge="onClickRequestReplacementCharge"
        @onClickResultsAvailableCharge="onClickResultsAvailableCharge"
        @onClickSetStatus="onClickSetStatus"
        @onClickSendKit="onClickSendKit"
      />
    </div>
  </transition>
</template>

<script>
import searchUser from '@/components/elements/searchUser'
import UserMixins from '@/mixins/userProfile'
import CustomerInfo from '@/components/elements/CustomerInfo'
import KitItem from '@/components/elements/KitItem'
import ModalAdminActions from '@/components/models/ModalAdminActions'
import productType from '@/enums/productTypes'
import { Loader } from '@microbadevs/library'

export default {
  name: 'Customers',
  components: {
    ModalAdminActions,
    CustomerInfo,
    KitItem,
    searchUser
  },
  mixins: [UserMixins],
  data: function () {
    return {
      showPurchaseModal: false,
      showAdminActionsModal: false,
      customerObj: null,
      selectedKit: {},
      kits: [],
      sentKits: [],
      tempPassword: null
    }
  },
  computed: {
    insightKits: function () {
      return this.kits.filter((kit) => kit.productType === productType.CONSUMER)
    },
    researchKits: function () {
      return this.kits.filter((kit) => kit.productType === productType.RESEARCH)
    },
    sentInsightKits: function () {
      return this.sentKits.filter(
        (kit) => kit.productType === productType.CONSUMER
      )
    },
    sentResearchKits: function () {
      return this.sentKits.filter(
        (kit) => kit.productType === productType.RESEARCH
      )
    },
    purchaseObject: function () {
      return this.customerObj
        ? {
            purchaserEmail: this.customerObj.email,
            purchaserFirstName: this.customerObj.firstName,
            purchaserLastName: this.customerObj.lastName,
            orderType: 'REPLACEMENT',
            replacingKitId: this.selectedKit.id,
            orderItems: [{}],
            amount: 0
          }
        : null
    }
  },
  mounted: function () {},
  methods: {
    fetchCustomer(obj = null) {
      this.customerObj = obj || this.customerObj
      this.customerObj.address = this.getAddress(this.customerObj)

      Loader.show()
      const kitsPromise = this.$store.dispatch(
        'getKitsByUserId',
        this.customerObj.id
      )
      const sentKitsPromise = this.$store.dispatch(
        'getSentKitsByUserId',
        this.customerObj.id
      )
      Promise.all([kitsPromise, sentKitsPromise])
        .then(([kitsResponse, sentKitsResponse]) => {
          this.kits = kitsResponse.data
          this.sentKits = sentKitsResponse.data
        })
        .catch((response) => {
          console.error('Error while getting the kits:', response)
          alert(
            'Something has gone wrong. Please retry. If the problem persists, please contact support'
          )
        })
        .finally(() => {
          Loader.hide()
        })
    },

    user: function () {
      return this.$store.state.GET_USER_RESPONSE
    },

    onClickAdminActions: function (kit) {
      this.selectedKit = kit
      this.showAdminActionsModal = true
    },

    onClickCancelShipping: function () {
      // console.log('Cancel shipping for kit ' + this.selectedKit.id)
      this.$store
        .dispatch('cancelKitShipping', this.selectedKit.id)
        .then((ok) => {
          this.fetchCustomer()
        })
        .catch((error) => {
          console.log(error)
          alert('ERROR: ' + error.response.data.message)
        })
    },

    onClickMarkPriority() {
      // console.log('Mark kit as priority ' + this.selectedKit.id)
      this.$store
        .dispatch('putSample', {
          id: this.selectedKit.sampleId,
          priority: true
        })
        .then((ok) => {
          this.fetchCustomer()
        })
        .catch((error) => {
          console.error('Error while marking for priority:', error)
          alert('ERROR: ' + error.response.data.message)
        })
    },

    onClickRequestReplacementFree: function (sampleStatus) {
      // console.log('Request free replacement for kit ' + this.selectedKit.id)
      const paymentId = `REPLACEMENT OF KIT ${this.selectedKit.id}`
      const description = `Free replacement kit`
      const replacingKitId = this.selectedKit.id
      const replacingKitSampleStatus = sampleStatus
      const payload = {
        ...this.purchaseObject,
        paymentId,
        description,
        replacingKitId,
        replacingKitSampleStatus
      }
      this.$store
        .dispatch('postOrder', payload)
        .then((ok) => {
          this.fetchCustomer()
        })
        .catch((error) => {
          console.error('Error while requesting replacement', error)
          alert('ERROR: ' + error.response.data.message)
        })
    },

    onClickRequestReplacementCharge: function (sampleStatus) {
      // console.log('Request replacement at a charge for kit ' + this.selectedKit.id)
      // console.log('Set sample status to ' + sampleStatus)
      let payload = {
        id: this.selectedKit.sampleId,
        sampleStatus: sampleStatus
      }
      this.$store
        .dispatch('putSample', payload)
        .then((ok) => {
          this.fetchCustomer()
        })
        .catch((error) => {
          alert('ERROR: ' + error.response.data.message)
        })
    },
    onClickResultsAvailableCharge() {
      let payload = {
        id: this.selectedKit.sampleId,
        sampleStatus: 'RESULTS_AVAILABLE'
      }
      this.$store
        .dispatch('putSample', payload)
        .then((ok) => {
          this.fetchCustomer()
        })
        .catch((error) => {
          alert('ERROR: ' + error.response.data.message)
        })
    },

    onClickSetStatus(status, action) {
      let payload = {
        id: this.selectedKit.sampleId,
        sampleStatus: status
      }
      this.$store
        .dispatch(action, payload)
        .then((ok) => {
          this.fetchCustomer()
        })
        .catch((error) => {
          alert('ERROR: ' + error.response.data.message)
        })
    },
    onClickSendKit: function () {
      const payload = {
        kitId: this.selectedKit.id,
        email: this.customerObj.email
      }
      this.$store.dispatch('sendKit', payload).catch((error) => {
        console.error('Error while retrieving customer information', error)
        alert('ERROR: ' + error.response.data.message)
      })
    },
    onClickActivateUser: function (user) {
      let payload = {
        id: user.id,
        enabled: true
      }
      this.$store.dispatch('patchUser', payload).then((response) => {
        this.customerObj = response.data
      })
    },
    setTemporaryPassword: function () {
      Loader.show()
      let payload = {
        principal: this.customerObj.email,
        userType: this.customerObj.type || 'CONSUMER'
      }
      this.$store
        .dispatch('postPasswordTemporary', payload)
        .then((response) => {
          if (response.data.password) {
            this.tempPassword = response.data.password
          } else {
            throw new Error('No temporary password received')
          }
        })
        .catch((response) => {
          console.error(
            'Error while setting temporary password for customer',
            response,
            this.customerObj
          )
          alert(
            'Something has gone wrong. Please retry. If the problem persists, please contact support'
          )
        })
        .finally(() => {
          Loader.hide()
        })
    },
    otherKits: function (kitId) {
      return [...this.kits, ...this.sentKits].filter((x) => x.id !== kitId)
    },
    reloadKits: function () {
      this.fetchCustomer()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.chart-wrap {
  position: relative;
  height: 200px;
  margin: 50px auto;
}

em {
  font-size: 17px;
  opacity: 0.7;
  max-width: 500px;
  margin: 0px auto;
}

.padded-row {
  padding-bottom: 0;
  @media (min-width: 992px) {
    padding-bottom: 1rem;
  }
}

.mb-4 {
  margin-bottom: 1.25rem !important;
  @media (min-width: 992px) {
    margin-bottom: 1.5rem !important;
  }
}

.dashboard {
  //padding-bottom:2.5rem;
  padding-bottom: 100px;
}

.badge {
  margin-top: 20px;
}
</style>
