const queues = Object.freeze({
  NOTIFICATION: process.env.VUE_APP_NOTIFICATION_QUEUE,
  MENTAL_HEALTH_SURVEY_PROCESSING:
    process.env.VUE_APP_QUEUE_MENTAL_HEALTH_SURVEY_PROCESSING,
  FFQ_PROCESSING: process.env.VUE_APP_QUEUE_FFQ_PROCESSING,
  PAQ_PROCESSING: process.env.VUE_APP_QUEUE_PAQ_PROCESSING,
  RESULT_ORCHESTRATOR: process.env.VUE_APP_QUEUE_RESULT_ORCHESTRATOR, // TODO: _V2
  RESULT_PROCESSOR: process.env.VUE_APP_QUEUE_RESULT_PROCESSOR,
  REPORT_QC_ORCHESTRATOR: process.env.VUE_APP_QUEUE_REPORT_QC_ORCHESTRATOR,
  PDF_GENERATOR: process.env.VUE_APP_QUEUE_PDF_GENERATOR,
  LAB_PROCESSING: process.env.VUE_APP_QUEUE_LAB_PROCESSING
})

export default queues
