export default {
  methods: {
    isValidAddress: function (inputVal) {
      return (
        inputVal.firstName &&
        inputVal.firstName !== '' &&
        inputVal.lastName &&
        inputVal.addressLine1 &&
        inputVal.suburb &&
        inputVal.postcode
      )
    },

    isCompleteProfile: function (inputVal) {
      return (
        inputVal.firstName &&
        inputVal.firstName !== '' &&
        inputVal.lastName !== '' &&
        inputVal.lastName &&
        inputVal.addressLine1 !== '' &&
        inputVal.addressLine1 &&
        inputVal.suburb !== '' &&
        inputVal.suburb &&
        inputVal.postcode !== '' &&
        inputVal.postcode &&
        inputVal.agreeToDataUsage != null
      )
    },

    needsParentalApproval: function (inputVal) {
      return (
        inputVal &&
        inputVal.parentConsented === false &&
        inputVal.underAge === true
      )
    },

    getAccountType: function (customer) {
      if (customer.guardian) {
        return 'Guardian'
      }
      if (customer.underAge) {
        return 'Under Age'
      }
      return 'Adult'
    },

    getAddress: function (customer) {
      return `${customer.addressLine1 ? customer.addressLine1 : ''} ${
        customer.addressLine2 ? customer.addressLine2 : ''
      } ${customer.suburb ? customer.suburb : ''} ${
        customer.state ? customer.state : ''
      } ${customer.postcode ? customer.postcode : ''}`
    }
  }
}
