export default class Address {
  constructor (jsonObj) {
    this.externalIdentifier = jsonObj.externalIdentifier || null
    this.provider = jsonObj.provider || null
    this.addressLine1 = jsonObj.addressLine1 || null
    this.state = jsonObj.state || null
    this.postcode = jsonObj.postcode || null
    this.suburb = jsonObj.suburb || null
    this.country = jsonObj.country || null
  }
}
