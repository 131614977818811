<script>
import { sampleStatus } from '@/enums/kitItemStatus'
import sampleService from '@/services/sampleService'
import {
  Failure,
  Loader,
  Notification,
  NOTIFICATION_STYLES,
  Success
} from '@microbadevs/library'
import SampleToolbar from '@/components/elements/SampleToolbar'
import QCFilter from '@/components/qcReport/qcFilter' // TODO: refactor the name of this component
import productService from '@/services/productService'
import vSelect from 'vue-select'
import authService from '@/services/authService'
import NotificationQueueService from '@/services/notificationQueueService'
import queues from '@/enums/queues'
import websocketMessageTypes from '@/enums/websocketMessageTypes'
import KitTimeline from '@/components/elements/KitTimeline.vue'

const defaultResultsFields = [
  {
    key: 'sampleIdentifier',
    label: 'Sample Identifier'
  },
  {
    key: 'sampleStatus',
    label: 'Sample Status'
  }
]

export default {
  name: 'ResultsProcess',
  components: {
    KitTimeline,
    SampleToolbar,
    QCFilter,
    vSelect
  },
  data() {
    return {
      samples: [],
      selectedSamples: [],
      sampleStatuses: sampleStatus,
      sampleFilters: {},
      productData: null,
      selectedProduct: null,
      excludedProductListProductTypes: []
    }
  },
  computed: {
    samplesWithStatus() {
      return this.samples?.map((sample) => {
        if (
          sample.sampleStatus === this.sampleStatuses.READY_FOR_ACCESSIONING
        ) {
          return {
            ...sample,
            _rowVariant: 'success'
          }
        }
        if (
          sample.sampleStatus === this.sampleStatuses.RESULTS_PROCESSING_FAILED
        ) {
          return {
            ...sample,
            _rowVariant: 'danger'
          }
        }
        if (sample.sampleStatus === this.sampleStatuses.ACCESSIONING) {
          return {
            ...sample,
            _rowVariant: 'warning'
          }
        }
      })
    },
    products() {
      return !this.productData
        ? []
        : this.productData
            .map((product) => {
              return {
                id: product.productId,
                name: product.productName,
                label: `${product.partnerCode} - ${product.productName} - ${product.productType}`,
                productType: product.productType,
                partnerCode: product.partnerCode,
                // canPreview: product.canPreview,
                // active: product.active,
                // subGrouped: product.subGrouped,
                fields: [
                  {
                    key: 'selected'
                  },
                  ...(product.fields || defaultResultsFields),
                  {
                    key: 'actions',
                    label: 'Actions'
                  }
                ]
              }
            })
            .filter(
              (product) =>
                !this.excludedProductListProductTypes.includes(
                  product.productType
                )
            )
    },
    samplesByProduct() {
      let samplesByProduct = {}
      this.products?.forEach((product) => {
        const samples = this.samplesWithStatus?.filter(
          (sample) => sample.productId === product.id
        )
        const filters = this.sampleFilters[product.id]
        samplesByProduct[product.id] = {
          samples,
          filteredSamples: samples?.filter((sample) => {
            return (
              (sample.sampleStatus === sampleStatus.RESULTS_PROCESSING_FAILED &&
                filters.errorInProcessing) ||
              (sample.sampleStatus === sampleStatus.READY_FOR_ACCESSIONING &&
                filters.readyForProcessing) ||
              (sample.sampleStatus === sampleStatus.ACCESSIONING &&
                filters.inProcessing)
            )
          }),
          samplesFailedProcessing: samples?.filter(
            (sample) =>
              sample.sampleStatus === sampleStatus.RESULTS_PROCESSING_FAILED
          ),
          samplesReadyForProcessing: samples?.filter(
            (sample) =>
              sample.sampleStatus === sampleStatus.READY_FOR_ACCESSIONING
          ),
          samplesInProcessing: samples?.filter(
            (sample) => sample.sampleStatus === sampleStatus.ACCESSIONING
          )
        }
      })
      return samplesByProduct
    }
  },
  async mounted() {
    await this.getSamples()
    await this.fetchProductData()
  },
  methods: {
    // Get the samples based on the statues you are interested in
    async getSamples() {
      try {
        Loader.show()
        const {
          data: samplesReadyForProcessing
        } = await sampleService.getSamplesByStatus(
          sampleStatus.READY_FOR_ACCESSIONING
        )
        const {
          data: samplesResultsProcessingFailed
        } = await sampleService.getSamplesByStatus(
          sampleStatus.RESULTS_PROCESSING_FAILED
        )
        const {
          data: samplesInProcessing
        } = await sampleService.getSamplesByStatus(sampleStatus.ACCESSIONING)
        this.samples = [
          ...samplesReadyForProcessing,
          ...samplesResultsProcessingFailed,
          ...samplesInProcessing
        ]
        Loader.hide()
      } catch (error) {
        Notification({
          title: 'Failed',
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    // switch active product
    switchActiveProduct(resultProcessingProduct) {
      this.unselectAll()
      this.activeProcessingProduct = resultProcessingProduct
    },
    async processSingleResult(sampleData) {
      const userResponse = await Notification({
        title: `Begin Processing results for ${sampleData.sampleIdentifier}`,
        text: 'Are you sure?',
        style: NOTIFICATION_STYLES.QUESTION
      })
      if (userResponse.isConfirmed) {
        this.processResult(sampleData)
      }
    },
    async processResult(sampleData) {
      try {
        /* await this.$store.dispatch('putSample', {
          id: sampleData.id,
          sampleStatus: sampleStatus.RESULTS_AVAILABLE
        }) */
        // await this.getSamples() // Do we need to immediately reload all the samples?
        Loader.show({ text: 'Sending message to orchestration queue.' })
        const username = authService.getUsername()
        const payload = {
          sampleIdentifier: sampleData.sampleIdentifier
        }
        const notificationQueue = new NotificationQueueService(
          queues.RESULT_ORCHESTRATOR // TODO: _V2
        )
        notificationQueue
          .sendMessage(payload, true, websocketMessageTypes.PRIVATE, username)
          .then(() => {
            Success({ title: 'Success!' })
          })
          .catch((error) => {
            Failure({
              text:
                error?.response?.data?.message ||
                'Something gone wrong, unable to send message to process sample.'
            })
          })
        this.unselectAll()
      } catch (error) {
        Notification({
          title: 'Failed',
          text: error.response.data.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    async processSelectedSamples() {
      const userResponse = await Notification({
        title: `Begin processing the results for selected samples.`,
        text: 'Are you sure?',
        style: NOTIFICATION_STYLES.QUESTION
      })
      if (userResponse.isConfirmed) {
        this.selectedSamples.forEach((sample) => {
          this.processResult(sample)
        })
      }
    },
    unselectAll() {
      this.selectedSamples = []
    },
    selectAll() {
      console.log('selectAll')
      this.unselectAll()
      this.selectedSamples =
        this.samplesByProduct[this.activeProcessingProduct?.id].samples || []
    },
    updateFilter(filterKey, resultKey, enabled) {
      this.sampleFilters = {
        ...this.sampleFilters,
        [resultKey]: {
          ...this.sampleFilters[resultKey],
          [filterKey]: enabled
        }
      }
    },
    async reloadData() {
      await this.getSamples()
    },
    async fetchProductData() {
      try {
        Loader.show()
        const {
          data: productData
        } = await productService.getProductSpecifications()
        this.productData = [
          ...productData,
          {
            // Hardcode appended support for RESEARCH as a product
            distributorId: 8,
            partnerCode: 'mic',
            productId: null,
            productName: 'RESEARCH',
            productType: 'LBRAAS',
            description: 'Research product for Microba',
            specification: {
              'bio-service-name': 'insight',
              'bio-service-version': '2.0.0'
            }
          }
        ]
        const sampleProcessingFilters = {}
        this.productData.forEach(({ productId }) => {
          sampleProcessingFilters[productId] = {
            readyForProcessing: true,
            inProcessing: true,
            errorInProcessing: true
          }
        })
        this.sampleFilters = sampleProcessingFilters
        Loader.hide()
      } catch (error) {
        Notification({
          title: 'Failed',
          text: error.message,
          style: NOTIFICATION_STYLES.ERROR
        })
      }
    },
    setProduct() {
      if (this.selectedProduct) {
        this.switchActiveProduct(this.selectedProduct)
      }
    }
  }
}
</script>
<template>
  <div class="results-release page container">
    <header class="inline-between">
      <h1>Results Process</h1>

      <a
        href="https://microba.atlassian.net/l/c/cmmLuTwG"
        target="_blank"
        class="btn btn-sm btn-outline-warning inline-half"
        ><span class="fa fa-question-circle"></span> User guide</a
      >
    </header>

    <div class="box box-shadow">
      <label for="select-product">Select a product</label>

      <vSelect
        id="select-product"
        v-model="selectedProduct"
        placeholder="Select Product"
        :options="products"
        @input="setProduct"
      >
        <template #option="{ id, label }">
          {{ label }}
          <span class="float-right">
            <b-badge pill variant="success">
              {{ samplesByProduct[id].samplesReadyForProcessing.length }}
            </b-badge>
            <b-badge pill variant="primary">
              {{ samplesByProduct[id].samplesInProcessing.length }}
            </b-badge>
            <b-badge pill variant="danger">
              {{ samplesByProduct[id].samplesFailedProcessing.length }}
            </b-badge>
          </span>
        </template>
      </vSelect>

      <q-c-filter
        v-if="selectedProduct"
        button-size="sm"
        :success-count="
          samplesByProduct[selectedProduct.id].samplesReadyForProcessing.length
        "
        :warning-count="
          samplesByProduct[selectedProduct.id].samplesInProcessing.length
        "
        :error-count="
          samplesByProduct[selectedProduct.id].samplesFailedProcessing.length
        "
        :success-name="'Ready for Processing'"
        :warning-name="'In Processing'"
        :error-name="'Failed Processing'"
        :success-visible="sampleFilters[selectedProduct.id].readyForProcessing"
        :warning-visible="sampleFilters[selectedProduct.id].inProcessing"
        :error-visible="sampleFilters[selectedProduct.id].errorInProcessing"
        @success-toggle="
          updateFilter('readyForProcessing', selectedProduct.id, $event)
        "
        @warning-toggle="
          updateFilter('inProcessing', selectedProduct.id, $event)
        "
        @error-toggle="
          updateFilter('errorInProcessing', selectedProduct.id, $event)
        "
      />

      <div v-if="selectedProduct">
        <b-table
          hover
          :items="samplesByProduct[selectedProduct.id].filteredSamples"
          :fields="selectedProduct.fields"
          show-empty
          empty-text="There are no samples to process."
        >
          <template v-slot:cell(selected)="row">
            <b-form-checkbox
              v-model="selectedSamples"
              type="checkbox"
              :value="row.item"
            />
          </template>

          <template v-slot:cell(actions)="row">
            <div class="row">
              <SampleToolbar
                class="mr-2"
                :sample-identifier="row.item.sampleIdentifier"
                :sample-status="row.item.sampleStatus"
                :product-type="row.item.productType"
                :can-aliquot="!row.item.aliquotOfSample"
                @sampleData:updated="reloadData"
              />
              <i
                v-b-popover.hover="'Process'"
                class="fa fa-upload fa-2x mr-2"
                @click="processSingleResult(row.item)"
              />
              <i
                v-b-popover.hover="'Pipeline Info'"
                class="fa fa-info-circle fa-2x mr-2 my-auto"
                @click="row.toggleDetails"
              />
            </div>
          </template>

          <template #row-details="row">
            <b-card>
              <kit-timeline :kit-id="row.item.kitId" />
              <b-button class="pull-right" size="sm" @click="row.toggleDetails">
                Hide Details
              </b-button>
            </b-card>
          </template>
        </b-table>
        <b-button size="sm" class="mr-1" @click="selectAll()">
          Select All
        </b-button>
        <b-button size="sm" @click="unselectAll"> Unselect All </b-button>
        <div class="float-right">
          <b-button size="sm" @click="processSelectedSamples">
            Process Selected Samples
          </b-button>
        </div>
        <div class="text-right mt-2">
          <span>
            * Please enable popup to review multiple selected samples.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
