<template>
  <Maintenance
    v-if="inMaintenance"
    :date="maintenanceEndDate"
    button-text="Try again"
    @leaveButtonClick="$store.dispatch('maintenance/hide')"
  />
  <router-view v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import authService from '@/services/authService'
import {
  Maintenance,
  TokenService,
  WebSocketClient,
  NotificationQueue,
  NOTIFICATION_STYLES
} from '@microbadevs/library'

export default {
  name: 'App',
  components: {
    Maintenance
  },
  computed: {
    ...mapGetters('maintenance', ['inMaintenance', 'maintenanceEndDate']),
    dashboard: function () {
      return this.$store.state.GET_DASHBOARD_RESPONSE
    },
    userDetails: function () {
      return this.$store.state.GET_USER_RESPONSE
    }
  },
  mounted() {
    if (authService.isLoggedIn()) {
      const { accessToken, refreshToken } = TokenService.getTokenDetails()
      if (accessToken && refreshToken) {
        authService.initialize(accessToken, refreshToken)
      }
    }
  },
  watch: {
    // Handles profileCompete route on page refresh
    dashboard: function () {
      if (this.dashboard && this.dashboard.profileCompleted === false) {
        this.$bus.$emit('disableSidebar')
        this.$router.push('/profile')
      }
    },
    userDetails: function () {
      this.subscribeByEmailAddress(this.userDetails?.email)
    }
  },
  methods: {
    subscribeByEmailAddress(emailAddress) {
      const webSocketClient = new WebSocketClient(
        process.env.VUE_APP_WEBSOCKET_URL,
        authService.getAccessToken()
      )

      webSocketClient.subscribeForUserEmail(
        emailAddress,
        this.displaySubscriptionMessage
      )
    },
    displaySubscriptionMessage(message) {
      NotificationQueue({
        title: message?.message || 'Update',
        text: JSON.stringify(message?.data || ''),
        style: NOTIFICATION_STYLES.INFO
      })
    }
  }
}
</script>

<style lang="scss">
@import './styles/main';

body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.5s 0.1s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-30px);
  position: absolute;
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
