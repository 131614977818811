import * as qz from 'qz-tray'
import RSVP from 'rsvp'
import { printerOptions, printGenre } from '@/enums/printers'
import { sha256 } from 'js-sha256'
// import httpClientService from '@/services/httpClientService'
import { HTTP } from '@/http.js'

export default class printService {
  static printLabels(data, printerType, genre) {
    let { dataToPrint, isPdf } = this.prepareLabelContent(data, genre)
    this.initialize()
    return this.sendToPrinter(dataToPrint, printerType, isPdf)
  }
  static printAllSynLabSampleLabels(data, printerType, genre) {
    const bulkPrintRepetitionRules = {
      'qPCR': 2,
      'ELISA #1': 2,
      'ELISA #2': 2,
    }

    let toBePrinted = []
    data.forEach(({sampleIdentifier, workflow}) => {
      const printCount = bulkPrintRepetitionRules[workflow] || 1
      const { dataToPrint } = this.prepareLabelContent(sampleIdentifier, genre)

      for (let i = 0; i < printCount; i++) {
        toBePrinted.push(dataToPrint);
      }
    })

    this.initialize()

    return this.sendToPrinter(toBePrinted, printerType)
  }
  static prepareLabelContent(data, genre) {
    let dataToPrint
    let isPdf = false;

    switch (genre) {
      case printGenre.AUSPOST_MANIFEST:
        isPdf = true
        dataToPrint = data.manifestDat
        break
      case printGenre.AUSPOST_ADDRESS_LABELS:
        isPdf = true
        dataToPrint = data.addressLabel
        break
      case printGenre.ACTIVATION_CODE:
        dataToPrint = this.getActivationCodeLabelTemplate(data)
        break
      case printGenre.VOUCHER_CODE:
        dataToPrint = this.getVoucherCodeLabelTemplate(data)
        break
      case printGenre.KIT_OWNER:
        dataToPrint = this.getKitOwnerLabelTemplate(data)
        break
      case printGenre.BATCH_LOT:
        dataToPrint = this.getBatchLotLabelTemplate(data)
        break
      case printGenre.SYNLAB_IDENTIFIER:
        dataToPrint = this.getSynlabLabelTemplate(data)
        break
      case printGenre.ORBIT_M_KIT_ACTIVATION:
        dataToPrint = this.getOrbitMKitActivationTemplate(data)
        break
      case printGenre.ORBIT_M_KIT_REPLACEMENT:
        dataToPrint = this.getOrbitMKitReplacementTemplate(data)
        break
      case printGenre.ORBIT_M_K2EDTA_TUBE:
        dataToPrint = this.getOrbitMK2edtaTubeTemplate(data)
        break
      case printGenre.ORBIT_M_NAHEPARIN_TUBE:
        dataToPrint = this.getOrbitMNaheparinTubeTemplate(data)
        break
      case printGenre.ORBIT_M_BLANK_LABEL:
        dataToPrint = this.getOrbitMBlankLabelTemplate(data)
        break
      case printGenre.ORBIT_M_KIT_HASHED_ID_ONLY:
        dataToPrint = this.getOrbitMKitHashedIdOnlyTemplate(data)
        break
      case printGenre.COBIOME_ACTIVATION_QR:
        dataToPrint = this.getCoBiomeActivationLabelTemplate(data)
        break
      case printGenre.SAMPLE_IDENTIFIER:
        dataToPrint = this.getSampleIdentifierLabelTemplate(data)
        break
    }

    return { dataToPrint, isPdf }
  }
  static getOrbitMKitHashedIdOnlyTemplate(data) {
    const labelTemplate = `
    ^XA
    ^CF0,40
    ^FO18,58^FDORBIT-m^FS
    ^CF0,30
    ^FO80,108^FD${data.hashedId}^FS
    ^FO20,170^FDExpiry: ${data.expiryDate}^FS
    ^XZ
    `
    return [labelTemplate]
  }
  static getOrbitMBlankLabelTemplate(data) {
    const labelTemplate = `
    ^XA
    ^CF0,40
    ^FO18,58^FDORBIT-m^FS
    ^CF0,30
    ^FO20,120^FDExpiry: ${data.expiryDate}^FS
    ^XZ
    `
    return [labelTemplate]
  }
  static getOrbitMK2edtaTubeTemplate(data) {
    const labelTemplate = `
    ^XA
    ^CF0,40
    ^FO18,20^FDORBIT-m^FS
    ^FO328,34
    ^BQN,2,2
    ^FDMM,A${data.sampleIdentifier}^FS
    ^CF0,30
    ^FO20,58^FD${data.sampleIdentifier}^FS
    ^FO18,170^FDSubject ID:_________________^FS
    ^XZ
    `
    return [labelTemplate]
  }
  static getOrbitMNaheparinTubeTemplate(data) {
    const labelTemplate = `
    ^XA
      ^CF0,40
      ^FO18,20^FDORBIT-m^FS
      ^CF0,30
      ^FO20,98^FDExpiry: ${data.expiry}^FS
      ^FO20,130^FDLot: ${data.lot}^FS
      ^FO18,170^FDSubject ID:_________________^FS
      ^XZ
    `
    return [labelTemplate]
  }
  static getOrbitMKitActivationTemplate(data) {
    const labelTemplate = `
    ^XA
    ^CF0,40
    ^FO18,58^FDORBIT-m^FS
    ^FO270,34
    ^BQN,2,4
    ^FDMM,A${data.urlLink}^FS
    ^CF0,30
    ^FO18,120^FD${data.hashedId}^FS
    ^FO20,170^FDExpiry: ${data.expiryDate}^FS
    ^XZ
    `
    return [labelTemplate]
  }

  static getOrbitMKitReplacementTemplate(data) {
    const labelTemplate = `
    ^XA
    ^FWB
    ^CF0,43
    ^FO20,12^FDORBIT-m^FS
    ^FO270,34
    ^BQN,2,4
    ^FDMM,A${data.urlLink}^FS
    ^FWB
    ^CF0,25
    ^FO65,15^FD${data.hashedId}^FS
    ^FWN
    ^CF0,28
    ^FO90,80^FDREPLACEMENT^FS
    ^FO90,125^FDONLY^FS
    ^FO90,170^FDExpiry: ${data.expiryDate}^FS
    ^XZ
    `
    return [labelTemplate]
  }

  static getSynlabLabelTemplate(data) {
    return [
      `^XA
      ^CFA,20
      ^FO0,40^AdN,30,20^FWN^FH^FD${data}^FS
      ^FO280,25^BY4,1,0^BQN,2,3^FDMM,A${data}^FS
      ^XZ`
    ]
  }
  static getKitOwnerLabelTemplate(data) {
    return [
      `^XA
      ^FX Top section
      ^CF0,30
      ^FO50,70^FD${data.firstLine || ''}^FS
      ^FO50,115^FD${data.secondLine || ''}^FS
      ^FO50,168^GB700,1,1^FS
      ^FX Sample Identifier
      ^CFA,20
      ^FO50,180^FD ${data.lastLine || ''}^FS
      ^XZ`
    ]
  }

  static getBatchLotLabelTemplate(data) {
    return [
      `^XA
      ^FX Top section
      ^CF0,30
      ^FO50,70^FD${data.firstLine || ''}^FS
      ^FO50,115^FD${data.secondLine || ''}^FS
      ^FO50,160^FD ${data.lastLine || ''}^FS
      ^XZ`
    ]
  }

  static getActivationCodeLabelTemplate(data) {
    return [
      `^XA
      ^FX Top section
      ^CF0,50
      ^FO65,100^FD${data.firstLine || ''}^FS
      ^CFA,30
      ^FO61,40^FDACTIVATION CODE^FS
      ^XZ`
    ]
  }

  static getVoucherCodeLabelTemplate(data) {
    return [
      `^XA
      ^FX Top section
      ^CF0,33
      ^FO10,140^FD${data.firstLine || ''}^FS
      ^CF0,40
      ^FO40,40^FDVoucher Code:^FS
      ^XZ`
    ]
  }

  static getCoBiomeActivationLabelTemplate({ activationCode }) {
    const coBiomePatientBaseUrl = process.env.VUE_APP_COBIOME_PATIENT_URL
    return [
      `^XA
      ^FO245,5
      ^BQN,2,4
      ^FDQA,${coBiomePatientBaseUrl}kit?activation-code=${activationCode}^FS
      ^CF0,50
      ^FO15,40
      ^FDScan here^FS
      ^FO15,100
      ^FDto activate^FS
      ^CF0,20
      ^FO15,175
      ^FDActivation Code:^FS
      ^CF0,30
      ^FO375,170,1
      ^FD${activationCode}^FS
      ^XZ`
    ]
  }

  static getSampleIdentifierLabelTemplate({ sampleIdentifier }) {
    return [
      `^XA
      ^FX Top section
      ^CF0,50
      ^FO65,100^FD${sampleIdentifier}^FS
      ^CFA,30
      ^FO61,40^FDSAMPLE IDENTIFIER^FS
      ^XZ`
    ]
  }

  // Initialize certificates needed for communication with QZ Tray
  static initialize() {
    qz.security.setCertificatePromise(function (resolve, reject) {
      let url = `${process.env.VUE_APP_API_ROOT}/public/digital-certificate`
      HTTP.get(url)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error))
    })
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        let url = `${process.env.VUE_APP_API_ROOT}/public/sign-message?request=${toSign}`
        HTTP.get(url)
          .then((response) => resolve(response.data))
          .catch((error) => reject(error))
      }
    })
    qz.api.setPromiseType(function promise(resolver) {
      return new RSVP.Promise(resolver)
    })
    qz.api.setSha256Type((data) => sha256(data))
  }

  // Send message to printer via QZ tray
  static sendToPrinter(data, printer, isPdf) {
    const dataToPrint = isPdf
      ? [{ type: 'pdf', format: 'base64', data: data }]
      : data
    return new Promise((resolve, reject) => {
      this.initialize()
      let time = 0
      if (!qz.websocket.isActive()) {
        qz.websocket.connect()
        time = 500
      }
      setTimeout(() => {
        qz.printers
          .find(printer)
          .then((selectedPrinter) =>
            qz.configs.create(selectedPrinter, printerOptions[printer])
          )
          .then((config) => qz.print(config, dataToPrint))
          .then(() => resolve())
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            // qz.websocket.disconnect()
          })
      }, time)
    })
  }
}
