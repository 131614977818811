const experimentStatues = Object.freeze({
  START: 'START',
  COMPLETE: 'COMPLETE'
})
const experimentTypes = Object.freeze({
  PLATE_PREPARATION: 'PLATE_PREPARATION',
  SAMPLE_ACCESSIONING: 'SAMPLE_ACCESSIONING',
  DNA_EXTRACTION: 'DNA_EXTRACTION',
  CONSOLIDATION: 'CONSOLIDATION',
  LIB_PREPARATION: 'LIB_PREPARATION'
})
export { experimentStatues, experimentTypes }
