<template>
  <section class="transfer-kit">
    <b-card-group deck>
      <b-card header="Transfer Kit">
        <b-card-text>
          <b-form-group>
            <searchUser @customer-selected="setKitRecipient" />
          </b-form-group>
          <b-form-group v-if="kitRecipient">
            <label class="mr-sm-2" for="tranferTo"
              >Transfer Kit To Customer:</label
            >
            <div>
              <i class="fa fa-user" aria-hidden="true" />
              {{ kitRecipient.firstName }} {{ kitRecipient.lastName }}<br />
              <i class="fa fa-envelope" aria-hidden="true" />
              {{ kitRecipient.email }}<br />
              <i class="fa fa-phone" aria-hidden="true" />
              {{ kitRecipient.phoneNumber }}
            </div>
          </b-form-group>
          <b-form-group>
            <div class="pull-right">
              <b-button
                variant="primary"
                :disabled="!kitRecipient"
                @click="kitRecipient = null"
              >
                Reset
              </b-button>
              <b-button
                variant="primary"
                :disabled="!kitRecipient"
                @click="transferKit"
              >
                Transfer
              </b-button>
            </div>
          </b-form-group>
        </b-card-text>
      </b-card>
    </b-card-group>
  </section>
</template>

<script lang="js">
import { Notification, NOTIFICATION_STYLES } from '@microbadevs/library'
import searchUser from '@/components/elements/searchUser'
export default {
  name: 'TransferKit',
  components: {
    searchUser
  },
  props: {
    kit: Object,
    owner: Object
  },
  data () {
    return {
      kitRecipient: null
    }
  },
  computed: {
    questionnaireAnswered: function () {
      let answered = this.kit.surveysStats.reduce((accumulator, currentValue) => accumulator + currentValue.completedQuestions, 0)
      return answered > 0
    }
  },
  mounted () {},
  methods: {
    setKitRecipient (payload) {
      this.kitRecipient = payload
    },
    transferKit () {
      Notification({
        text: 'Kit contains answered questionnaire associated with it.',
        style: NOTIFICATION_STYLES.QUESTION,
        confirmButtonText: 'Yes, transfer it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('transferKit', {kitId: this.kit.id, fromUserId: this.owner.id, toUserId: this.kitRecipient.id})
            .then(() => {
              this.$emit('kit-transferred')
              Notification({
                title: 'Kit transferred successfully.',
                style: NOTIFICATION_STYLES.SUCCESS
              })
            })
            .catch(error => {
              console.error(error)
              Notification({
                text: error.response.data.message,
                style: NOTIFICATION_STYLES.ERROR
              })
            })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.transfer-kit {
  padding: 1.25rem;
}
</style>
